import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography } from '@material-ui/core'
import { connect } from 'react-redux'
import { Breadcrumb } from 'components'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Account = props => {
    const classes = useStyles();
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Mi Cuenta'
                    }
                ]}
            />
            
            {((false) ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="100px"
                >
                    <Typography style={{ fontSize: 35, fontWeight: 500, fontFamily: 'anton' }}>
                        PRÓXIMAMENTE
                    </Typography>
                </Box>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    
})

const mapDispatchToProps = dispatch => ({
    
})

export default connect(mapStateToProps, mapDispatchToProps)(Account);
