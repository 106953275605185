import React from 'react'
import { Route } from 'routes';
import { Switch, Redirect } from "react-router-dom";
import { SignIn as SignInView } from './screens/signin';

const NonSecured = React.memo(() => {
    return (
        <Switch>
            <Redirect exact from='/auth' to="/auth/login" />
            <Route exact path='/auth/login' component={SignInView} />
            <Redirect from='/*' to="/" />
        </Switch>
    )
})

export default NonSecured