export default {
    root: {
        backgroundColor: '#d15c5c',
        padding: '5px 15px',
        borderRadius: 4,
        marginTop: 10,

        '&.MuiFormHelperText-contained': {
            marginLeft: 0,
            marginRight: 0,
        },

        '&.Mui-error': {
            color: '#fff',
      },
    },
  };
  