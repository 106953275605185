const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    locality: {
        loading: true,
        error: null,
        data: {
            id: null,
            countryId: null,
            provinceId: null,
            name: '',
            favorite: false
        },
        actions: {
            save: {
                id: null,
                loading: false,
                success: false,
                error: null,
            },
            updateFavorite: {
                id: null,
                loading: false,
                success: false,
                error: null,
            },
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'LOCALITIES_FETCH_LOCALITIES_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'LOCALITIES_FETCH_LOCALITIES_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.localities),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'LOCALITIES_FETCH_LOCALITIES_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true,
            })

        case 'LOCALITIES_FETCH_LOCALITY_START':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    loading: true,
                })
            })

        case 'LOCALITIES_FETCH_LOCALITY_SUCCESS':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    loading: false,
                    data: {
                        id: action.payload.locality.id,
                        countryId: action.payload.locality.provincia.pais.id,
                        provinceId: action.payload.locality.provincia.id,
                        name: action.payload.locality.nombre,
                        favorite: action.payload.locality.favorito,
                    }
                })
            })

        case 'LOCALITIES_FETCH_LOCALITY_FAIL':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    loading: false,
                    error: true,
                })
            })

        case 'LOCALITIES_SAVE_LOCALITY_START':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        save: {
                            id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'LOCALITIES_SAVE_LOCALITY_SUCCESS':
            const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
            }) : null

            return Object.assign({}, state, {
                data: action.payload && action.payload.patch && action.payload.id ? [].concat(data) : state.data,
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        save: {
                            id: null,
                            loading: true,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'LOCALITIES_SAVE_LOCALITY_FAIL':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        save: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'LOCALITIES_UPDATE_FAVORITE_START':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        updateFavorite: {
                            id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'LOCALITIES_UPDATE_FAVORITE_SUCCESS':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        updateFavorite: {
                            id: null,
                            loading: false,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'LOCALITIES_UPDATE_FAVORITE_FAIL':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        updateFavorite: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'LOCALITIES_SAVE_LOCALITY_RESET':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    actions: Object.assign({}, state.locality.actions, {
                        save: defaultState.locality.actions.save
                    })
                })
            })

        case 'LOCALITIES_FORM_PROPERTY_VALUE_SET':
            return Object.assign({}, state, {
                locality: Object.assign({}, state.locality, {
                    data: Object.assign({}, state.locality.data, {
                        [action.payload.property]: action.payload.value
                    })
                })
            })

        case 'LOCALITIES_LOCALITY_RESET':
            return Object.assign({}, state, {
                locality: Object.assign({}, defaultState.locality)
            })

        case 'LOCALITIES_RESET':
            return Object.assign({}, state, defaultState)
        default:
            return state;
    }
}
