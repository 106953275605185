import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { connect } from 'react-redux';
import { Box, CircularProgress } from '@material-ui/core'

const ApplicationRoute = ({ component: Component, secured, user, loading, ...rest }) => (
    <Route
        { ...rest }
        
        children={() =>
            loading ? (
                <Box
                    display="flex"
                    flex="1"
                    justifyContent="center"
                    alignItems="center"
                    height="100%"
                >
                    <CircularProgress />
                </Box>
            ) : secured && user ? (
                <Component />
            ) : secured && !user ? (
                <Redirect to="/auth" />
            ) : !secured && user ? (
                <Redirect to="/dashboard" />
            ) : (
                <Component  />
            )
        }
    />
);

export default connect(state => ({ user: state.context.user, loading: state.context.loading }))(ApplicationRoute);