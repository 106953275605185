import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { BookingsActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'
import { BookingStatuses } from 'consts'
import moment from 'moment'


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    content: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    emptyIcon: {
        fontSize: '200px',
        color: '#ccc'
    }
}));

const BookingsList = React.memo(props => {
    const {
        getBookings,
        bookings,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        query,
        setQuery,
        reset,
        dateFrom,
        dateTo,
        setDateFrom,
        setDateTo,

        getBookingReport,
        report,
    } = props

    const {
        p,
        r,
        f,
        t
    } = query;

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('BOOKINGS_RESET')
        }
    }, [reset])

    useEffect(() => {
        getBookings({
            page: p,
            pageCount: r,
            sortField: "experienciafecha",
            sortOrder: "desc",
        })
    }, [p, r, dateFrom, dateTo, getBookings])

    useEffect(() => {
        if (f.length > 0 && t.length > 0) {
            setDateFrom(f)
            setDateTo(t)
        } else {
            setQuery({ t: dateTo, f: dateFrom })
        }

    }, [f, t])

    const onClickView = useCallback((id) => {
        history.push(`/reservas/detalle/${id}`);
    }, [history])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const handleDateFromChange = useCallback((date) => {
        if (date && date._isValid) {
            setQuery({ p: 0, f: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'), })
            setDateFrom(date)
        }
    }, [setQuery])

    const handleDateToChange = useCallback((date) => {
        if (date && date._isValid) {
            setQuery({ p: 0, t: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'), })
            setDateTo(date)
        }
    }, [setQuery])

    const onClickDownloadExcel = useCallback(() => {
        getBookingReport()
    }, [setQuery])


    const headers = useMemo(() => ([
        { label: 'Reserva' },
        { label: 'Creado' },
        { label: 'Experiencia' },
        { label: 'Cliente' },
        { label: 'Cantidad' },
        { label: 'Monto' },
        { label: 'Status' },
    ]), [])

    const rows = useMemo(() => (
        bookings.map(item => {
            return {
                view: [
                    { value: `${moment.utc(item.fecha).format('DD/MM/YY - HH:mm')}` },
                    { value: `${moment.utc(item.creado).format('DD/MM/YY - HH:mm')}` },
                    { value: `${item.experiencia.nombre ? item.experiencia.nombre : 'No hay información'}` },
                    { value: `${item.cliente.nombre ? item.cliente.nombre : 'No hay información'}` },
                    { value: `${item.cantidad}` },
                    { value: `$${item.total}` },
                    { value: `${BookingStatuses[item.estado]}` }
                ],
                data: {
                    id: item.id,
                    status: item.estado
                }
            }
        })
    ), [bookings])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Reservas'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            getBookings({
                                page: p,
                                pageCount: r,
                                sortField: "experienciafecha",
                                sortOrder: "desc",
                            })
                        }}
                    />
                ) : (
                            <Box width="100%">
                                <div className={classes.content}>
                                    <AnTable
                                        toolbar={(
                                            <AnTableToolbar
                                                dateFromValue={dateFrom}
                                                dateToValue={dateTo}
                                                handleDateFromChange={handleDateFromChange}
                                                handleDateToChange={handleDateToChange}
                                                onClickDownloadExcel={onClickDownloadExcel}
                                                report={report}
                                            />
                                        )}
                                        isActiveSearch={isActiveSearch}
                                        emptyIcon={(
                                            <GroupAddIcon className={classes.emptyIcon} />
                                        )}
                                        emptyMessage="Aún no hay reservas"
                                        actionsRowWidth="58px"
                                        headers={headers}
                                        rows={rows}
                                        loading={loading}
                                        totals={totals}
                                        page={page}
                                        pageCount={pageCount}
                                        onClickView={onClickView}
                                        onPageChange={onPageChange}
                                        onPageCountChange={onPageCountChange}
                                        showToolbarOnError
                                    />
                                </div>
                            </Box>
                        )}
            </Box>
        </div>
    );
});

const mapStateToProps = state => ({
    initialLoading: state.bookings.initialLoading,
    loading: state.bookings.loading,
    error: state.bookings.error,
    bookings: state.bookings.data,
    page: state.bookings.page,
    pageCount: state.bookings.pageCount,
    totals: state.bookings.totals,
    isActiveSearch: state.bookings.isActiveSearch,
    dateFrom: state.bookings.dateFrom,
    dateTo: state.bookings.dateTo,
    report: state.bookings.booking.actions.report,
})

const mapDispatchToProps = dispatch => ({
    getBookings: (params, criteria) => dispatch(BookingsActions.getBookings(params, criteria)),
    reset: (type) => dispatch(BookingsActions.reset(type)),
    setDateFrom: (date) => dispatch(BookingsActions.setDateFrom(date)),
    setDateTo: (date) => dispatch(BookingsActions.setDateTo(date)),
    getBookingReport: () => dispatch(BookingsActions.getBookingReport()),
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        f: withDefault(StringParam, ""),//  The current date from
        t: withDefault(StringParam, ""),// The current date to
    }, BookingsList)
);
