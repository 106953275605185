import React, { useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider, Typography } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { BookingsActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom'
import { BookingTypes } from 'consts'
import Alert from '@material-ui/lab/Alert';
import moment from 'moment';
import { formatCurrencyData } from 'utils/formatCurrencyData'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    header: {
        background: '#f3f3f3b3',
        padding: '10px 16px',
    },
    content: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
    },
    selectRoot: {
        height: '28px',
        lineHeight: '28px',
        fontWeight: 500,
    },
    inputRoot: {
        '& .MuiInput-input': {
            height: '28px',
            lineHeight: '28px',
            fontWeight: 500,
        },

    },
}));

const BookingsForm = props => {
    const {
        booking,
        getBooking,
        cancelBooking,
        reset,
    } = props

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const transitionStyles = {
        entered: { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getBooking(match.params.id)
        }

        return () => {
            reset('BOOKINGS_BOOKING_RESET')
        }
    }, [getBooking, reset, match.params.id])

    useEffect(() => {
        if (booking.actions.cancel.success && match.params.id) {
            getBooking(match.params.id)
        }

        return () => {
            reset('BOOKINGS_BOOKING_RESET')
        }
    }, [getBooking, booking.actions.cancel.success])
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Reserva'
                    },
                    {
                        name: 'Detalle'
                    }
                ]}
            />

            {((match.params.id && booking.loading) ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                    <Transition in={true} appear={true} timeout={0}>
                        {state => (
                            <Box paddingBottom={3}>
                                <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                    <CardHeader
                                        className={classes.header}
                                        subheader={"Datos de la reserva"}
                                        title="Reserva"
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Fecha de Realización"
                                                    value={moment.utc(booking.data.creado).local().format('DD-MM-YYYY hh:mm A')}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Fecha Reservada ( Hora local del lugar del evento )"
                                                    value={moment(booking.data.fecha).format('DD-MM-YYYY hh:mm A')}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Cantidad"
                                                    value={booking.data.cantidad}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Precio"
                                                    value={formatCurrencyData(booking.data.total)}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Tipo de Reserva"
                                                    value={BookingTypes[booking.data.tipoReserva]}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                    </CardContent>

                                    <CardHeader
                                        className={classes.header}
                                        title="Cliente"
                                        subheader={"Datos del Cliente"}
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Nombre"
                                                    value={booking.data.cliente.nombre}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Email"
                                                    value={booking.data.emailContacto}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Teléfono"
                                                    value={booking.data.cliente.telefono}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Datos de contacto"
                                                    value={booking.data.datosContacto}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>


                                    </CardContent>

                                    <CardHeader
                                        className={classes.header}
                                        subheader={"Datos del Proveedor"}
                                        title="Proveedor"
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Nombre"
                                                    value={booking.data.usuarioProveedor.nombre}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>


                                    <Divider />

                                    <CardHeader
                                        className={classes.header}
                                        subheader={"Datos del Promotor"}
                                        title="Promotor"
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Nombre"
                                                    value={booking.data.usuarioPromotor.nombre}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />

                                    <CardHeader
                                        className={classes.header}
                                        title="Importes"
                                        subheader={"Detalle de importes"}
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Proveedor ( Subtotal )"
                                                    value={formatCurrencyData(booking.data.subTotalProveedor)}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Comisión promotores"
                                                    value={formatCurrencyData(booking.data.comisionPromotores)}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Comisión Plataforma"
                                                    value={formatCurrencyData(booking.data.comisionPlataforma)}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Pago total ( Al momento de la reserva )"
                                                    value={booking.data.pago ? formatCurrencyData(booking.data.pago.montos.total) : 'No hay información'}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Comisión Pago"
                                                    value={booking.data.pago ? formatCurrencyData(booking.data.pago.montos.comisionPago) : 'No hay información'}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>

                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Subtotal Pago Plataforma"
                                                    value={booking.data.pago ? formatCurrencyData(booking.data.pago.montos.subTotalPlataforma) : 'No hay información'}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    inputProps={{
                                                        readOnly: true
                                                    }}
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Subtotal Pago Usuario"
                                                    value={booking.data.pago ? formatCurrencyData(booking.data.pago.montos.subTotalUsuario) : 'No hay información'}
                                                    onChange={(e) => {
                                                        // Nothing to do ...
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />

                                    <CardHeader
                                        className={classes.header}
                                        subheader={"Estado de la reserva"}
                                        title="Estado"
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                {booking.data.estado === 1 ? (
                                                    <Alert
                                                        icon={false}
                                                        severity="info"
                                                        action={
                                                            <>
                                                                {booking.actions.cancel.loading ? (
                                                                    <CircularProgress size={15} />
                                                                ) : (
                                                                        <Button
                                                                            style={{
                                                                                background: '#fff',
                                                                                color: '#f15757',
                                                                            }}
                                                                            color="inherit"
                                                                            size="small"
                                                                            variant="contained"
                                                                            onClick={() => {
                                                                                cancelBooking(match.params.id)
                                                                            }}
                                                                        >
                                                                            Cancelar
                                                                        </Button>
                                                                    )}
                                                            </>
                                                        }
                                                    >
                                                        Pendiente de Pago
                                                    </Alert>
                                                ) : booking.data.estado === 2 ? (
                                                    <Alert
                                                        icon={false}
                                                        severity="success"
                                                        action={
                                                            booking.actions.cancel.loading ? (
                                                                <CircularProgress size={15} />
                                                            ) : (
                                                                    <Button
                                                                        style={{
                                                                            background: '#fff',
                                                                            color: '#f15757',
                                                                        }}
                                                                        color="inherit"
                                                                        size="small"
                                                                        variant="contained"
                                                                        onClick={() => {
                                                                            cancelBooking(match.params.id)
                                                                        }}
                                                                    >
                                                                        Cancelar
                                                                    </Button>
                                                                )
                                                        }
                                                    >
                                                        Confirmada
                                                    </Alert>
                                                ) : booking.data.estado === 3 ? (
                                                    <Alert
                                                        icon={false}
                                                        severity="warning"
                                                        action={
                                                            booking.actions.cancel.loading ? (
                                                                <CircularProgress size={15} />
                                                            ) : (
                                                                    <Button
                                                                        style={{
                                                                            background: '#fff',
                                                                            color: '#f15757',
                                                                        }}
                                                                        color="inherit"
                                                                        size="small"
                                                                        variant="contained"
                                                                        onClick={() => {
                                                                            cancelBooking(match.params.id)
                                                                        }}
                                                                    >
                                                                        Cancelar
                                                                    </Button>
                                                                )
                                                        }
                                                    >
                                                        Pago Rechazado
                                                    </Alert>
                                                ) : (
                                                                <Alert
                                                                    icon={false}
                                                                    severity="error"
                                                                >
                                                                    {booking.data.estado === 4 ? 'Pendiente Cancelación Pago' : 'Cancelada'}
                                                                </Alert>
                                                            )}
                                            </Grid>
                                        </Grid>
                                    </CardContent>


                                    <Divider />

                                    <CardActions
                                        style={{
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                        >
                                            VOLVER
                                    </Button>
                                    </CardActions>
                                </Card>
                            </Box>
                        )}
                    </Transition>
                ))}
        </div>
    );
};

const mapStateToProps = state => ({
    booking: state.bookings.booking,
})

const mapDispatchToProps = dispatch => ({
    getBooking: (id) => dispatch(BookingsActions.getBooking(id)),
    cancelBooking: (id) => dispatch(BookingsActions.cancelBooking(id)),
    reset: (type) => dispatch(BookingsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(BookingsForm);
