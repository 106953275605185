
import axios from 'axios'
import { PostulationRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getPostulations(page, pageCount, criteria, status) {
    return async dispatch => {
        try {
            dispatch({
                type: 'POSTULATIONS_FETCH_POSTULATIONS_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })

            const response = await new PostulationRestClient(true).getPostulations(page, pageCount, criteria, status)

            dispatch({
                type: 'POSTULATIONS_FETCH_POSTULATIONS_SUCCESS',
                payload: {
                    page,
                    pageCount,
                    postulations: response.data.items,
                    totals: response.data.totalItems,
                }
            })

        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'POSTULATIONS_FETCH_POSTULATIONS_FAIL'
                })

                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener las postulaciones'
                    }
                })
            }
        }
    }
}

function getPostulation(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'POSTULATIONS_FETCH_POSTULATION_START'
            })

            const response = await new PostulationRestClient(true).getPostulation(id)

            dispatch({
                type: 'POSTULATIONS_FETCH_POSTULATION_SUCCESS',
                payload: {
                    postulation: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'POSTULATIONS_FETCH_POSTULATION_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener la postulación'
                }
            })
        }
    }
}

function savePostulation(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'POSTULATIONS_SAVE_POSTULATION_START',
                payload: {
                    id,
                    patch,
                }
            })

            if (id && !patch) {
                await new PostulationRestClient(true).updatePostulation(id, data)
            } else if (id && patch) {
                await new PostulationRestClient(true).patchPostulation(id, data)
            } else {
                await new PostulationRestClient(true).createPostulation(data)
            }

            dispatch({
                type: 'POSTULATIONS_SAVE_POSTULATION_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: id
                        ? 'La postulación se ha actualizado correctamente'
                        : 'La postulación se ha creado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'POSTULATIONS_SAVE_POSTULATION_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'POSTULATIONS_SAVE_POSTULATION_RESET'
            })
        }
    }
}

function reset(type) {
    return {
        type,
    }
}


export const PostulationsActions = {
    getPostulations,
    getPostulation,
    savePostulation,
    reset,
}