import MuiButton from './MuiButton';
import MuiIconButton from './MuiIconButton';
import MuiPaper from './MuiPaper';
import MuiTableRow from './MuiTableRow';
import MuiTableCell from './MuiTableCell';
import MuiTableHead from './MuiTableHead';
import MuiTypography from './MuiTypography';
import MuiFormHelperText from './MuiFormHelperText'
import MuiTablePagination from './MuiTablePagination'

export default {
    MuiButton,
    MuiIconButton,
    MuiPaper,
    MuiTableRow,
    MuiTableCell,
    MuiTableHead,
    MuiTypography,
    MuiFormHelperText,
    MuiTablePagination,
    MuiSelect: {
        select: {
            '&:focus': {
                backgroundColor: 'transparent'
            }
        }
    },
    MuiCardHeader: {
        title: {
            fontFamily: 'Roboto',
            fontWeight: 800
        }
    }
};
