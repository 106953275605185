
import { CommissionsSchemeRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getCommissionsScheme() {
    return async dispatch => {
        try {
            dispatch({
                type: 'COMMISSIONS_SCHEMES_FETCH_SCHEME_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })
            
            const response = await new CommissionsSchemeRestClient(true).getCommissionsScheme()

            dispatch({
                type: 'COMMISSIONS_SCHEMES_FETCH_SCHEME_SUCCESS',
                payload: {
                    scheme: response.data
                }
            })
            
        } catch (e) {
            dispatch({
                type: 'COMMISSIONS_SCHEMES_FETCH_SCHEME_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener el esquema de comisiones'
                }
            })
        }   
    }
}

function saveCommissionsScheme(data) {
    return async dispatch => {
        try {
            dispatch({
                type: 'COMMISSIONS_SCHEMES_SAVE_SCHEME_START',
            })

            await new CommissionsSchemeRestClient(true).updateCommissionsScheme(data)

            dispatch({
                type: 'COMMISSIONS_SCHEMES_SAVE_SCHEME_SUCCESS',
                payload: {
                    data,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: 'El esquema de comisión se ha actualizado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'COMMISSIONS_SCHEMES_SAVE_SCHEME_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'COMMISSIONS_SCHEMES_SAVE_SCHEME_RESET'
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'COMMISSIONS_SCHEMES_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}

function reset(type) {
    return {
        type,
    }
}

export const CommissionsSchemesActions = {
    getCommissionsScheme,
    saveCommissionsScheme,
    setFormPropertyValue,
    reset,
}