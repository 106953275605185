import moment from 'moment'
let date = new Date()

const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    page: 0,
    pageCount: 10,
    totals: 0,
    isActiveSearch: false,
    data: [],
    dateFrom: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DDThh:mm:ss.SZ'),
    dateTo: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
    booking: {
        loading: true,
        error: null,
        data: {},
        actions: {
            cancel: {
                loading: false,
                success: false,
                error: null,
            },
            report: {
                loading: false,
                success: false,
                error: null
            }
        }
    },
};


export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'BOOKINGS_FETCH_BOOKINGS_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'BOOKINGS_FETCH_BOOKINGS_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.bookings),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'BOOKINGS_FETCH_BOOKINGS_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true,
            })

        case 'BOOKINGS_FETCH_BOOKING_START':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    loading: true,
                })
            })

        case 'BOOKINGS_FETCH_BOOKING_SUCCESS':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    loading: false,
                    data: action.payload.booking,
                })
            })

        case 'BOOKINGS_FETCH_BOOKING_FAIL':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    loading: false,
                    error: true,
                })
            })

        case 'BOOKINGS_CANCEL_BOOKING_START':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        cancel: {
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'BOOKINGS_CANCEL_BOOKING_SUCCESS':
            const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
            }) : null

            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        cancel: {
                            loading: false,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'BOOKINGS_CANCEL_BOOKING_FAIL':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        cancel: {
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'BOOKINGS_CANCEL_BOOKING_RESET':
            console.warn('ddddddd')
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        cancel: defaultState.booking.actions.cancel
                    })
                })
            })

        case 'BOOKINGS_FORM_PROPERTY_VALUE_SET':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    data: Object.assign({}, state.booking.data, {
                        [action.payload.property]: action.payload.value
                    })
                })
            })

        case 'BOOKINGS_BOOKING_RESET':
            return Object.assign({}, state, {
                booking: Object.assign({}, defaultState.booking)
            })

        case 'BOOKINGS_RESET':
            return Object.assign({}, state, defaultState)

        case 'BOOKINGS_SET_DATE_FROM':
            return Object.assign({}, state, {
                dateFrom: action.payload.dateFrom,
            })

        case 'BOOKINGS_SET_DATE_TO':
            return Object.assign({}, state, {
                dateTo: action.payload.dateTo,
            })

        case 'BOOKINGS_FETCH_BOOKINGS_REPORT_START':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        report: {
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'BOOKINGS_FETCH_BOOKINGS_REPORT_SUCCESS':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        report: {
                            loading: false,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'BOOKINGS_FETCH_BOOKINGS_REPORT_FAIL':
            return Object.assign({}, state, {
                booking: Object.assign({}, state.booking, {
                    actions: Object.assign({}, state.booking.actions, {
                        report: {
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        default:
            return state;
    }
}
