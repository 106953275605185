import React from 'react';
import { Switch, Redirect, } from 'react-router-dom';
import { NonSecured as NonSecuredView, Secured as SecuredView } from 'views';
import Route from 'routes/helpers/ApplicationRoute'

const Routes = () => {
    return (
        <Switch>
            <Route
                secured={false}
                path='/auth'
                component={NonSecuredView}
            />

            <Route
                secured
                path='/'
                component={SecuredView}
            />

            <Redirect from='/*' to="/" />
        </Switch>
  );
};

export default Routes;

export {
    Route,
}
