const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    country: {
        loading: true,
        error: null,
        data: {
            id: null,
            name: null,
        },
        actions: {
            save: {
                id: null,
                loading: false,
                success: false,
                error: null,
            },
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'COUNTRIES_FETCH_COUNTRIES_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'COUNTRIES_FETCH_COUNTRIES_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.countries),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'COUNTRIES_FETCH_COUNTRIES_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true,
            })

        case 'COUNTRIES_FETCH_COUNTRY_START':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    loading: true,
                })
            })

        case 'COUNTRIES_FETCH_COUNTRY_SUCCESS':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    loading: false,
                    data: {
                        id: action.payload.country.id,
                        name: action.payload.country.nombre,
                    }
                })
            })

        case 'COUNTRIES_FETCH_COUNTRY_FAIL':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    loading: false,
                    error: true,
                })
            })

        case 'COUNTRIES_SAVE_COUNTRY_START':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    actions: Object.assign({}, state.country.actions, {
                        save: {
                            id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'COUNTRIES_SAVE_COUNTRY_SUCCESS':
            const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
            }) : null

            return Object.assign({}, state, {
                data: action.payload && action.payload.patch && action.payload.id ? [].concat(data) : state.data,
                country: Object.assign({}, state.country, {
                    actions: Object.assign({}, state.country.actions, {
                        save: {
                            id: null,
                            loading: true,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'COUNTRIES_SAVE_COUNTRY_FAIL':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    actions: Object.assign({}, state.country.actions, {
                        save: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'COUNTRIES_SAVE_COUNTRY_RESET':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    actions: Object.assign({}, state.country.actions, {
                        save: defaultState.country.actions.save
                    })
                })
            })

        case 'COUNTRIES_FORM_PROPERTY_VALUE_SET':
            return Object.assign({}, state, {
                country: Object.assign({}, state.country, {
                    data: Object.assign({}, state.country.data, {
                        [action.payload.property]: action.payload.value
                    })
                })
            })

        case 'COUNTRIES_COUNTRY_RESET':
            return Object.assign({}, state, {
                country: Object.assign({}, defaultState.country)
            })

        case 'COUNTRIES_RESET':
            return  Object.assign({}, state, defaultState)

        default:
            return state;
    }
}
