/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class PostulationRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getPostulations
     */

    getPostulations(page, pageCount, criteria, status) {
        const url = `/api/v1/secured/postulaciones`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                email: criteria,
                estado: status ? status : null
            }
        })
    }

    /*
     *   @getPostulation
     */

    getPostulation(id) {
        const url = `/api/v1/secured/postulaciones/${id}`;

        return this.instance.get(url)
    }

    /*
     *  @patchPostulation
     */

    patchPostulation(id, data) {
        const url = `/api/v1/secured/postulaciones/${id}`

        return this.instance.patch(url, data)
    }
}