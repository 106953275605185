import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { TagsActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  inputRoot: {
    '& .MuiInput-input': {
      height: '28px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    
},
}));

const TagsForm = props => {
    const {
        getTag,
        saveTag,
        setFormPropertyValue,
        tag,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getTag(match.params.id)
        }

        return () => reset('TAGS_TAG_RESET')
    }, [getTag, reset, match.params.id])

    useEffect(() => {
        if (tag.actions.save.success) {
            history.goBack()
        }
    }, [tag.actions.save.success, history])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Tags'
                    },
                    {
                        name: match.params.id ? 'Editar' : 'Nuevo'
                    }
                ]}
            />

            {((match.params.id && tag.loading) || tag.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                <CardHeader
                                    className={classes.header}
                                    title="Tags"
                                    subheader={match.params.id ? "Editar Tag" : "Crear nuevo tag"}
                                />
            
                                <Divider />
            
                                <CardContent className={classes.content}>
                                    <TextField
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        classes={{
                                            root: classes.inputRoot,
                                        }}
                                        margin="normal"
                                        fullWidth
                                        label="Nombre"
                                        value={tag.data.name}
                                        onChange={(e) => {
                                            setFormPropertyValue('name', e.target.value)
                                        }}
                                    />
                                </CardContent>
            
                                <Divider />
            
                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        VOLVER
                                    </Button>
                                    
                                    <Button
                                        disabled={!tag.data.name}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            if (match.params.id) {
                                                return saveTag(tag.data.id, {
                                                    nombre: tag.data.name
                                                })
                                            }
            
                                            saveTag(null, {
                                                nombre: tag.data.name
                                            })
                                        }}
                                    >
                                        GUARDAR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    tag: state.tags.tag,
})

const mapDispatchToProps = dispatch => ({
    getTag: (id) => dispatch(TagsActions.getTag(id)),
    saveTag: (id, data, patch) => dispatch(TagsActions.saveTag(id, data, patch)),
    setFormPropertyValue: (property, value) => dispatch(TagsActions.setFormPropertyValue(property, value)),
    reset: (type) => dispatch(TagsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(TagsForm);
