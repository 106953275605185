/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'
import moment from 'moment'

const CancelToken = axios.CancelToken;
let cancel;

export default class MovementsRestClient extends RestClient {
	constructor(useCredentials, refreshContext) {
		super(useCredentials, refreshContext)
	}

	/*
	 *   @getMovements
	 */

	getMovements(params, criteria) {
		const url = `/api/v1/secured/movimientos`;

		if (typeof cancel === 'function') cancel()

		return this.instance.get(url, {
			cancelToken: new CancelToken(function executor(c) {
				// An executor function receives a cancel function as a parameter
				cancel = c;
			}),
			params: {
				page: params.page,
				pageCount: params.pageCount,
				FechaDesde: params.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null,
				FechaHasta: params.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null,
				nombre: criteria.name,
				UsuarioId: criteria.userId,
			}
		})
	}

	/*
	 *  @createMovement
	 */

	createMovement(data) {
		const url = `/api/v1/secured/movimientos`;

		return this.instance.post(url, data)
	}
}