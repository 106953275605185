import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { ExperiencesActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import StarRatings from 'react-star-ratings'
import { RatingStarColors } from 'consts';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
    },
  },
  emptyIcon: {
    fontSize: '200px',
    color: '#ccc'
  }
}));

const ExperienciesList = React.memo(props => {
    const {
        getExperiences,
        initialLoading,
        loading,
        error,
        experiences,
        totals,
        page,
        pageCount,
        isActiveSearch,
        query,
        setQuery,
        reset,
    } = props

    const {
        p,
        r,
        q,
    } = query;
    
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('EXPERIENCES_RESET')
        }
    }, [reset])
    

    useEffect(() => {
        if (q.length > 2) {
            getExperiences(p, r, q)
        } else if (q.length === 0) {
            getExperiences(p, r)
        }
    }, [p, r, q, getExperiences])

    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/experiencias/editar/${id}`);
    }, [history])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Experiencia' },
        { label: 'Ranking', width: '100px' },
        { label: 'Rating', width: '200px' }
    ]), [])

    const rows = useMemo(() => (
        experiences.map(item => {
            return {
                view: [
                    { value: item.nombre },
                    { value: !isNaN(item.ranking) && item.ranking >= 1 ? `${item.ranking}${String.fromCharCode(176)}` : '-' },
                    { component: (
                        <Box
                            style={{
                                pointerEvents: "none"
                            }}
                        >
                            <StarRatings
                                rating={item.rating}
                                starRatedColor={RatingStarColors[item.rating]}
                                numberOfStars={5}
                                name="rating"
                                starDimension="20px"
                                starEmptyColor="#ededed"
                            />
                        </Box>
                    )}
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [experiences])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Experiencias'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getExperiences(p, r, q)
                            } else if (q.length === 0) {
                                getExperiences(p, r)
                            }
                        }}
                    />
                ) : (
                    <Box width="100%">
                        <div className={classes.content}>
                            <AnTable
                                toolbar={(
                                    <AnTableToolbar
                                        textSearchPlaceholder="Buscar experiencia ...."
                                        textSearchValue={q}
                                        onSearch={onSearch}
                                    />
                                )}
                                isActiveSearch={isActiveSearch}
                                emptyIcon={(
                                    <LibraryBooksIcon className={classes.emptyIcon} />
                                )}
                                emptyMessage="Aún no se ha creado ninguna experiencia"
                                headers={headers}
                                rows={rows}
                                actionsRowWidth="58px"
                                loading={loading}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onClickEdit={onClickEdit}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
            
        </div>
    );
})

const mapStateToProps = state => ({
    initialLoading: state.experiences.initialLoading,
    loading: state.experiences.loading,
    error: state.experiences.error,
    experiences: state.experiences.data,
    page: state.experiences.page,
    pageCount: state.experiences.pageCount,
    totals: state.experiences.totals,
    isActiveSearch: state.experiences.isActiveSearch,
})

const mapDispatchToProps = dispatch => ({
    getExperiences: (page, pageCount, criteria) => dispatch(ExperiencesActions.getExperiences(page, pageCount, criteria)),
    reset: (type) => dispatch(ExperiencesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, ""), // The current search criteria
    }, ExperienciesList)
);