
import axios from 'axios'
import { MovementsRestClient } from 'http/resources'
import { ErrorManager } from 'services'
import moment from 'moment'

function getMovements(params, criteria, isSearch) {
	return async (dispatch, getState) => {
		try {
			if (isSearch) {
				dispatch({
					type: 'MOVEMENTS_SEARCH_MOVEMENTS_START',
				})
			} else {
				dispatch({
					type: 'MOVEMENTS_FETCH_MOVEMENTS_START',
				})
			}

			const { dateFrom, dateTo } = getState().movements

			const response = await new MovementsRestClient(true).getMovements({
				...params,
				dateFrom,
				dateTo
			}, criteria)

			if (isSearch) {
				dispatch({
					type: 'MOVEMENTS_SEARCH_MOVEMENTS_SUCCESS',
					payload: {
						page: params.page ? params.page : 0,
						pageCount: params.pageCount ? params.pageCount : 10,
						movements: response.data.items,
						totals: response.data.totalItems,
					}
				})

			} else {
				dispatch({
					type: 'MOVEMENTS_FETCH_MOVEMENTS_SUCCESS',
					payload: {
						page: params.page ? params.page : 0,
						pageCount: params.pageCount ? params.pageCount : 10,
						movements: response.data.items,
						totals: response.data.totalItems,
					}
				})
			}

		} catch (e) {
			if (e instanceof axios.Cancel) {
				// Nothing to do, just canceled request by next request
			} else {

				if (isSearch) {
					dispatch({
						type: 'MOVEMENTS_SEARCH_MOVEMENTS_FAIL'
					})
				} else {
					dispatch({
						type: 'MOVEMENTS_FETCH_MOVEMENTS_FAIL'
					})
				}

				dispatch({
					type: 'ERROR_SET',
					payload: {
						title: 'Error en la operación!',
						description: 'Ha ocurrido un error al intentar obtener los movimientos'
					}
				})
			}
		}
	}
}

function saveMovement(data) {
	return async dispatch => {
		try {
			dispatch({
				type: 'MOVEMENTS_SAVE_MOVEMENT_START',
			})


			await new MovementsRestClient(true).createMovement(data)

			dispatch({
				type: 'MOVEMENTS_SAVE_MOVEMENT_SUCCESS',
			})

			dispatch({
				type: 'SUCCESS_SET',
				payload: {
					title: 'Operación Exitosa!',
					description: 'El movimiento se ha creado correctamente'
				}
			})
		} catch (e) {
			dispatch({
				type: 'MOVEMENTS_SAVE_MOVEMENT_FAIL'
			})

			dispatch({
				type: 'ERROR_SET',
				payload: {
					title: 'UPS! Ha ocurrido un error en la operación.',
					description: ErrorManager.toMessage(e),
				}
			})
		} finally {
			dispatch({
				type: 'MOVEMENTS_SAVE_MOVEMENT_RESET'
			})
		}
	}
}

function setDateFrom(date) {
	return {
		type: 'MOVEMENTS_SET_DATE_FROM',
		payload: {
			dateFrom: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
		}
	}
}

function setDateTo(date) {
	return {
		type: 'MOVEMENTS_SET_DATE_TO',
		payload: {
			dateTo: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
		}
	}
}

function setFormPropertyValue(property, value) {
	return {
		type: 'MOVEMENTS_FORM_PROPERTY_VALUE_SET',
		payload: {
			property,
			value
		}
	}
}

function reset(type) {
	return {
		type,
	}
}

export const MovementsActions = {
	getMovements,
	setDateFrom,
	setDateTo,
	setFormPropertyValue,
	saveMovement,
	reset,
}