const RatingStarColors = {
    0: 'transparent',
    0.5: '#F29365', 
    1: '#F29365',
    1.5: '#F7B447',
    2: '#F7B447',
    2.5: '#FBD548',
    3: '#FBD548',
    3.5: '#B7CE3E',
    4: '#B7CE3E',
    4.5: '#A7B85E',
    5: '#A7B85E'
}

export default RatingStarColors