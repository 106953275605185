import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { TagsActions } from 'redux/actions';
import { AnTableToolbar, AnTable } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
    },
  },
  emptyIcon: {
    fontSize: '200px',
    color: '#ccc'
  }
}));

const TagsList = React.memo(props => {
    const {
        getTags,
        saveTag,
        initialLoading,
        loading,
        error,
        tags,
        tag,
        totals,
        page,
        pageCount,
        isActiveSearch,
        query,
        setQuery,
        reset
    } = props

    const {
        p,
        r,
        q,
    } = query;
    
    const classes = useStyles();
    const history = useHistory()

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('TAGS_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getTags(p, r, q)
        } else if (q.length === 0) {
            getTags(p, r)
        }
    }, [p, r, q, getTags])
    
    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/tags/editar/${id}`);
    }, [history])

    const onClickToggleActivate = useCallback((id, inactive)=> {
        saveTag(id, { inactivo: !inactive }, true)
    }, [saveTag])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Tag' }
    ]), [])

    const rows = useMemo(() => (
        tags.map(item => {
            return {
                view: [
                    { value: item.nombre },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [tags])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Tags'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getTags(p, r, q)
                            } else if (q.length === 0) {
                                getTags(p, r)
                            }
                        }}
                    />
                ) : (
                    <Box width="100%">
                        <div className={classes.content}>
                            <AnTable
                                toolbar={(
                                    <AnTableToolbar
                                        textSearchPlaceholder="Buscar tag ...."
                                        textSearchValue={q}
                                        createButtonText="Crear Tag"
                                        createButtonTo="/tags/nuevo"
                                        onSearch={onSearch}
                                    />
                                )}
                                isActiveSearch={isActiveSearch}
                                emptyIcon={(
                                    <LocalOfferIcon className={classes.emptyIcon} />
                                )}
                                emptyMessage="Aún no has creado ningún tags"
                                emptyButtonTo="/tags/nuevo"
                                emptyButtonText="Crear Tag"
                                headers={headers}
                                rows={rows}
                                loading={loading}
                                save={tag.actions.save}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onClickEdit={onClickEdit}
                                onClickToggleActivate={onClickToggleActivate}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
            
        </div>
    );
});

const mapStateToProps = state => ({
    initialLoading: state.tags.initialLoading,
    loading: state.tags.loading,
    error: state.tags.error,
    tags: state.tags.data,
    page: state.tags.page,
    pageCount: state.tags.pageCount,
    totals: state.tags.totals,
    isActiveSearch: state.tags.isActiveSearch,
    tag: state.tags.tag,
})

const mapDispatchToProps = dispatch => ({
    getTags: (page, pageCount, criteria) => dispatch(TagsActions.getTags(page, pageCount, criteria)),
    saveTag: (id, data, patch) => dispatch(TagsActions.saveTag(id, data, patch)),
    reset: (type) => dispatch(TagsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, "")// The current search criteria
    }, TagsList)
);
