import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { ClientsActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import CategoryIcon from '@material-ui/icons/Category';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';


const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3),
		},
	},
	content: {
		paddingBottom: theme.spacing(2),
		marginTop: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			paddingBottom: theme.spacing(3),
		},
	},
	emptyIcon: {
		fontSize: '200px',
		color: '#ccc'
	},
	link: {
		color: 'rgb(3, 131, 235)',
		textDecoration: 'underline'
	}
}));

const ClientsList = props => {
	const {
		getClients,
		clients,
		initialLoading,
		loading,
		error,
		totals,
		page,
		pageCount,
		isActiveSearch,
		query,
		setQuery,
		reset
	} = props

	const {
		p,
		r,
		q,
	} = query;

	const classes = useStyles();

	useEffect(() => {
		// Nothing to do, we only use this effect
		// to register the function thats makes the cleanup
		// when the component unmount
		return () => {
			reset('CLIENTS_RESET')
		}
	}, [reset])

	useEffect(() => {
		if (q.length > 2) {
			getClients(p, r, q)
		} else if (q.length === 0) {
			getClients(p, r)
		}
	}, [p, r, q, getClients])

	const onSearch = useCallback((text) => {
		if (text.length > 2) {
			setQuery({ q: text, p: undefined })
		} else {
			setQuery({ q: undefined, p: undefined })
		}
	}, [setQuery])

	const onPageChange = useCallback((page) => {
		setQuery({ p: page > 0 ? page : undefined })
	}, [setQuery])

	const onPageCountChange = useCallback((pageCount) => {
		setQuery({ r: pageCount !== 10 ? pageCount : undefined })
	}, [setQuery])

	const headers = useMemo(() => ([
		{ label: 'Nombre' },
		{ label: 'Email' }
	]), [])

	const rows = useMemo(() => (
		clients.map(item => {
			return {
				view: [
					{ value: item.nombre ? item.nombre : 'No disponible' },
					{ value: item.email },
				],
				data: {
					id: item.id,
					inactive: item.inactivo,
				}
			}
		})
	), [clients])

	return (
		<div className={classes.root}>
			<Breadcrumb
				path={[
					{
						name: 'Clientes'
					}
				]}
			/>

			<Box>
				{initialLoading ? (
					<Box
						display="flex"
						justifyContent="center"
					>
						<CircularProgress />
					</Box>
				) : error ? (
					<ErrorBox
						onRetry={() => {
							if (q.length > 2) {
								getClients(p, r, q)
							} else if (q.length === 0) {
								getClients(p, r)
							}
						}}
					/>
				) : (
							<Box width="100%">
								<div className={classes.content}>
									<AnTable
										toolbar={(
											<AnTableToolbar
												textSearchPlaceholder="Buscar cliente ...."
												textSearchValue={q}
												onSearch={onSearch}
											/>
										)}
										isActiveSearch={isActiveSearch}
										emptyIcon={(
											<CategoryIcon className={classes.emptyIcon} />
										)}
										emptyMessage="Aún no se ha creado ningún cliente"
										headers={headers}
										rows={rows}
										loading={loading}
										totals={totals}
										page={page}
										pageCount={pageCount}
										onPageChange={onPageChange}
										onPageCountChange={onPageCountChange}
									/>
								</div>
							</Box>
						)}
			</Box>
		</div>
	);
};

const mapStateToProps = state => ({
	initialLoading: state.clients.initialLoading,
	loading: state.clients.loading,
	error: state.clients.error,
	clients: state.clients.data,
	page: state.clients.page,
	pageCount: state.clients.pageCount,
	totals: state.clients.totals,
	isActiveSearch: state.clients.isActiveSearch,
})

const mapDispatchToProps = dispatch => ({
	getClients: (page, pageCount, criteria) => dispatch(ClientsActions.getClients(page, pageCount, criteria)),
	reset: (type) => dispatch(ClientsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
	withQueryParams({
		p: withDefault(NumberParam, 0), // The current page
		r: withDefault(NumberParam, 10), // The current page count
		q: withDefault(StringParam, ""), // The current search criteria
	}, ClientsList)
);
