/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'

export default class IdentityRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getToken
     */

    getToken(token) {
        const url = `/api/v1/public/identity/admins/token`;

        return this.instance.get(url, {
            headers: {
                authorization: `Bearer ${token}`
            }
        })
    }

    getImpersonateToken(id) {
        const url = `/api/v1/secured/usuarios/${id}/token`;

        return this.instance.get(url)
    }
}