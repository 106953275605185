import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Drawer, Box, Avatar, CircularProgress, Typography } from '@material-ui/core';
import DashboardIcon from '@material-ui/icons/Dashboard';
import TranslateIcon from '@material-ui/icons/Translate';
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import PublicIcon from '@material-ui/icons/Public'
import RoomIcon from '@material-ui/icons/Room'
import CategoryIcon from '@material-ui/icons/Category';
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import GroupIcon from '@material-ui/icons/Group';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import AccessibilityIcon from '@material-ui/icons/Accessibility';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import { SidebarNav } from './components';
import favicon from 'assets/icons/favicon.png'
const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
    drawerRoot: {
        float: 'left',
        height: '100%',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        height: '100%',
        backgroundColor: theme.palette.primary.main,
        width: drawerWidth,
        borderRight: 'none',
        display: 'block',
        float: 'left',
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
    },

    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        padding: '0 8px',
        ...theme.mixins.toolbar,
    },
    headerDrawer: {
        height: 64,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: '#23262f'
    },
    active: {
        color: '#ffffff',
        '& $icon': {
            color: '#ffffff'
        }
    },
    button: {
        padding: '10px 18px',
        justifyContent: 'flex-start',
        textTransform: 'none',
        letterSpacing: 0,
        fontSize: 13,
        width: '100%',
        fontWeight: theme.typography.fontWeightMedium
    },
    titleBtnActive: {
        color: '#ffffff'
    },
    icon: {
        color: theme.palette.icon,
        width: 22,
        height: 22,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(2)
    }
}));

const Sidebar = props => {
    const { sidebarOpen, onClose, isDesktop, username } = props;

    const theme = useTheme()
    const classes = useStyles();

    const pages = [
        {
            title: 'Dashboard',
            href: '/dashboard',
            icon: <DashboardIcon />
        },
        {
            title: 'Esquema Comisiones',
            href: '/esquema-comisiones',
            icon: <MonetizationOnIcon />
        },
        {
            title: 'Postulaciones',
            href: '/postulaciones',
            icon: <GroupAddIcon />
        },
        {
            title: 'Usuarios',
            href: '/usuarios',
            icon: <GroupIcon />
        },
        {
            title: 'Clientes',
            href: '/clientes',
            icon: <AccessibilityIcon />
        },
        {
            title: 'Idiomas',
            href: '/idiomas',
            icon: <TranslateIcon />
        },
        {
            title: 'Paises',
            href: '/paises',
            icon: <PublicIcon />
        },
        {
            title: 'Provincias',
            href: '/provincias',
            icon: <BlurCircularRoundedIcon />
        },
        {
            title: 'Localidades',
            href: '/localidades',
            icon: <RoomIcon />
        },
        {
            title: 'Categorías',
            href: '/categorias',
            icon: <CategoryIcon />
        },
        {
            title: 'Tags',
            href: '/tags',
            icon: <LocalOfferIcon />
        },
        {
            title: 'Experiencias',
            href: '/experiencias',
            icon: <LibraryBooksIcon />
        },
        {
            title: 'Reservas',
            href: '/reservas',
            icon: <CollectionsBookmarkIcon />
        },
        {
            title: 'Comisiones',
            href: '/comisiones',
            icon: <AccountBalanceWalletIcon />
        },
        {
            title: 'Movimientos',
            href: '/movimientos',
            icon: <SwapHorizontalCircleIcon />
        },
    ];

    return (
        <Drawer
            variant={isDesktop ? "permanent" : "temporary"}
            classes={{
                root: clsx(classes.drawerRoot),
                paper: clsx(classes.drawerPaper, !sidebarOpen && classes.drawerPaperClose),
            }}
            onClose={onClose}
            open={sidebarOpen}
        >
            <Box className={classes.headerDrawer}>
                {(!sidebarOpen && (
                    (true ?
                        <Avatar
                            alt="S"
                            src={favicon}
                            /* style={{
                                backgroundColor: '#a6b1d0',
                            }} */
                        />
                        :
                        <CircularProgress
                            size={20}
                            color='secondary' />
                    )
                ))}

                {(sidebarOpen && (
                    <Typography variant="h4" style={{ color: '#fff' }}>
                        Spiderweb
                    </Typography>
                ))}
            </Box>

            <Box
                display="flex"
                flexDirection="row"
                p={1.25}
                mt={1}
                mb={1}
                height={62}
                justifyContent={!sidebarOpen ? "center" : "flex-start"}
            >
                <Avatar
                    alt="profile picture"
                    src="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
                    className={classes.accountAvatar}
                />

                {(sidebarOpen && (
                    <Box ml={2} align="start" overflow="hidden">
                        <Typography style={{ color: '#fff', textOverflow: 'ellipsis', overflow: 'hidden' }}>
                            {username}
                        </Typography>

                        <Typography style={{ color: '#ffffff50', fontSize: 13 }}>
                            Administrador
                    </Typography>
                    </Box>
                ))}
            </Box>

            <Box
                p={1.25}
                bgcolor="#a6b1d0"
                align={sidebarOpen ? "left" : "center"}
            >
                <Typography style={{ color: theme.palette.primary, fontWeight: 500 }}>
                    MENU
            </Typography>
            </Box>

            <SidebarNav
                isDesktop={isDesktop}
                className={classes.nav}
                pages={pages}
                onClose={onClose}
                open={sidebarOpen}
            />
        </Drawer>
    );
};

Sidebar.propTypes = {
    onClose: PropTypes.func,
    sidebarOpen: PropTypes.bool.isRequired,
    isDesktop: PropTypes.bool.isRequired
};

export default Sidebar;
