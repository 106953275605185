import axios from 'axios';
import { CommissionsRestClient } from 'http/resources';
import moment from 'moment';
import { saveAs } from 'file-saver';

function getCommissions(page, pageCount, from, to) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: 'COMMISSIONS_FETCH_COMMISIONS_START'
            });

			let dates = {
                dateFrom: from ? new Date(from) : null,
                dateTo: to ? new Date(to) : null
            };

            const response = await new CommissionsRestClient(
                true
            ).getCommissions(page, pageCount, dates);

            dispatch({
                type: 'COMMISSIONS_FETCH_COMMISIONS_SUCCESS',
                payload: {
                    page: page ? page : 0,
                    pageCount: pageCount ? pageCount : 10,
                    commissions: response.data.items,
                    totals: response.data.totalItems
                }
            });
        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'COMMISSIONS_FETCH_COMMISIONS_FAIL'
                });

                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description:
                            'Ha ocurrido un error al intentar obtener las comisiones'
                    }
                });
            }
        }
    };
}

function getCommissionReport(from, to) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_START'
            });

            let dates = {
                dateFrom: new Date(from),
                dateTo: new Date(to)
            };

            const response = await new CommissionsRestClient(
                true
            ).getCommissionReport(dates);

            dispatch({
                type: 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_SUCCESS'
            });

            let blob = new Blob([response.data], {
                type:
                    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            });

            saveAs(blob, 'reporte.xlsx'); //save file on desktop
        } catch (e) {
            dispatch({
                type: 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_FAIL'
            });

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description:
                        'Ha ocurrido un error al intentar descargar el reporte'
                }
            });
        }
    };
}

function setDateFrom(date) {
    return {
        type: 'COMMISSIONS_SET_DATE_FROM',
        payload: {
            dateFrom: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ')
        }
    };
}

function setDateTo(date) {
    return {
        type: 'COMMISSIONS_SET_DATE_TO',
        payload: {
            dateTo: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ')
        }
    };
}

function reset(type) {
    return {
        type
    };
}

export const CommissionsActions = {
    getCommissions,
    reset,
    setDateFrom,
    setDateTo,
    getCommissionReport
};
