import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
    Button,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import { Link } from '@material-ui/core';
import { SearchInput } from 'components';
import GetAppIcon from '@material-ui/icons/GetApp';
import {
    KeyboardDatePicker,
} from '@material-ui/pickers';
import { AnDropdown } from '..';

const useStyles = makeStyles(theme => ({
    root: {
        marginBottom: theme.spacing(1),
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'flex-start',
        }
    },
    rootFlexEnd: {
        justifyContent: 'flex-end',
    },
    action: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-end',
    },
    row: {
        display: 'flex',
        alignItems: 'center',
    },
    search: {
        display: 'flex',
        alignItems: 'center',

        [theme.breakpoints.down('xs')]: {
            marginBottom: theme.spacing(1)
        }
    },
    select: {
        marginBottom: theme.spacing(1)
    },
    spacer: {
        flexGrow: 1
    },
    dayPicker: {
        width: 140,
        marginLeft: theme.spacing(1),
    },
    dayPickerCont: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        [theme.breakpoints.down('xs')]: {
            width: '100%'
        }
    },
    buttonProgress: {
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
}));

const AnTableToolbar = React.memo(props => {
    const {
        onSearch,
        textSearchPlaceholder,
        textSearchValue,
        createButtonText,
        createButtonTo,
        handleDateFromChange,
        handleDateToChange,
        onClickDownloadExcel,
        dateFromValue,
        dateToValue,
        report,

        onChangeSelect,
        selectOptionValue,
        selectOptionsArray,
    } = props;

    const classes = useStyles();

    const [text, setText] = useState(textSearchValue)

    const onChangeText = useCallback((event) => {
        setText(event.target.value)
        onSearch(event.target.value)
    }, [onSearch])

    return (
        <>
            <div
                className={clsx(classes.root,
                    (!(typeof onSearch === 'function') &&
                        !(createButtonText)) && classes.rootFlexEnd)}
            >
                {createButtonText ? (
                    <div className={classes.row}>
                        <span className={classes.spacer} />

                        <Link
                            component={RouterLink}
                            to={createButtonTo}
                            variant="h6"
                        >
                            <Tooltip title={createButtonText}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    {createButtonText}
                                </Button>
                            </Tooltip>
                        </Link>
                    </div>
                ) : null}

                {typeof onSearch === 'function' && (
                    <div className={classes.search}>
                        <SearchInput
                            className={classes.searchInput}
                            placeholder={textSearchPlaceholder}
                            value={text}
                            onChange={onChangeText}
                        />
                    </div>
                )}

                {typeof onChangeSelect === 'function' && (
                    <div className={classes.select}>
                        <AnDropdown
                            value={selectOptionValue}
                            onChange={onChangeSelect}
                            inputProps={{
                                name: 'Filtro',
                                id: 'filter',
                            }}
                            displayEmpty
                            options={selectOptionsArray}
                        />
                    </div>
                )}

                {typeof handleDateFromChange === 'function' &&
                    typeof handleDateToChange === 'function' &&
                    <div className={classes.dayPickerCont}>
                        <KeyboardDatePicker
                            className={classes.dayPicker}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha Desde"
                            format="DD/MM/YY"
                            value={dateFromValue}
                            onChange={handleDateFromChange}
							maxDate={dateToValue}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            className={classes.dayPicker}
                            margin="normal"
                            id="date-picker-dialog"
                            label="Fecha Hasta"
                            format="DD/MM/YY"
                            value={dateToValue}
                            onChange={handleDateToChange}
							//maxDate={new Date()}
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </div>}

            </div>
            {report &&
                typeof onClickDownloadExcel === 'function' && (
                    <div className={classes.action}>
                        <Tooltip title="Descargar">
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.button}
                                endIcon={<GetAppIcon />}
                                onClick={onClickDownloadExcel}
                                disabled={report.loading}
                            >
                                Descargar Excel
                                {report.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                            </Button>
                        </Tooltip>
                    </div>
                )}
        </>
    );
});

AnTableToolbar.propTypes = {
    textSearchPlaceholder: PropTypes.string,
    textSearchValue: PropTypes.string,
    createButtonText: PropTypes.string,
    createButtonTo: PropTypes.string,
    onSearch: PropTypes.func,
    handleDateToChange: PropTypes.func,
    handleDateToChange: PropTypes.func,
    onClickDownloadExcel: PropTypes.func,
    dateFromValue: PropTypes.string,
    dateToValue: PropTypes.string,
    report: PropTypes.object,
    onChangeSelect: PropTypes.func,
    selectOptionValue: PropTypes.string,
    selectOptionsArray: PropTypes.array,
};

export default AnTableToolbar;
