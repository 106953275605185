import palette from '../palette';
import typography from '../typography';

export default {
  root: {
    ...typography.body1,
    borderBottom: `1px solid ${palette.divider}`
  },
  head: {
    fontFamily: 'Roboto',
    fontSize: 14,
    fontWeight: 800,
  },
  body: {
      padding: '4px 16px'
  },
};
