import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { CountriesActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  inputRoot: {
    '& .MuiInput-input': {
      height: '28px',
      lineHeight: '28px',
      fontWeight: 500,
    },
},
}));

const CountriesForm = props => {
    const {
        getCountry,
        saveCountry,
        setFormPropertyValue,
        country,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getCountry(match.params.id)
        }

        return () => {
            reset('COUNTRIES_COUNTRY_RESET')
        }
    }, [getCountry, reset, match.params.id])

    useEffect(() => {
        if (country.actions.save.success) {
            history.goBack()
        }
    }, [country.actions.save.success, history])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Paises'
                    },
                    {
                        name: match.params.id ? 'Editar' : 'Nuevo'
                    }
                ]}
            />

            {((match.params.id && country.loading) || country.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                <CardHeader
                                    className={classes.header}
                                    title="País"
                                    subheader={match.params.id ? "Editar país" : "Crear nuevo país"}
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Nombre"
                                                value={country.data.name}
                                                onChange={(e) => {
                                                    setFormPropertyValue('name', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        VOLVER
                                    </Button>
                                    
                                    <Button
                                        disabled={!country.data.name}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            if (match.params.id) {
                                                return saveCountry(country.data.id, {
                                                    nombre: country.data.name,
                                                })
                                            }

                                            saveCountry(null, {
                                                nombre: country.data.name,
                                            })
                                        }}
                                    >
                                        GUARDAR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    country: state.countries.country,
})

const mapDispatchToProps = dispatch => ({
    getCountry: (id) => dispatch(CountriesActions.getCountry(id)),
    saveCountry: (id, data, patch) => dispatch(CountriesActions.saveCountry(id, data, patch)),
    setFormPropertyValue: (property, value) => dispatch(CountriesActions.setFormPropertyValue(property, value)),
    reset: (type) => dispatch(CountriesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(CountriesForm);
