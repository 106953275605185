import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core'
import { Breadcrumb, ErrorBox } from 'components'
import { connect } from 'react-redux'
import { CommissionsSchemesActions } from 'redux/actions';
import { Transition } from 'react-transition-group';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  inputRoot: {
    '& .MuiInput-input': {
      height: '28px',
      lineHeight: '28px',
      fontWeight: 500,
    },
},
}));

const CommissionsSchemeForm = props => {
    const {
        getCommissionsScheme,
        saveCommissionsScheme,
        setFormPropertyValue,
        scheme,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        getCommissionsScheme()

        return () => {
            reset('COMMISSIONS_SCHEMES_SCHEME_RESET')
        }
    }, [getCommissionsScheme, reset])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Comisiones'
                    },
                ]}
            />

            {((scheme.loading) || scheme.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : scheme.error ? (
                <ErrorBox
                    onRetry={() => {
                        getCommissionsScheme()
                    }}
                />
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                <CardHeader
                                    className={classes.header}
                                    title="Comisiones"
                                    subheader={"Esquema de comisiones"}
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 100,
                                                        step: .1,
                                                    }
                                                }}
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Comisión Promotor (%)"
                                                value={scheme.data.promoter}
                                                onChange={(e) => {
                                                    setFormPropertyValue('promoter', e.target.value)
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 100,
                                                        step: .1,
                                                    }
                                                }}
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Comisión Promotor ( Abuelo ) (%)"
                                                value={scheme.data.promoterLevel1}
                                                onChange={(e) => {
                                                    setFormPropertyValue('promoterLevel1', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 100,
                                                        step: .1,
                                                    }
                                                }}
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Comisión Promotor ( Padre ) (%)"
                                                value={scheme.data.promoterLevel2}
                                                onChange={(e) => {
                                                    setFormPropertyValue('promoterLevel2', e.target.value)
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 100,
                                                        step: .1,
                                                    }
                                                }}
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Comisión Mercado Pago (%)"
                                                value={scheme.data.gateway}
                                                onChange={(e) => {
                                                    setFormPropertyValue('gateway', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 100,
                                                        step: .1,
                                                    }
                                                }}
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Comisión Plataforma (%)"
                                                value={scheme.data.platform}
                                                onChange={(e) => {
                                                    setFormPropertyValue('platform', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        disabled={false}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            saveCommissionsScheme({
                                                comisionGatewayPagos: Number(scheme.data.gateway),
                                                comisionPlataforma: Number(scheme.data.platform),
                                                comisionPromotor: Number(scheme.data.promoter),
                                                comisionPromotorNivel0: Number(scheme.data.promoterLevel1),
                                                comisionPromotorNivel1: Number(scheme.data.promoterLevel2 ),
                                            })
                                        }}
                                    >
                                        GUARDAR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    scheme: state.commissionsSchemes.scheme,
})

const mapDispatchToProps = dispatch => ({
    getCommissionsScheme: () => dispatch(CommissionsSchemesActions.getCommissionsScheme()),
    saveCommissionsScheme: (data) => dispatch(CommissionsSchemesActions.saveCommissionsScheme(data)),
    setFormPropertyValue: (property, value) => dispatch(CommissionsSchemesActions.setFormPropertyValue(property, value)),
    reset: (type) => dispatch(CommissionsSchemesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(CommissionsSchemeForm);
