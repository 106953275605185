import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress, Typography} from '@material-ui/core'
import { Breadcrumb } from 'components'

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    content: {
        marginTop: theme.spacing(2)
    },
    paperRoot: {
        flexDirection: 'row',
        display: 'flex',
    },
    cardHeaderRoot: {
        flex: 1
    },
    cardContentRoot: {
        display: 'flex',
        justifyContent: 'flex-end',
        fontSize: '40px',
        fontFamily: 'Roboto',
        fontWeight: 500,
        padding: '10px 20px',
        background: '#a6b2cf',
        color: '#2a3142',
        '&:last-child': {
            paddingBottom: 10
        }
    },
}));

const Dashboard = () => {
    const classes = useStyles();

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Dashboard'
                    }
                ]}
            />

            {((false) ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="100px"
                >
                    <Typography style={{ fontSize: 35, fontWeight: 500, fontFamily: 'anton' }}>
                        PRÓXIMAMENTE
                    </Typography>
                </Box>
            ))}
        </div>
    );
};

export default Dashboard
