/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'
import moment from 'moment'

const CancelToken = axios.CancelToken;
let cancel;

export default class BookingRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getBookings
     */

    getBookings(params, criteria) {
        const url = `/api/v1/secured/reservas`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page: params ? params.page : null,
                pageCount: params ? params.pageCount : null,
                nombre: criteria ? criteria.name : null,
                sortField: params ? params.sortField : null,
                sortOrder: params ? params.sortOrder : null,
                FechaDesde: params ? params.dateFrom ? moment(params.dateFrom).format('yyyy-MM-DD 03:00:00') : null : null,
                FechaHasta: params ? params.dateTo ? moment(params.dateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null : null,
                ExperienciaFechaDesde: params ? params.experienceDateFrom ? moment(params.experienceDateFrom).format('yyyy-MM-DD 03:00:00') : null : null,
                ExperienciaFechaHasta: params ? params.experienceDateTo ? moment(params.experienceDateTo).add(1, 'day').format('yyyy-MM-DD 03:00:00') : null : null,
            }
        })
    }

    /*
     *  @getBooking
     */

    getBooking(id) {
        const url = `/api/v1/secured/reservas/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchBooking
     */

    patchBooking(id, data) {
        const url = `/api/v1/secured/reservas/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *  @updateBooking
     */

    updateBooking(id, data) {
        const url = `/api/v1/secured/reservas/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @cancelBooking
     */

    cancelBooking(id, data) {
        const url = `/api/v1/secured/reservas/${id}/cancelar`;

        return this.instance.put(url, {
            motivoAnulacion: 'Cancelación por Administrador'
        })
    }

    /*
     *  @getBookingReport
     */

    getBookingReport(dates) {
        const url = `/api/v1/secured/reservas/reporte`

        return this.instance.get(url, {
            responseType: 'arraybuffer',
            params: {
                FechaDesde: dates.dateFrom,
                FechaHasta: dates.dateTo,
            }
        })
    }
}