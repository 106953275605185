import moment from 'moment'
let date = new Date()

const defaultState = {
	initialLoading: true,
	loading: false,
	error: false,
	totals: 0,
	page: 0,
	pageCount: 10,
	data: [],
	dateFrom: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DDThh:mm:ss.SZ'),
	dateTo: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
	commission: {
		actions: {
			report: {
				loading: false,
				error: null,
				success: false
			}
		}
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'COMMISSIONS_FETCH_COMMISIONS_START':
			return Object.assign({}, state, {
				initialLoading: state.initialLoading || state.error ? true : false,
				loading: true,
				error: false,
			})

		case 'COMMISSIONS_FETCH_COMMISIONS_SUCCESS':
			return Object.assign({}, state, {
				initialLoading: false,
				loading: false,
				data: [].concat(action.payload.commissions),
				totals: action.payload.totals,
				pageCount: action.payload.pageCount,
				page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
			})

		case 'COMMISSIONS_FETCH_COMMISIONS_FAIL':
			return Object.assign({}, state, {
				initialLoading: false,
				loading: false,
				error: true,
			})

		case 'COMMISSIONS_RESET':
			return Object.assign({}, state, defaultState)

		case 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_START':
			return Object.assign({}, state, {
				commission: Object.assign({}, state.commission, {
					actions: Object.assign({}, state.commission.actions, {
						report: {
							loading: true,
							success: false,
							error: null,
						}
					})
				})
			})

		case 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_SUCCESS':
			return Object.assign({}, state, {
				commission: Object.assign({}, state.commission, {
					actions: Object.assign({}, state.commission.actions, {
						report: {
							loading: false,
							success: true,
							error: null,
						}
					})
				})
			})

		case 'COMMISSIONS_FETCH_COMMISSIONS_REPORT_FAIL':
			return Object.assign({}, state, {
				commission: Object.assign({}, state.commission, {
					actions: Object.assign({}, state.commission.actions, {
						report: {
							loading: false,
							success: false,
							error: true,
						}
					})
				})
			})

		case 'COMMISSIONS_SET_DATE_FROM':
			return Object.assign({}, state, {
				dateFrom: action.payload.dateFrom,
			})

		case 'COMMISSIONS_SET_DATE_TO':
			return Object.assign({}, state, {
				dateTo: action.payload.dateTo,
			})

		default:
			return state;
	}
}
