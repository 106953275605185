import React, { useEffect, useCallback } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box,Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider, Typography, IconButton } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { PostulationsActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom'
import Alert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AlertTitle } from '@material-ui/lab';
import Linkify from 'react-linkify';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  selectRoot: {
    height: '28px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  inputRoot: {
      '& .MuiInput-input': {
        height: '28px',
        lineHeight: '28px',
        fontWeight: 500,
      },
      
  },
}));

const PostulationsForm = props => {
    const {
        postulation,
        getPostulation,
        savePostulation,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getPostulation(match.params.id)
        }

        return () => {
            console.warn('nnnnnn')
            reset('POSTULATIONS_POSTULATION_RESET')
        }
    }, [getPostulation, reset, match.params.id])

    useEffect(() => {
        if (postulation.actions.save.success) {
            if (match.params.id) {
                getPostulation(match.params.id)
            }
        }

        return () => {
            reset('POSTULATIONS_POSTULATION_RESET')
        }
    }, [postulation.actions.save.success, getPostulation])

    const onClickAccept = useCallback((id)=> {
        savePostulation(id, { estado: 2 }, true)
    }, [savePostulation])

    const onClickReject = useCallback((id)=> {
        savePostulation(id, { estado: 3 }, true)
    }, [savePostulation])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Postulaciones'
                    },
                    {
                        name: 'Detalle'
                    }
                ]}
            />

            {((match.params.id && postulation.loading) ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>                            
                                <CardHeader
                                    className={classes.header}
                                    subheader={"Datos del usuario"}
                                    title="Postulación"
                                />

                                <Alert
                                    icon={false}
                                    severity="warning"
                                >
                                    {`Postulación presentada para ${postulation.data.type === 1 ? 'promotor' : 'proveedor'}`}
                                </Alert>

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Nombre"
                                                value={postulation.data.name}
                                                onChange={(e) => {
                                                    // Nothing to do ...
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Apellido"
                                                value={postulation.data.surname}
                                                onChange={(e) => {
                                                    // Nothing to do ...
                                                }}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Email"
                                                value={postulation.data.email}
                                                onChange={(e) => {
                                                    // Nothing to do ...
                                                }}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Teléfono"
                                                value={postulation.data.phone}
                                                onChange={(e) => {
                                                    // Nothing to do ...
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <CardHeader
                                    className={classes.header}
                                    subheader={"Información adicional"}
                                    title="Información"
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            {postulation.data.info ? (
                                                <Linkify
                                                    componentDecorator={(href, text, key) => (
                                                        <a href={href} key={key} target="_blank" style={{ color: '#0383eb' }}>
                                                            {text}
                                                        </a>
                                                    )}
                                                >
                                                    <Typography>
                                                        { postulation.data.info }
                                                    </Typography>
                                                </Linkify>
                                            ) : (
                                                <Typography>
                                                    No hay información adicional
                                                </Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                    
                                <CardHeader
                                    className={classes.header}
                                    subheader={"Usuario padre"}
                                    title="Jerarquía"
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    {(postulation.data.parent.id ? (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Alert
                                                    icon={false}
                                                    severity="info"
                                                    action={
                                                        <IconButton
                                                            aria-label="delete"
                                                            onClick={() => {
                                                                history.push(`/usuarios/detalle/${postulation.data.parent.id}`);
                                                            }}
                                                        >
                                                            <VisibilityIcon style={{ color: '#2a3042' }} />
                                                        </IconButton>
                                                    }
                                                >
                                                    <AlertTitle>{postulation.data.parent.name}</AlertTitle>
                                                    {`ID: ${postulation.data.parent.id}`}
                                                </Alert>
                                            </Grid>
                                        </Grid> 
                                    ) : (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12}>
                                                <Alert
                                                    severity="info"
                                                >
                                                    El usuario no posee un usuario padre en su jerarquía
                                                </Alert>
                                            </Grid>
                                        </Grid>
                                    ))}
                                </CardContent>
                            
                                <CardHeader
                                    className={classes.header}
                                    subheader={"Status de aprobación"}
                                    title="Status"
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    {postulation.data.status === 1 ? (
                                        <Alert
                                            icon={false}
                                            severity="info"
                                            action={
                                                <>
                                                    {postulation.actions.save.loading ? (
                                                        <CircularProgress size={15} />
                                                    ) : (
                                                        <>
                                                            <Button
                                                                style={{
                                                                    background: '#fff',
                                                                    color: '#5c9b5c',
                                                                }}
                                                                color="inherit"
                                                                size="small"
                                                                variant="contained"
                                                                onClick={() => onClickAccept(match.params.id)}
                                                            >
                                                                Aceptar
                                                            </Button>

                                                            <div style={{ width: 10, }}/>

                                                            <Button
                                                                style={{
                                                                    background: '#fff',
                                                                    color: '#f15757',
                                                                }}
                                                                color="inherit"
                                                                size="small"
                                                                variant="contained"
                                                                onClick={() => onClickReject(match.params.id)}
                                                            >
                                                                Rechazar
                                                            </Button>
                                                        </>
                                                    )}
                                                </>
                                            }
                                        >
                                            Pendiente de Aprobación
                                        </Alert>
                                    ) : postulation.data.status === 2 ? (
                                        <Alert
                                            icon={false}
                                            severity="success"
                                            action={
                                                postulation.actions.save.loading ? (
                                                    <CircularProgress size={15} />
                                                ) : (
                                                    <Button
                                                        style={{
                                                            background: '#fff',
                                                            color: '#f15757',
                                                        }}
                                                        color="inherit"
                                                        size="small"
                                                        variant="contained"
                                                        onClick={() => onClickReject(match.params.id)}
                                                    >
                                                        Rechazar
                                                    </Button>
                                                )
                                            }
                                        >
                                            Aprobada
                                        </Alert>
                                    ) : (
                                        <Alert
                                            icon={false}
                                            severity="error"
                                            action={
                                                <>
                                                    {postulation.actions.save.loading ? (
                                                        <CircularProgress size={15} />
                                                    ) : (
                                                        <Button
                                                            style={{
                                                                background: '#fff',
                                                                color: '#5c9b5c',
                                                            }}
                                                            color="inherit"
                                                            size="small"
                                                            variant="contained"
                                                            onClick={() => onClickAccept(match.params.id)}
                                                        >
                                                            Aceptar
                                                        </Button>
                                                    )}
                                                </>
                                            }
                                        >
                                            Rechazada
                                        </Alert>
                                    )}
                                </CardContent>

                                <Divider />

                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        VOLVER
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    postulation: state.postulations.postulation,
})

const mapDispatchToProps = dispatch => ({
    getPostulation: (id) => dispatch(PostulationsActions.getPostulation(id)),
    savePostulation: (id, data, patch) => dispatch(PostulationsActions.savePostulation(id, data, patch)),
    reset: (type) => dispatch(PostulationsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(PostulationsForm);
