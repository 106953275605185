import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider, Grid } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { ExperiencesActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import validate from 'validate.js'
import { useHistory, useRouteMatch } from 'react-router-dom'

const schema = {
    ranking: {
        presence: { allowEmpty: false, message: 'El ranking es requerido' },
        numericality: {
            onlyInteger: false,
            greaterThanOrEqualTo: 1,
            lessThanOrEqualTo: 1000,
            message: "El ranking debe ser mayor a 0 y menor o igual a 1000"
        },
        format: {
            pattern: "[0-9]+(.[5]?)?",
            flags: "i",
            message: "El ranking puede contener sólo 1 decimal igual a 5"
        }
    },
    rating: {
        presence: { allowEmpty: false, message: 'El rating es requerido' },
        numericality: {
            onlyInteger: false,
            greaterThanOrEqualTo: 0,
            lessThanOrEqualTo: 5,
            message: "El rating debe ser mayor o igual a 0 y menor o igual a 5"
        },
        format: {
            pattern: "^[0-5]{0,1}$|^[0-5]{1,1}.[5]?$",
            flags: "i",
            message: "El rating puede contener sólo 1 decimal igual a 5"
        }
    }
}

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  inputRoot: {
    '& .MuiInput-input': {
      height: '28px',
      lineHeight: '28px',
      fontWeight: 500,
    },
    
},
}));

const ExperienciesForm = props => {
    const {
        getExperience,
        saveExperience,
        setFormPropertyValue,
        experience,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    const [formState, setFormState] = useState({
        isValid: false,
        errors: {},
        touched: {},
    });

    const hasError = field => {
        return formState.errors[field] ? true : false;
    }

    useEffect(() => {
        const errors = validate(experience.data, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {},
        }));
    }, [experience.data]);

    useEffect(() => {
        if (match.params.id) {
            getExperience(match.params.id)
        }

        return () => reset('EXPERIENCES_EXPERIENCE_RESET')
    }, [getExperience, reset, match.params.id])

    useEffect(() => {
        if (experience.actions.save.success) {
            history.goBack()
        }
    }, [experience.actions.save.success, history])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Experiencias'
                    },
                    {
                        name: match.params.id ? 'Editar' : 'Nuevo'
                    }
                ]}
            />

            {((match.params.id && experience.loading) || experience.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                <CardHeader
                                    className={classes.header}
                                    title={experience.data.name || '---'}
                                    subheader={match.params.id ? "Editar Experiencia" : "Crear nueva experiencia"}
                                />
            
                                <Divider />
            
                                <CardContent className={classes.content}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 1000,
                                                        step: .5,
                                                    }
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                error={hasError('ranking')}
                                                helperText={
                                                    hasError('ranking') ? formState.errors.ranking[0] : null
                                                }
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Ranking"
                                                name="ranking"
                                                value={experience.data.ranking}
                                                onChange={(event) => {
                                                    setFormPropertyValue('ranking', event.target.value)
                                                }}
                                            />
                                        </Grid>
                                        
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                InputProps={{
                                                    inputProps: { 
                                                        min: 0,
                                                        max: 5,
                                                        step: .5,
                                                    }
                                                }}
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                error={hasError('rating')}
                                                helperText={
                                                    hasError('rating') ? formState.errors.rating[0] : null
                                                }
                                                type="number"
                                                margin="normal"
                                                fullWidth
                                                label="Rating"
                                                name="rating"
                                                value={experience.data.rating}
                                                onChange={(event) => {
                                                    setFormPropertyValue('rating', event.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>
            
                                <Divider />
            
                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        VOLVER
                                    </Button>
                                    
                                    <Button
                                        disabled={!formState.isValid || props.loading}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            if (match.params.id) {
                                                return saveExperience(experience.data.id, {
                                                    ranking: Number(experience.data.ranking),
                                                    rating: Number(experience.data.rating),
                                                }, true)
                                            }
                                        }}
                                    >
                                        GUARDAR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    experience: state.experiences.experience,
})

const mapDispatchToProps = dispatch => ({
    getExperience: (id) => dispatch(ExperiencesActions.getExperience(id)),
    saveExperience: (id, data, patch) => dispatch(ExperiencesActions.saveExperience(id, data, patch)),
    setFormPropertyValue: (property, value) => dispatch(ExperiencesActions.setFormPropertyValue(property, value)),
    reset: (type) => dispatch(ExperiencesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(ExperienciesForm);
