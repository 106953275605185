import moment from 'moment'
let date = new Date()

const defaultState = {
	loading: false,
	error: false,
	totals: 0,
	page: 0,
	pageCount: 10,
	dateFrom: moment(new Date(date.getFullYear(), date.getMonth(), 1)).format('YYYY-MM-DDThh:mm:ss.SZ'),
	dateTo: moment(new Date(date.getFullYear(), date.getMonth(), date.getDate())).format('YYYY-MM-DDThh:mm:ss.SZ'),
	data: [],
	actions: {
		search: {
			loading: false,
			error: false,
			success: false
		},
	},
	movement: {
		data: {
			movementType: null,
			mount: null,
			userId: null,
			details: null
		},
		actions: {
			save: {
				loading: false,
				success: false,
				error: false
			}
		}
	}
};

export default function reducer(state = defaultState, action) {
	switch (action.type) {
		case 'MOVEMENTS_SEARCH_MOVEMENTS_START':
			return Object.assign({}, state, {
				actions: Object.assign({}, state.actions, {
					search: {
						loading: true,
						error: false,
						success: false
					}
				}),
			})

		case 'MOVEMENTS_SEARCH_MOVEMENTS_SUCCESS':
			return Object.assign({}, state, {
				data: [].concat(action.payload.movements),
				totals: action.payload.totals,
				pageCount: action.payload.pageCount,
				page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page,
				actions: Object.assign({}, state.actions, {
					search: {
						loading: false,
						error: false,
						success: true
					}
				}),
			})

		case 'MOVEMENTS_SEARCH_MOVEMENTS_FAIL':
			return Object.assign({}, state, {
				actions: Object.assign({}, state.actions, {
					search: {
						loading: false,
						error: true,
						success: false
					}
				}),
			})

		case 'MOVEMENTS_FETCH_MOVEMENTS_START':
			return Object.assign({}, state, {
				loading: true
			})

		case 'MOVEMENTS_FETCH_MOVEMENTS_SUCCESS':
			return Object.assign({}, state, {
				loading: false,
				error: false,
				data: [].concat(action.payload.movements),
				totals: action.payload.totals,
				pageCount: action.payload.pageCount,
				page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page,
			})

		case 'MOVEMENTS_FETCH_MOVEMENTS_FAIL':
			return Object.assign({}, state, {
				loading: false,
				error: true
			})

		case 'MOVEMENTS_SET_DATE_FROM':
			return Object.assign({}, state, {
				dateFrom: action.payload.dateFrom,
			})

		case 'MOVEMENTS_SET_DATE_TO':
			return Object.assign({}, state, {
				dateTo: action.payload.dateTo,
			})

		case 'MOVEMENTS_FORM_PROPERTY_VALUE_SET':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					data: Object.assign({}, state.movement.data, {
						[action.payload.property]: action.payload.value
					})
				})
			})

		case 'MOVEMENTS_SAVE_MOVEMENT_START':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					actions: Object.assign({}, state.movement.actions, {
						save: {
							loading: true,
							success: false,
							error: null,
						}
					})
				})
			})

		case 'MOVEMENTS_SAVE_MOVEMENT_SUCCESS':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					actions: Object.assign({}, state.movement.actions, {
						save: {
							loading: true,
							success: true,
							error: null,
						}
					})
				})
			})

		case 'MOVEMENTS_SAVE_MOVEMENT_FAIL':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					actions: Object.assign({}, state.movement.actions, {
						save: {
							loading: false,
							success: false,
							error: true,
						}
					})
				})
			})

		case 'MOVEMENTS_SAVE_MOVEMENT_RESET':
			return Object.assign({}, state, {
				movement: Object.assign({}, state.movement, {
					data: Object.assign({}, state.movement.data, defaultState.movement.data),
					actions: Object.assign({}, state.movement.actions, {
						save: defaultState.movement.actions.save
					})
				})
			})

		case 'MOVEMENTS_RESET':
			return Object.assign({}, state, defaultState)

		default:
			return state;
	}
}