
// This function receives a number and correctly formats it to currency text

export function formatCurrencyData(number) {

	if (typeof number === 'number') {
		return new Intl.NumberFormat('es-AR', {
			maximumFractionDigits: 2,
			style: "currency",
			currency: "ARS"
		}).format(number)
	} else {

		return 'No hay información'
	}

}
