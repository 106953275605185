import React, { useState, useEffect, useRef } from 'react';
import { Route } from 'routes';
import { connect } from 'react-redux'
import { Switch, Redirect } from "react-router-dom";
import { Sidebar, Topbar } from 'layouts'
import { Dashboard as DashboardView } from './screens/dashboard';
import { Account as AccountView } from './screens/account';
import { CommissionsSchemeForm as CommissionsSchemeFormView } from './screens/commissions-scheme'
import { LanguagesList as LanguagesListView, LanguagesForm as LanguagesFormView } from './screens/languages'
import { CategoriesList as CategoriesListView, CategoriesForm as CategoriesFormView } from './screens/categories';
import { TagsList as TagsListView, TagsForm as TagsFormView } from './screens/tags';
import { LocalitiesList as LocalitiesListView, LocalitiesForm as LocalitiesFormView } from './screens/localities'
import { CountriesList as CountriesListView, CountriesForm as CountriesFormView } from './screens/countries'
import { ProvincesList as ProvincesListView, ProvincesForm as ProvincesFormView } from './screens/provinces'
import { ExperienciesList as ExperienciesListView, ExperienciesForm as ExperienciesFormView } from './screens/experiencies'
import { PostulationsList as PostulationsListView, PostulationsForm as PostulationsFormView } from './screens/postulations'
import { UsersList as UsersListView, UsersForm as UsersFormView } from './screens/users'
import { BookingsList as BookingsListView, BookingsForm as BookingsFormView } from './screens/bookings'
import { CommissionsList as CommissionsListView } from './screens/commissions'
import { ClientsList as ClientsListView } from './screens/clients'
import { MovementsList as MovementsListView, MovementsForm as MovementsFormView } from './screens/movements'
import { ContextActions, DashboardActions } from 'redux/actions'
import clsx from 'clsx';
import { useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { AnAlertBar, AnAlertBarManager } from 'components/an'

const useStyles = makeStyles(theme => ({
    root: {
        height: '100%',
        display: 'flex',
    },
    content: {
        overflowY: 'scroll',
        height: '100%',
        display: 'flex',
        width: '100%',
        paddingTop: '64px',
    }
}));

const Secured = React.memo(props => {
    const classes = useStyles();
    const theme = useTheme();

    const { user, error, success, clearError, clearSuccess } = props

    const alertRef = useRef(null)
    const [sidebarOpen, setSidebarOpen] = useState(true)
    const [is, setIs] = useState(useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    }))

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })

    if (isDesktop !== is) {
        setIs(isDesktop)
    };

    const handleSidebarOpen = () => {
        setSidebarOpen(true);
    };

    const handleSidebarClose = () => {
        setSidebarOpen(false);
    };

    const handleLogout = () => {
        props.logout()
    }

    useEffect(() => {
        if (!is) {
            setSidebarOpen(false)
        }
    }, [is])

    useEffect(() => {
        if (error) {
            clearError()
            return AnAlertBarManager.showAlertBar(alertRef.current, {
                title: error.title,
                description: error.description,
                type: 'error',
            });
        }

        if (success) {
            clearSuccess();

            return AnAlertBarManager.showAlertBar(alertRef.current, {
                title: success.title,
                description: success.description,
                type: 'success',
            });
        }
    }, [error, success, clearError, clearSuccess])

    return (
        <div
            className={clsx(classes.root)}
        >
            <Topbar
                onSidebarOpen={handleSidebarOpen}
                onSidebarClose={handleSidebarClose}
                onLogout={handleLogout}
                sidebarOpen={sidebarOpen}
                username={user.email}
            />

            <Sidebar
                onClose={handleSidebarClose}
                sidebarOpen={sidebarOpen}
                isDesktop={isDesktop}
                username={user.email}
            />

            <main id="scrollable" className={classes.content}>
                <AnAlertBar ref={alertRef} />

                <Switch>
                    <Redirect exact from='/' to="/dashboard" />
                    <Route secured exact path='/dashboard' component={DashboardView} />
                    <Route secured exact path='/mi-cuenta' component={AccountView} />
                    <Route secured exact path='/esquema-comisiones' component={CommissionsSchemeFormView} />
                    <Route secured exact path='/usuarios' component={UsersListView} />
                    <Route secured exact path='/usuarios/detalle/:id' component={UsersFormView} />
                    <Route secured exact path='/postulaciones' component={PostulationsListView} />
                    <Route secured exact path='/postulaciones/detalle/:id' component={PostulationsFormView} />
                    <Route secured exact path='/idiomas' component={LanguagesListView} />
                    <Route secured exact path='/idiomas/editar/:id' component={LanguagesFormView} />
                    <Route secured exact path='/idiomas/nuevo' component={LanguagesFormView} />
                    <Route secured exact path='/categorias' component={CategoriesListView} />
                    <Route secured exact path='/categorias/editar/:id' component={CategoriesFormView} />
                    <Route secured exact path='/categorias/nueva' component={CategoriesFormView} />
                    <Route secured exact path='/tags' component={TagsListView} />
                    <Route secured exact path='/tags/editar/:id' component={TagsFormView} />
                    <Route secured exact path='/tags/nuevo' component={TagsFormView} />
                    <Route secured exact path='/paises' component={CountriesListView} />
                    <Route secured exact path='/paises/editar/:id' component={CountriesFormView} />
                    <Route secured exact path='/paises/nuevo' component={CountriesFormView} />
                    <Route secured exact path='/provincias' component={ProvincesListView} />
                    <Route secured exact path='/provincias/editar/:id' component={ProvincesFormView} />
                    <Route secured exact path='/provincias/nueva' component={ProvincesFormView} />
                    <Route secured exact path='/localidades' component={LocalitiesListView} />
                    <Route secured exact path='/localidades/editar/:id' component={LocalitiesFormView} />
                    <Route secured exact path='/localidades/nueva' component={LocalitiesFormView} />
                    <Route secured exact path='/experiencias' component={ExperienciesListView} />
                    <Route secured exact path='/experiencias/editar/:id' component={ExperienciesFormView} />
                    <Route secured exact path='/reservas' component={BookingsListView} />
                    <Route secured exact path='/reservas/detalle/:id' component={BookingsFormView} />
                    <Route secured exact path='/clientes' component={ClientsListView} />
                    <Route secured exact path='/comisiones' component={CommissionsListView} />
                    <Route secured exact path='/movimientos' component={MovementsListView} />
                    <Route secured exact path='/movimientos/nuevo' component={MovementsFormView} />
                    <Redirect from='/*' to="/" />
                </Switch>
            </main>
        </div>
    );
})

const mapStateToProps = state => ({
    user: state.context.user,
    success: state.dashboard.success,
    error: state.dashboard.error,
})

const mapDispatchToProps = dispatch => ({
    clearSuccess: () => dispatch(DashboardActions.clearSuccess()),
    clearError: () => dispatch(DashboardActions.clearError()),
    logout: () => dispatch(ContextActions.setContext(null))
})

export default connect(mapStateToProps, mapDispatchToProps)(Secured);