/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;
export default class CommissionsRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext);
    }

    /*
     *   @getCommissions
     */

    getCommissions(page, pageCount, dates) {
        const url = `/api/v1/secured/comisiones/admin`;
        if (typeof cancel === 'function') cancel();

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                FechaDesde: dates.dateFrom,
                FechaHasta: dates.dateTo
            }
        });
    }

    /*
     *  @getCommissionReport
     */

    getCommissionReport(dates) {
        const url = `/api/v1/secured/comisiones/reporte`;

        return this.instance.get(url, {
            responseType: 'arraybuffer',
            params: {
                FechaDesde: dates.dateFrom,
                FechaHasta: dates.dateTo
            }
        });
    }
}
