import firebase from 'firebase/app'
import JWT from 'services/jwt';

function initializeContext() {
    return async dispatch => {
        const accessToken = localStorage.getItem('accessToken')

        setTimeout(() => {
            dispatch(setContext(accessToken ? JWT.parseToken(accessToken) : null))
        }, 0)
        
    }
}

function setContext(context) {
    if (!context) {
        firebase.auth().signOut()
            .then(() => {
                localStorage.removeItem('accessToken')
            })
    }

    return {
        type: 'CONTEXT_SET',
        payload: {
            user: context ? context : null
        }
    }
}


export const ContextActions = {
    initializeContext,
    setContext,
}