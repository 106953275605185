import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { CategoriesActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import CategoryIcon from '@material-ui/icons/Category';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
    },
  },
  emptyIcon: {
    fontSize: '200px',
    color: '#ccc'
  }
}));

const CategoriesList = props => {
    const {
        getCategories,
        saveCategory,
        categories,
        category,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        query,
        setQuery,
        reset
    } = props

    const {
        p,
        r,
        q,
    } = query;
    
    const classes = useStyles();
    const history = useHistory()

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('CATEGORIES_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getCategories(p, r, q)
        } else if (q.length === 0) {
            getCategories(p, r)
        }
    }, [p, r, q, getCategories])
    
    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/categorias/editar/${id}`);
    }, [history])

    const onClickToggleActivate = useCallback((id, inactive)=> {
        saveCategory(id, { inactivo: !inactive }, true)
    }, [saveCategory])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Categoría' },
    ]), [])

    const rows = useMemo(() => (
        categories.map(item => {
            return {
                view: [
                    { value: item.nombre },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [categories])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Categorías'
                    }
                ]}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getCategories(p, r, q)
                            } else if (q.length === 0) {
                                getCategories(p, r)
                            }
                        }}
                    />
                ) : (
                    <Box width="100%">
                        <div className={classes.content}>
                            <AnTable
                                toolbar={(
                                    <AnTableToolbar
                                        textSearchPlaceholder="Buscar categoría ...."
                                        textSearchValue={q}
                                        createButtonText="Crear Categoría"
                                        createButtonTo="/categorias/nueva"
                                        onSearch={onSearch}
                                    />
                                )}
                                isActiveSearch={isActiveSearch}
                                emptyIcon={(
                                    <CategoryIcon className={classes.emptyIcon} />
                                )}
                                emptyMessage="Aún no has creado ninguna categoría"
                                emptyButtonTo="/categorias/nueva"
                                emptyButtonText="Crear Categoría"
                                headers={headers}
                                rows={rows}
                                loading={loading}
                                save={category.actions.save}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onClickEdit={onClickEdit}
                                onClickToggleActivate={onClickToggleActivate}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
        </div>
    );
};

const mapStateToProps = state => ({
    initialLoading: state.categories.initialLoading,
    loading: state.categories.loading,
    error: state.categories.error,
    categories: state.categories.data,
    page: state.categories.page,
    pageCount: state.categories.pageCount,
    totals: state.categories.totals,
    isActiveSearch: state.categories.isActiveSearch,
    category: state.categories.category,
})

const mapDispatchToProps = dispatch => ({
    getCategories: (page, pageCount, criteria) => dispatch(CategoriesActions.getCategories(page, pageCount, criteria)),
    saveCategory: (id, data, patch) => dispatch(CategoriesActions.saveCategory(id, data, patch)),
    reset: (type) => dispatch(CategoriesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, ""), // The current search criteria
    }, CategoriesList)
);
