/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, { forwardRef } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Button, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    item: {
        borderBottom: '1px solid #3c474c',
        padding: '0px 0px',
    },
    button: props => ({
        color: '#a6b2cf',
        fontWeight: theme.typography.fontWeightMedium,
        display: 'flex',
        justifyContent: props.open ? 'flex-start' : 'center',
        padding: '11px 8px',
        borderRadius: '0px',

        '&.active': {
            color: '#fff',
        }
    }),

    icon: {
        width: 24,
        height: 24,
        display: 'flex',
        alignItems: 'center',
        marginRight: theme.spacing(1)
    },

    label: {
        fontWeight: 500,
        textTransform: 'capitalize'
    },
}));

const SidebarNav = props => {
  const { pages, open, isDesktop, onClose } = props;

  const classes = useStyles({ open });

  return (
        <List>
            {pages.map(page => {
                if (!page.collection) {
                    return (
                        <ListItem
                            className={classes.item}
                            disableGutters
                            key={page.title}
                        >
                            <Button
                                className={classes.button}
                                component={forwardRef((props, ref) => (
                                    <div
                                        ref={ref}
                                        style={{
                                            flexGrow: 1,
                                        }}
                                    >
                                        <RouterLink {...props} />
                                    </div>
                                ))}
                                to={page.href}
                                onClick={() => {
                                    if (!isDesktop) onClose()
                                }}
                            >
                                <div className={classes.icon}>
                                    {page.icon}
                                </div>
    
                                
                                <Typography color="inherit" className={classes.label}>
                                    {open && page.title}
                                </Typography>
                            </Button>
                        </ListItem>
                    )
                } else {
                    return null
                }
            })}
        </List>
    );
};

SidebarNav.propTypes = {
    className: PropTypes.string,
    pages: PropTypes.array.isRequired
};

export default SidebarNav;
