import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Input, Box, IconButton, Modal } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import FilterListIcon from '@material-ui/icons/FilterList';

const useStyles = makeStyles(theme => ({
  root: {
    borderRadius: '4px',
    alignItems: 'center',
    padding: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
    flexBasis: 420,

    [theme.breakpoints.down('sm')]: {
        flex: 1,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
    color: theme.palette.text.secondary
  },
  input: {
    flexGrow: 1,
    fontSize: '14px',
    lineHeight: '16px',
    letterSpacing: '-0.05px'
  },
  paper: {
    position: 'absolute',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
    outline: 'none',
  },
}));

const SearchInput = props => {
  const { onChange, style, ...rest } = props;

  const classes = useStyles();

  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
      setOpen(true)
  }

  const handleClose = () => {
      setOpen(false)
  }

  return (
    <Paper
      {...rest}
      className={clsx(classes.root)}
      style={style}
    >
      <SearchIcon className={classes.icon} />
      <Input
        {...rest}
        className={classes.input}
        disableUnderline
        onChange={onChange}
      />
      {/* <Box
        width="40px"
        marginLeft="10px"
        textAlign="right"
        justifyContent="flex-end"
        borderLeft="1px solid #ccc"
      >
          <IconButton
            style={{
                padding: '5px'
            }}
            onClick={handleOpen}
          >
              <FilterListIcon />
          </IconButton>
      </Box>

        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            >
                <div
                    style={{
                        top: '50%',
                        left: '50%',
                        transform: `translate(-50%, -50%)`,
                    }}
                    className={classes.paper}
                >
                    <h2 id="simple-modal-title">Filtros</h2>
                    <p id="simple-modal-description">
                        Aca van los filtros ....
                    </p>
                </div>
        </Modal> */}
    </Paper>
  );
};

SearchInput.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  style: PropTypes.object
};

export default SearchInput;
