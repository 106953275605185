
import axios from 'axios'
import { CountryRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getCountries(page, pageCount, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: 'COUNTRIES_FETCH_COUNTRIES_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })
            
            const response = await new CountryRestClient(true).getCountries(page, pageCount, criteria)

            dispatch({
                type: 'COUNTRIES_FETCH_COUNTRIES_SUCCESS',
                payload: {
                    page: page ? page : 0,
                    pageCount: pageCount ? pageCount : 10,
                    countries: response.data.items,
                    totals: response.data.totalItems,
                }
            })
            
        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'COUNTRIES_FETCH_COUNTRIES_FAIL'
                })
    
                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener los paises'
                    }
                })
            }
        }   
    }
}

function getCountry(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'COUNTRIES_FETCH_COUNTRY_START'
            })

            const response = await new CountryRestClient(true).getCountry(id)

            dispatch({
                type: 'COUNTRIES_FETCH_COUNTRY_SUCCESS',
                payload: {
                    country: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'COUNTRIES_TAGS_FETCH_COUNTRY_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener el pais'
                }
            })
        }
    }
}

function saveCountry(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'COUNTRIES_SAVE_COUNTRY_START',
                payload: {
                    id,
                    patch,
                }
            })

            if (id && !patch) {
                await new CountryRestClient(true).updateCountry(id, data)
            } else if (id && patch) {
                await new CountryRestClient(true).patchCountry(id, data)
            } else {
                await new CountryRestClient(true).createCountry(data)
            }

            dispatch({
                type: 'COUNTRIES_SAVE_COUNTRY_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: id
                        ? 'El país se ha actualizado correctamente'
                        : 'El país se ha creado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'COUNTRIES_SAVE_COUNTRY_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'COUNTRIES_SAVE_COUNTRY_RESET'
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'COUNTRIES_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}

function reset(type) {
    return {
        type,
    }
}

export const CountriesActions = {
    getCountries,
    getCountry,
    saveCountry,
    setFormPropertyValue,
    reset,
}