import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { AppBar, Toolbar, IconButton, Typography, Avatar, Box, ListItem,  Menu, MenuItem, ListItemText, Button, Divider} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { useHistory } from 'react-router-dom'

const drawerWidth = 240;
const drawerWidthCollapsed = 72;

const useStyles = makeStyles(theme => ({
    appBar: {
        boxShadow: 'none',
        background: '#fff',
        
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidthCollapsed}px)`,
        },
    },
    appBarShift: {
        [theme.breakpoints.up('md')]: {
            width: `calc(100% - ${drawerWidth}px)`,
        }
    },
    toolbar: {
        justifyContent: "space-between",
        minHeight: 64,
    },
    menuButton: {
        marginRight: 36,
    },
    iconListItem: {
        width: "auto",
        borderRadius: theme.shape.borderRadius,
        paddingTop: 11,
        paddingBottom: 11,
        marginLeft: theme.spacing(1),
    },
    smBordered: {
        [theme.breakpoints.down("xs")]: {
            borderRadius: "50% !important"
        }
    },
    accountAvatar: {
        backgroundColor: theme.palette.secondary.main,
        height: 24,
        width: 24,
        marginRight: theme.spacing(2),
        [theme.breakpoints.down("xs")]: {
            marginLeft: theme.spacing(1.5),
            marginRight: theme.spacing(1.5)
        }
    },
    username: {
        paddingLeft: 0,
        
        '& .MuiTypography-root': {
            maxWidth: '230px',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis',

        }
    },
}));


const Topbar = props => {
	const { onSidebarOpen, onSidebarClose, onLogout, sidebarOpen, username } = props;

    const classes = useStyles();
    const history = useHistory()

    const [anchorEl, setAnchorEl] = React.useState(null);

    const onClick = () => {
        if (sidebarOpen) {
            return onSidebarClose()
        } else {
            onSidebarOpen()
        }
    }

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    
    const handleClose = (cb) => {
        setAnchorEl(null);

        if (typeof cb === 'function') {
            cb()
        }
    };
    
    return (
        <AppBar color="transparent" position="absolute" className={clsx(classes.appBar, sidebarOpen && classes.appBarShift)}>
            <Toolbar className={classes.toolbar}>
                <Box display='flex' flexDirection='row'>
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={onClick}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton>
                </Box>
                
                <Box display="flex" flexDirection="row">
                    <ListItem
                        disableGutters
                        className={clsx(classes.iconListItem, classes.smBordered)}
                    >
                        <Button
                            aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}
                        >
                            <Avatar
                                alt="profile picture"
                                src="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
                                className={classes.accountAvatar}
                            />

                            <ListItemText
                                className={classes.username}
                                primary={
                                    <Typography
                                        color="inherit"
                                        component="h6"
                                        variant="body2"
                                    >
                                        {username}
                                    </Typography>
                                }
                            />
                        </Button>

                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                onClick={() => {
                                    handleClose(() => {
                                        history.push(`/mi-cuenta`);
                                    })
                                }}
                            >
                                <Avatar
                                    alt="profile picture"
                                    src="https://miro.medium.com/max/525/1*lyyXmbeoK5JiIBNCnzzjjg.png"
                                    style={{
                                        backgroundColor: '#ccc',
                                        height: 24,
                                        width: 24,
                                    }}
                                />

                                <Typography style={{ marginLeft: 10 }}>Mi Cuenta</Typography>
                            </MenuItem>
                            <Divider />
                            
                            <Divider />
                            <MenuItem onClick={onLogout}>
                                <ExitToAppIcon />
                                <Typography style={{ marginLeft: 10 }}>Salir</Typography>
                            </MenuItem>
                        </Menu>
                    </ListItem>
                </Box>
            </Toolbar>
        </AppBar>
    )
};

Topbar.propTypes = {
	className: PropTypes.string,
	onSidebarOpen: PropTypes.func
};

export default Topbar
