import React, { useCallback, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider, Switch } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { CountriesActions, ProvincesActions, LocalitiesActions } from 'redux/actions';
import { AnDropdown } from 'components/an'
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    header: {
        background: '#f3f3f3b3',
        padding: '10px 16px',
    },
    content: {
        marginTop: theme.spacing(2)
    },
    selectRoot: {
        height: '28px',
        lineHeight: '28px',
        fontWeight: 500,
    },
    inputRoot: {
        '& .MuiInput-input': {
            height: '28px',
            lineHeight: '28px',
            fontWeight: 500,
        },

    },
    switchLoader: {
        margin: 15
    }
}));

const LocalitiesForm = props => {
    const {
        countries,
        provinces,
        getCountries,
        getProvinces,
        getLocality,
        saveLocality,
        setFormPropertyValue,
        locality,
        reset,
        resetCountries,
        resetProvinces,
        updateFavorite,
    } = props

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();

    const transitionStyles = {
        entered: { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getLocality(match.params.id)
            getCountries(null, null)
        } else {
            getCountries(null, null)
        }

        return () => {
            resetCountries('COUNTRIES_RESET')
            resetProvinces('PROVINCES_RESET')
            reset('LOCALITIES_LOCALITY_RESET')
        }
    }, [getLocality, getCountries, resetCountries, resetProvinces, reset, match.params.id])

    useEffect(() => {
        if (locality.data.countryId) {
            getProvinces(null, null, locality.data.countryId)
        }
    }, [locality.data.countryId, getProvinces])

    useEffect(() => {
        if (locality.actions.save.success) {
            history.goBack()
        }
    }, [locality.actions.save.success, history])

    const handleSwitchFavorite = useCallback(() => {
        if (match.params.id) {
            updateFavorite(locality.data.id, {
                favorito: !locality.data.favorite,
            }, true, false)
        } else {
            setFormPropertyValue('favorite', !locality.data.favorite)
        }
    }, [updateFavorite, setFormPropertyValue, locality]);

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Localidades'
                    },
                    {
                        name: match.params.id ? 'Editar' : 'Nueva'
                    }
                ]}
            />

            {((match.params.id && locality.loading) || locality.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                    <Transition in={true} appear={true} timeout={0}>
                        {state => (
                            <Box paddingBottom={3}>
                                <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                    <CardHeader
                                        className={classes.header}
                                        title="Localidad"
                                        subheader={match.params.id ? "Editar localidad" : "Crear nueva localidad"}
                                    />

                                    <Divider />

                                    <CardContent className={classes.content}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12} sm={6}>
                                                <AnDropdown
                                                    label="País"
                                                    value={locality.data.countryId}
                                                    onChange={(e) => {
                                                        setFormPropertyValue('countryId', e.target.value)
                                                        setFormPropertyValue('provinceId', null)
                                                    }}
                                                    inputProps={{
                                                        name: 'País',
                                                        id: 'country',
                                                    }}
                                                    displayEmpty
                                                    loadingOptions={countries.loading}
                                                    loadingOptionsLabel="Cargando opciones ..."
                                                    emptyOptionsLabel="No hay opciones"
                                                    options={countries.data.map((country) => ({
                                                        id: country.id,
                                                        name: country.nombre,
                                                        inactive: country.inactivo
                                                    }))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <AnDropdown
                                                    label="Provincia"
                                                    value={locality.data.provinceId}
                                                    onChange={(e) => {
                                                        setFormPropertyValue('provinceId', e.target.value)
                                                    }}
                                                    inputProps={{
                                                        name: 'Provincia',
                                                        id: 'province',
                                                    }}
                                                    disabled={!locality.data.countryId || provinces.loading || provinces.data.length === 0}
                                                    displayEmpty
                                                    loadingOptions={provinces.loading}
                                                    loadingOptionsLabel="Cargando opciones ..."
                                                    emptyOptionsLabel="No hay opciones"
                                                    options={provinces.data.map((province) => ({
                                                        id: province.id,
                                                        name: province.nombre,
                                                        inactive: province.inactivo
                                                    }))}
                                                />
                                            </Grid>

                                            <Grid item xs={12} sm={6}>
                                                <TextField
                                                    classes={{
                                                        root: classes.inputRoot,
                                                    }}
                                                    InputLabelProps={{
                                                        shrink: true,
                                                    }}
                                                    margin="normal"
                                                    fullWidth
                                                    label="Localidad"
                                                    value={locality.data.name}
                                                    onChange={(e) => {
                                                        setFormPropertyValue('name', e.target.value)
                                                    }}
                                                />
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <CardHeader
                                        className={classes.header}
                                        title="Localidad Frecuente"
                                    />

                                    <Divider />

                                    <CardContent>
                                        <Grid container>
                                            <Grid item xs={12}>
                                                <div>
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Alert
                                                                icon={false}
                                                                severity="info"
                                                                action={
                                                                    <>
                                                                        {locality.actions.updateFavorite.loading ?
                                                                            <CircularProgress
                                                                                size={25}
                                                                                className={classes.switchLoader}
                                                                            />
                                                                            :
                                                                            <Switch
                                                                                checked={locality.data.favorite}
                                                                                name="favorite"
                                                                                color="primary"
                                                                                onChange={handleSwitchFavorite}
                                                                            />}
                                                                    </>
                                                                }
                                                            >
                                                                <AlertTitle>Localidad Frecuente</AlertTitle>
                                                                Permite definir a la localidad para que aparezca por defecto en el listado de ubicaciones sin necesidad de tener que buscarla
                                                            </Alert>
                                                        </Grid>
                                                    </Grid>
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </CardContent>

                                    <Divider />

                                    <CardActions
                                        style={{
                                            justifyContent: 'flex-end'
                                        }}
                                    >
                                        <Button
                                            color="primary"
                                            onClick={() => {
                                                history.goBack()
                                            }}
                                        >
                                            VOLVER
                                    </Button>

                                        <Button
                                            disabled={!locality.data.name || !locality.data.provinceId || !locality.data.countryId}
                                            color="primary"
                                            variant="contained"
                                            onClick={() => {
                                                if (match.params.id) {
                                                    return saveLocality(locality.data.id, {
                                                        nombre: locality.data.name,
                                                        provinciaId: locality.data.provinceId,
                                                        favorito: locality.data.favorite
                                                    })
                                                }

                                                saveLocality(null, {
                                                    nombre: locality.data.name,
                                                    provinciaId: locality.data.provinceId,
                                                    favorito: locality.data.favorite
                                                })
                                            }}
                                        >
                                            GUARDAR
                                    </Button>
                                    </CardActions>
                                </Card>
                            </Box>
                        )}
                    </Transition>
                ))}
        </div>
    );
};

const mapStateToProps = state => ({
    countries: state.countries,
    provinces: state.provinces,
    locality: state.localities.locality,
})

const mapDispatchToProps = dispatch => ({
    getCountries: (page, pageCount) => dispatch(CountriesActions.getCountries(page, pageCount)),
    getProvinces: (page, pageCount, countryId) => dispatch(ProvincesActions.getProvinces(page, pageCount, countryId)),
    getLocality: (id) => dispatch(LocalitiesActions.getLocality(id)),
    setFormPropertyValue: (property, value) => dispatch(LocalitiesActions.setFormPropertyValue(property, value)),
    saveLocality: (id, data, patch) => dispatch(LocalitiesActions.saveLocality(id, data, patch)),
    resetCountries: (type) => dispatch(CountriesActions.reset(type)),
    resetProvinces: (type) => dispatch(ProvincesActions.reset(type)),
    reset: (type) => dispatch(LocalitiesActions.reset(type)),
    updateFavorite: (id, data, patch) => dispatch(LocalitiesActions.updateFavorite(id, data, patch))
})

export default connect(mapStateToProps, mapDispatchToProps)(LocalitiesForm);
