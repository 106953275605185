import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Paper, Box, Button } from '@material-ui/core'
import { Alert, AlertTitle } from '@material-ui/lab'

const useStyles = makeStyles(() => ({
    
}))

const ErrorBox = React.memo(props => {
    const {
        onRetry,
    } = props;

    return (
        <Box width='100%'>
            <Paper elevation={1}>
                <Alert severity="error">
                    <AlertTitle>
                        Error
                    </AlertTitle>
                    
                    UPS! Ha ocurrido un error al obtener los datos.
                </Alert>
            </Paper>

            <Box
                display="flex"
                justifyContent="flex-end"
                marginTop="20px"
            >
                <Button
                    color="primary"
                    variant="contained"
                    onClick={onRetry}
                >
                    Reintentar
                </Button>
            </Box>
        </Box>
    )
})

export default ErrorBox