import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { ProvincesActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import BlurCircularRoundedIcon from '@material-ui/icons/BlurCircularRounded';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
    },
  },
  emptyIcon: {
    fontSize: '200px',
    color: '#ccc'
  }
}));

const ProvincesList = React.memo(props => {
    const {
        getProvinces,
        provinces,
        province,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        saveProvince,
        query,
        setQuery,
        reset
    } = props

    const {
        p,
        r,
        q,
    } = query;
    
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('PROVINCES_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getProvinces(p, r, null, q)
        } else if (q.length === 0) {
            getProvinces(p, r)
        }
    }, [p, r, q, getProvinces])

    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/provincias/editar/${id}`);
    }, [history])

    const onClickToggleActivate = useCallback((id, inactive)=> {
        saveProvince(id, { inactivo: !inactive }, true)
    }, [saveProvince])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Provincia' },
        { label: 'País' },
    ]), [])

    const rows = useMemo(() => (
        provinces.map(item => {
            return {
                view: [
                    { value: item.nombre },
                    { value: item.pais.nombre },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [provinces])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Provincias'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getProvinces(p, r, null, q)
                            } else if (q.length === 0) {
                                getProvinces(p, r)
                            }
                        }}
                    />
                ) :  (
                    <Box width="100%">
                        <div className={classes.content}>
                            <AnTable
                                toolbar={(
                                    <AnTableToolbar
                                        textSearchPlaceholder="Buscar provincia ...."
                                        textSearchValue={q}
                                        createButtonText="Crear Provincia"
                                        createButtonTo="/provincias/nueva"
                                        onSearch={onSearch}
                                    />
                                )}
                                isActiveSearch={isActiveSearch}
                                emptyIcon={(
                                    <BlurCircularRoundedIcon className={classes.emptyIcon} />
                                )}
                                emptyMessage="Aún no has creado ninguna provincia"
                                emptyButtonTo="/provincias/nueva"
                                emptyButtonText="Crear Provincia"
                                headers={headers}
                                rows={rows}
                                loading={loading}
                                save={province.actions.save}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onClickEdit={onClickEdit}
                                onClickToggleActivate={onClickToggleActivate}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
        </div>
    );
});

const mapStateToProps = state => ({
    initialLoading: state.provinces.initialLoading,
    loading: state.provinces.loading,
    error: state.provinces.error,
    provinces: state.provinces.data,
    page: state.provinces.page,
    pageCount: state.provinces.pageCount,
    totals: state.provinces.totals,
    isActiveSearch: state.provinces.isActiveSearch,
    province: state.provinces.province,
})

const mapDispatchToProps = dispatch => ({
    getProvinces: (page, pageCount, countryId, criteria) => dispatch(ProvincesActions.getProvinces(page, pageCount, countryId, criteria)),
    saveProvince: (id, data, patch) => dispatch(ProvincesActions.saveProvince(id, data, patch)),
    reset: (type) => dispatch(ProvincesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, "")// The current search criteria
    }, ProvincesList)
);
