import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Box,
    TextField,
    Button,
    CircularProgress,
    Card,
    CardHeader,
    CardContent,
    CardActions,
    Divider,
    Grid,
    List,
    IconButton,
    withStyles,
    alpha,
    Paper,
    InputBase
} from '@material-ui/core';
import { Breadcrumb } from 'components';
import { connect } from 'react-redux';
import { UsersActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom';
import Alert from '@material-ui/lab/Alert';
import VisibilityIcon from '@material-ui/icons/Visibility';
import { AlertTitle, Autocomplete, TreeItem, TreeView } from '@material-ui/lab';
import { UserRestClient } from 'http/resources';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        }
    },
    header: {
        background: '#f3f3f3b3',
        padding: '10px 16px'
    },
    content: {
        marginTop: theme.spacing(2)
    },
    inputRoot: {
        '& .MuiInput-input': {
            height: '28px',
            lineHeight: '28px',
            fontWeight: 500
        }
    },
    avatar: {
        height: 100,
        width: 100,
        flexShrink: 0,
        flexGrow: 0
    },
    buttonProgress: {
        marginLeft: 12
    }
}));

const alertStyles = makeStyles(theme => ({
    message: {
        width: '25%'
    }
}));

const UsersForm = props => {
    const [parentSelected, setParentSelected] = React.useState(null);
    const [inputValue, setInputValue] = React.useState('');
    const [usersOptions, setUserOptions] = React.useState([]);
    const [grandfatherInfo, setGrandfatherInfo] = React.useState(null);
    const [showGrandfather, setShowGrandfather] = React.useState(false);

    const {
        getUser,
        user,
        reset,
        putUser,
        initialLoading,
        getUsers,
        users,
        setUserData
    } = props;

    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    const alertStylesClasses = alertStyles();

    const transitionStyles = {
        entered: { transform: 'translateY(0) scale(1)' }
    };

    useEffect(() => {
        if (match.params.id) {
            getUser(match.params.id);
        }

        return () => reset('USERS_USER_RESET');
    }, [getUser, reset, match.params.id]);

    useEffect(() => {
        // Si posee padre guardar la información y traer la data de este mismo
        if (user.data.parent.id) {
            getParentInfo(parentSelected.id)
                .then(res => {
                    setShowGrandfather(false);
                    if (res.data.parent) {
                        setGrandfatherInfo({
                            id: res.data.parent.id,
                            name: res.data.parent.nombre
                        });
                    } else {
                        setGrandfatherInfo(null);
                    }
                })
                .catch(err => console.log(err));
        }
    }, [parentSelected]);

    useEffect(() => {
        setParentSelected(user.data.parent);
    }, [user.data.parent]);

    const getParentInfo = async id => {
        const response = await new UserRestClient(true).getUser(id);

        return response;
    };

    const handleSelectOtherParent = (event, newValue) => {
        if (newValue) {
            setUserData({
                name: 'parent',
                value: {
                    id: newValue.id,
                    name: `${newValue.nombre} ${newValue.apellido}`
                }
            });
        }
    };

    const handleShowGrandFather = () => {
        setShowGrandfather(true);
    };

    const handleOnSearchCriteriaChange = e => {
        if (e.target.value.length > 2) {
            getUsers(0, 20, { name: e.target.value });
        }
    };

    useEffect(() => {
        if (users.length) {
            setUserOptions(users);
        }
    }, [users]);

    const handleOnChangeText = e => {
        setUserData({ name: e.target.name, value: e.target.value });
    };

    const confirmUpdateUserData = e => {
        const { name, surname, code, parent } = user.updatedUser;

        putUser(user.data.id, {
            nombre: name,
            apellido: surname,
            codigo: code,
            parentId: parent.id
        });
    };

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Usuarios'
                    },
                    {
                        name: 'Detalles'
                    }
                ]}
            />

            {match.params.id && user.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px">
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card
                                style={{
                                    transform: 'translateY(15px) scale(0.99)',
                                    transition: theme.transitions.create(
                                        ['transform'],
                                        {
                                            duration: 150,
                                            easing:
                                                theme.transitions.easing.sharp
                                        }
                                    ),
                                    ...transitionStyles[state]
                                }}>
                                <CardHeader
                                    className={classes.header}
                                    title="Usuario"
                                    subheader="Datos del usuario"
                                />

                                <Alert icon={false} severity="warning">
                                    {`Preferencia de usuario: ${
                                        user.data.type === 1
                                            ? 'Promotor'
                                            : 'Proveedor'
                                    }`}
                                </Alert>

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Nombre"
                                                value={user.updatedUser.name}
                                                name="name"
                                                onChange={handleOnChangeText}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Apellido"
                                                name="surname"
                                                value={user.updatedUser.surname}
                                                onChange={handleOnChangeText}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid container spacing={1}>
                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                margin="normal"
                                                fullWidth
                                                name="email"
                                                inputProps={{
                                                    readOnly: true
                                                }}
                                                label="Email"
                                                value={user.updatedUser.email}
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6}>
                                            <TextField
                                                InputLabelProps={{
                                                    shrink: true
                                                }}
                                                classes={{
                                                    root: classes.inputRoot
                                                }}
                                                margin="normal"
                                                name="code"
                                                fullWidth
                                                label="Código Promotor"
                                                value={user.updatedUser.code}
                                                onChange={handleOnChangeText}
                                            />
                                        </Grid>
                                    </Grid>

                                    {!!user.updatedUser.parent.id && (
                                        <Grid container spacing={1}>
                                            <Grid item xs={12} sm={6}>
                                                <Autocomplete
                                                    id="combo-box"
                                                    options={usersOptions.filter(
                                                        item =>
                                                            item.id !==
                                                                user.data.id &&
                                                            !user.data.childs.some(
                                                                child =>
                                                                    child.id ===
                                                                    item.id
                                                            )
                                                    )}
                                                    getOptionLabel={option =>
                                                        option.name ||
                                                        `${option.nombre} ${option.apellido}`
                                                    }
                                                    fullWidth
                                                    getOptionSelected={option =>
                                                        option.name ||
                                                        `${option.nombre} ${option.apellido}`
                                                    }
                                                    value={
                                                        user.updatedUser.parent
                                                    }
                                                    inputValue={inputValue}
                                                    noOptionsText="No se encontró usuario"
                                                    loadingText={
                                                        inputValue.length > 0
                                                            ? 'Buscando usuario..'
                                                            : 'Buscar usuario..'
                                                    }
                                                    onInputChange={(
                                                        e,
                                                        newInputValue
                                                    ) => {
                                                        setInputValue(
                                                            newInputValue
                                                        );
                                                    }}
                                                    onChange={
                                                        handleSelectOtherParent
                                                    }
                                                    loading={initialLoading}
                                                    renderInput={params => (
                                                        <TextField
                                                            {...params}
                                                            label="Usuario padre"
                                                            classes={{
                                                                root:
                                                                    classes.inputRoot
                                                            }}
                                                            onChange={
                                                                handleOnSearchCriteriaChange
                                                            }
                                                            margin="normal"
                                                            InputLabelProps={{
                                                                shrink: true
                                                            }}
                                                            InputProps={{
                                                                ...params.InputProps,
                                                                style: {
                                                                    fontWeight:
                                                                        '500'
                                                                }
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                        </Grid>
                                    )}
                                </CardContent>
                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        disabled={
                                            user.actions.updateUser.loading ||
                                            (user.updatedUser.name ===
                                                user.data.name &&
                                                user.updatedUser.surname ===
                                                    user.data.surname &&
                                                user.updatedUser.parent.id ===
                                                    user.data.parent.id &&
                                                user.updatedUser.code ===
                                                    user.data.code)
                                        }
                                        onClick={confirmUpdateUserData}>
                                        ACTUALIZAR USUARIO
                                        {user.actions.updateUser.loading && (
                                            <CircularProgress
                                                size={20}
                                                className={
                                                    classes.buttonProgress
                                                }
                                            />
                                        )}
                                    </Button>
                                </CardActions>

                                <CardHeader
                                    className={classes.header}
                                    title="Jerarquía"
                                    subheader="Arbol genealógico"
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    {grandfatherInfo?.id && !showGrandfather && (
                                        <Button
                                            color="primary"
                                            size="small"
                                            style={{ marginBottom: 4 }}
                                            onClick={handleShowGrandFather}>
                                            Ver más..
                                        </Button>
                                    )}

                                    <Box>
                                        {grandfatherInfo?.id &&
                                            showGrandfather && (
                                                <Alert
                                                    icon={false}
                                                    severity={'info'}
                                                    style={{
                                                        marginTop: 10,
                                                        marginLeft: 0
                                                    }}
                                                    action={
                                                        <IconButton
                                                            onClick={() => {
                                                                history.push(
                                                                    `/usuarios/detalle/${grandfatherInfo.id}`
                                                                );
                                                            }}>
                                                            <VisibilityIcon
                                                                style={{
                                                                    color:
                                                                        '#2a3042'
                                                                }}
                                                            />
                                                        </IconButton>
                                                    }>
                                                    <AlertTitle>
                                                        {grandfatherInfo?.name}
                                                    </AlertTitle>
                                                    {`ID: ${grandfatherInfo?.id}`}
                                                </Alert>
                                            )}

                                        {/**
                                         * PARENT
                                         * If parent.id exists means user has father
                                         * */}

                                        {user.data.parent.id && (
                                            <Alert
                                                icon={false}
                                                severity={'info'}
                                                classes={{
                                                    message:
                                                        alertStylesClasses.message
                                                }}
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: showGrandfather
                                                        ? 35
                                                        : 0
                                                }}
                                                action={
                                                    <IconButton
                                                        onClick={() => {
                                                            history.push(
                                                                `/usuarios/detalle/${user.data.parent.id}`
                                                            );
                                                        }}>
                                                        <VisibilityIcon
                                                            style={{
                                                                color: '#2a3042'
                                                            }}
                                                        />
                                                    </IconButton>
                                                }>
                                                <AlertTitle>
                                                    <AlertTitle>
                                                        {`${user.data.parent.name}  `}
                                                    </AlertTitle>
                                                </AlertTitle>
                                                {`ID: ${user.data.parent.id}`}
                                            </Alert>
                                        )}

                                        {/* CURRENT */}
                                        <Alert
                                            icon={false}
                                            severity={'success'}
                                            style={{
                                                marginTop: 10,
                                                marginLeft: showGrandfather
                                                    ? 70
                                                    : user.data.parent.id
                                                    ? 35
                                                    : 0
                                            }}>
                                            <AlertTitle>
                                                {`${user.data.name} ${user.data.surname}`}
                                            </AlertTitle>
                                            {`ID: ${user.data.id}`}
                                        </Alert>

                                        {/* CHILDS */}
                                        {user.data.childs &&
                                            user.data.childs.length > 0 &&
                                            user.data.childs.map((child, i) => (
                                                <Alert
                                                    key={i}
                                                    icon={false}
                                                    severity={'info'}
                                                    style={{
                                                        marginTop: 10,
                                                        marginLeft: showGrandfather
                                                            ? 105
                                                            : user.data.parent
                                                                  .id
                                                            ? 70
                                                            : 35
                                                    }}
                                                    action={
                                                        <IconButton
                                                            onClick={() => {
                                                                history.push(
                                                                    `/usuarios/detalle/${child.id}`
                                                                );
                                                            }}>
                                                            <VisibilityIcon
                                                                style={{
                                                                    color:
                                                                        '#2a3042'
                                                                }}
                                                            />
                                                        </IconButton>
                                                    }>
                                                    <AlertTitle>
                                                        {child.nombre}
                                                    </AlertTitle>
                                                    {`ID: ${child.id}`}
                                                </Alert>
                                            ))}
                                    </Box>
                                </CardContent>

                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}>
                                    <Button
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            history.goBack();
                                        }}>
                                        VOLVER
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            )}
        </div>
    );
};

const mapStateToProps = state => ({
    user: state.users.user,
    initialLoading: state.users.initialLoading,
    updatedUser: state.users.updatedUser,
    users: state.users.data
});

const mapDispatchToProps = dispatch => ({
    getUser: id => dispatch(UsersActions.getUser(id)),
    putUser: (id, data) => dispatch(UsersActions.putUser(id, data)),
    reset: type => dispatch(UsersActions.reset(type)),
    setUserData: payload => dispatch(UsersActions.setUserData(payload)),
    getUsers: (page, pageCount, criteria) =>
        dispatch(UsersActions.getUsers(page, pageCount, criteria))
});

export default connect(mapStateToProps, mapDispatchToProps)(UsersForm);
