import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { CommissionsActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an';
import { Breadcrumb, ErrorBox } from 'components';
import CategoryIcon from '@material-ui/icons/Category';
import {
    withQueryParams,
    StringParam,
    NumberParam,
    withDefault
} from 'use-query-params';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { CommissionsTypes } from 'consts';

const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3)
        }
    },
    content: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3)
        }
    },
    emptyIcon: {
        fontSize: '200px',
        color: '#ccc'
    },
    link: {
        color: 'rgb(3, 131, 235)',
        textDecoration: 'underline'
    }
}));

const CommissionsList = props => {
    const {
        getCommissions,
        commissions,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        query,
        setQuery,
        reset,

        report,
        dateFrom,
        dateTo,

        setDateFrom,
        setDateTo,

        getCommissionReport
    } = props;

    const { p, r, t, f } = query;

    const classes = useStyles();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('COMMISSIONS_RESET');
        };
    }, [reset]);

    useEffect(() => {
        getCommissions(p, r, f, t);
    }, [p, r, t, f, getCommissions]);

    useEffect(() => {
        if (f.length > 0 && t.length > 0) {
            setDateFrom(f);
            setDateTo(t);
        } else {
            setQuery({ t: dateTo, f: dateFrom });
        }
    }, [f, t]);

    const onPageChange = useCallback(
        page => {
            setQuery({ p: page > 0 ? page : undefined });
        },
        [setQuery]
    );

    const onPageCountChange = useCallback(
        pageCount => {
            setQuery({ r: pageCount !== 10 ? pageCount : undefined });
        },
        [setQuery]
    );

    const handleDateFromChange = useCallback(
        date => {
            if (date && date._isValid) {
                setQuery({
                    p: 0,
                    f: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ')
                });
                setDateFrom(date);
            }
        },
        [setQuery]
    );

    const handleDateToChange = useCallback(
        date => {
            if (date && date._isValid) {
                setQuery({
                    p: 0,
                    t: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ')
                });
                setDateTo(date);
            }
        },
        [setQuery]
    );

    const onClickDownloadExcel = useCallback(() => {
        getCommissionReport(f, t);
    }, [setQuery, f, t]);

    const headers = useMemo(
        () => [
            { label: 'Fecha' },
            { label: 'Tipo de Comisión' },
            { label: 'Usuario' },
            { label: 'Monto' }
        ],
        []
    );

    const rows = useMemo(
        () =>
            commissions.map(item => {
                return {
                    view: [
                        {
                            value: moment(
                                moment.utc(item.creado).toDate()
                            ).format('DD/MM/YY - h:mmA')
                        },
                        { value: CommissionsTypes[item.tipo] },
                        {
                            value: item.usuario ? (
                                <Link
                                    className={classes.link}
                                    to={`/usuarios/detalle/${item.usuario.id}`}>
                                    {item.usuario.nombre}
                                </Link>
                            ) : (
                                'No posee'
                            )
                        },
                        { value: `$${item.monto}` }
                    ],
                    data: {
                        id: item.id
                    }
                };
            }),
        [commissions]
    );

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Comisiones'
                    }
                ]}
            />

            <Box>
                {initialLoading ? (
                    <Box display="flex" justifyContent="center">
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <>
                        <AnTableToolbar
                            dateFromValue={dateFrom}
                            dateToValue={dateTo}
                            handleDateFromChange={handleDateFromChange}
                            handleDateToChange={handleDateToChange}
                        />
                        <ErrorBox
                            onRetry={() => {
                                getCommissions(p, r, f, t);
                            }}
                        />
                    </>
                ) : (
                    <Box width="100%">
                        <div className={classes.content}>
                            {totals === 0 && !loading && (
                                <AnTableToolbar
                                    dateFromValue={dateFrom}
                                    dateToValue={dateTo}
                                    handleDateFromChange={handleDateFromChange}
                                    handleDateToChange={handleDateToChange}
                                />
                            )}
                            <AnTable
                                toolbar={
                                    <AnTableToolbar
                                        dateFromValue={dateFrom}
                                        dateToValue={dateTo}
                                        handleDateFromChange={
                                            handleDateFromChange
                                        }
                                        handleDateToChange={handleDateToChange}
                                        onClickDownloadExcel={
                                            onClickDownloadExcel
                                        }
                                        report={report}
                                    />
                                }
                                emptyIcon={
                                    <CategoryIcon
                                        className={classes.emptyIcon}
                                    />
                                }
                                emptyMessage="Aún no hay ninguna comision"
                                headers={headers}
                                rows={rows}
                                loading={loading}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
        </div>
    );
};

const mapStateToProps = state => ({
    initialLoading: state.commissions.initialLoading,
    loading: state.commissions.loading,
    error: state.commissions.error,
    commissions: state.commissions.data,
    page: state.commissions.page,
    pageCount: state.commissions.pageCount,
    totals: state.commissions.totals,

    report: state.commissions.commission.actions.report,
    dateFrom: state.commissions.dateFrom,
    dateTo: state.commissions.dateTo
});

const mapDispatchToProps = dispatch => ({
    getCommissions: (page, pageCount, from, to) =>
        dispatch(CommissionsActions.getCommissions(page, pageCount, from, to)),
    getCommissionReport: (from, to) =>
        dispatch(CommissionsActions.getCommissionReport(from, to)),
    setDateFrom: date => dispatch(CommissionsActions.setDateFrom(date)),
    setDateTo: date => dispatch(CommissionsActions.setDateTo(date)),
    reset: type => dispatch(CommissionsActions.reset(type))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(
    withQueryParams(
        {
            p: withDefault(NumberParam, 0), // The current page
            r: withDefault(NumberParam, 10), // The current page count
            f: withDefault(StringParam, ''), //  The current date from
            t: withDefault(StringParam, '') // The current date to
            //q: withDefault(StringParam, ""), // The current search criteria
        },
        CommissionsList
    )
);
