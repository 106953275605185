import axios from 'axios';
import { UserRestClient } from 'http/resources';
import { IdentityRestClient } from 'http/resources';
import { ErrorManager } from 'services';

function getUsers(page, pageCount, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USERS_FETCH_USERS_START',
                payload: {
                    isActiveSearch: criteria
                        ? typeof criteria.name !== 'undefined'
                        : false
                }
            });

            const response = await new UserRestClient(true).getUsers(
                page,
                pageCount,
                criteria
            );

            dispatch({
                type: 'USERS_FETCH_USERS_SUCCESS',
                payload: {
                    page: page ? page : 0,
                    pageCount: pageCount ? pageCount : 10,
                    users: response.data.items,
                    totals: response.data.totalItems
                }
            });
        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'USERS_FETCH_USERS_FAIL'
                });

                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description:
                            'Ha ocurrido un error al intentar obtener los usuarios'
                    }
                });
            }
        }
    };
}

function impersonateUser(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USERS_IMPERSONATE_USER_START',
                payload: {
                    id
                }
            });

            const response = await new IdentityRestClient(
                true
            ).getImpersonateToken(id);

            dispatch({
                type: 'USERS_IMPERSONATE_USER_SUCCESS',
                payload: {
                    token: response.data.value
                }
            });
        } catch (e) {
            dispatch({
                type: 'USERS_IMPERSONATE_USER_FAIL'
            });

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description:
                        'Ha ocurrido un error al intentar impersonar el usuario'
                }
            });
        } finally {
            dispatch({
                type: 'USERS_IMPERSONATE_USER_RESET'
            });
        }
    };
}

function getUser(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USERS_FETCH_USER_START'
            });

            const response = await new UserRestClient(true).getUser(id);

            dispatch({
                type: 'USERS_FETCH_USER_SUCCESS',
                payload: {
                    user: response.data
                }
            });
        } catch (e) {
            dispatch({
                type: 'USERS_FETCH_USER_FAIL'
            });

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description:
                        'Ha ocurrido un error al intentar obtener los detalles del usuario'
                }
            });
        }
    };
}
function sendAlert(type, message) {
    return {
        type,
        payload: {
            title: message.title,
            description: message.description
        }
    };
}

function reset(type) {
    return {
        type
    };
}

function setUserData(payload) {
    return {
        type: 'USERS_USER_SET_USER_DATA',
        payload
    };
}

function putUser(id, data) {
    return async dispatch => {
        try {
            dispatch({
                type: 'USERS_PUT_USER_START'
            });

            const response = await new UserRestClient(true).putUser(id, data);

            dispatch({
                type: 'USERS_PUT_USER_SUCCESS',
                payload: {
                    user: response.data
                }
            });
        } catch (e) {
            dispatch({
                type: 'USERS_PUT_USER_FAIL'
            });

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description:
                        'Ha ocurrido un error al intentar obtener los detalles del usuario'
                }
            });
        }
    };
}

export const UsersActions = {
    getUsers,
    impersonateUser,
    sendAlert,
    getUser,
    reset,
    putUser,
    setUserData
};
