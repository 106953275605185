import React, { useEffect } from 'react';
import {
	TextField,
	Grid,
	Typography
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { UserRestClient } from 'http/resources';

export default function SearchAsyncAutocompleteInput(props) {

	const {
		getOptionSelected,
		placeholder = "",
		shrink = false,
		variant = "standard",
		autocompleteStyles = {},
		label = null
	} = props

	const [value, setValue] = React.useState(null);
	const [inputValue, setInputValue] = React.useState('');
	const [options, setOptions] = React.useState([]);
	const [loading, setLoading] = React.useState(false);

	useEffect(() => {
		let active = true;

		(async () => {
			if (inputValue.length > 2) {
				try {
					setLoading(true)
					const response = await new UserRestClient(true).getUsers(0, 24, { name: inputValue })
					const data = response.data.items

					if (active) {
						let newOptions = [];

						if (value) {
							newOptions = [value];
						}

						if (response.data.items) {
							newOptions = [...newOptions, ...data].filter((option, index, self) =>
								index === self.findIndex((t) => (
									t.place === option.place && t.id === option.id
								))
							);
						}

						setOptions(newOptions);
						setLoading(false)
					}
				} catch (error) {
					setLoading(false)
				}
			}
		})();

		return () => {
			active = false;
		};
	}, [value, inputValue]);

	useEffect(() => {
		getOptionSelected(value)
	}, [value]);

	return (
		<Autocomplete
			id="asynchronous-search"
			getOptionLabel={(option) => (option.nombre + ' ' + option.apellido)}
			filterOptions={(x) => x}
			options={options}
			autoComplete
			loading={loading}
			includeInputInList
			value={value}
			onChange={(event, newValue) => {
				setOptions(newValue ? [newValue, ...options] : options);
				setValue(newValue);
			}}
			onInputChange={(event, newInputValue, reason) => {
				setInputValue(newInputValue);
			}}
			getOptionSelected={(option, value) => option.email === value.email}
			loadingText="Buscando usuario.."
			noOptionsText="No encontramos el usuario"
			style={autocompleteStyles}
			renderInput={(params) => (
				<TextField
					{...params}
					label={label}
					placeholder={placeholder}
					InputLabelProps={{
						shrink,
					}}
					variant={variant}
					InputProps={{
						...params.InputProps,
						endAdornment: (
							<React.Fragment>
								{params.InputProps.endAdornment}
							</React.Fragment>
						),
					}}
				/>
			)}
			renderOption={(option) => {
				return (
					<Grid container alignItems="center">
						<Grid item xs>
							<Typography variant="body1" >
								{`${option.nombre} ${option.apellido}`}
							</Typography>
							<Typography variant="body1" color="textSecondary">
								{`(${option.email})`}
							</Typography>
						</Grid>
					</Grid>
				)
			}}
		/>
	);
}