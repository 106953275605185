import firebase from 'firebase/app';
import { IdentityRestClient } from 'http/resources'
import { JWT, ErrorManager  }from 'services';


function login(email, password) {
    return async dispatch => {
        try {
            dispatch({
                type: 'SIGNIN_START',
            })
            
            const UserCredential = await firebase.auth().signInWithEmailAndPassword(email, password)
            const token = await UserCredential.user.getIdToken()
            const response = await new IdentityRestClient().getToken(token)

            // We store the access token to keep the session after
            // user closes the browser window
            localStorage.setItem('accessToken', response.data.value)

            dispatch({
                type: 'SIGNIN_SUCCESS'
            })

            dispatch({
                type: 'CONTEXT_SET',
                payload: {
                    user: JWT.parseToken(response.data.value)
                }
            })
        } catch (error) {
            dispatch({
                type: 'SIGNIN_ERROR',
                payload: {
                    error: ErrorManager.toMessage(error)
                }
            })
        }
    }
}

export const AuthActions = {
    login,
}