
import axios from 'axios'
import { ClientRestClient } from 'http/resources'

function getClients(page, pageCount, criteria) {
	return async dispatch => {
		try {
			dispatch({
				type: 'CLIENTS_FETCH_CLIENTS_START',
				payload: {
					isActiveSearch: typeof criteria !== 'undefined',
				}
			})

			const response = await new ClientRestClient(true).getClients(page, pageCount, criteria)

			dispatch({
				type: 'CLIENTS_FETCH_CLIENTS_SUCCESS',
				payload: {
					page: page ? page : 0,
					pageCount: pageCount ? pageCount : 10,
					clients: response.data.items,
					totals: response.data.totalItems,
				}
			})

		} catch (e) {
			if (e instanceof axios.Cancel) {
				// Nothing to do, just canceled request by next request
			} else {
				dispatch({
					type: 'CLIENTS_FETCH_CLIENTS_FAIL'
				})

				dispatch({
					type: 'ERROR_SET',
					payload: {
						title: 'Error en la operación!',
						description: 'Ha ocurrido un error al intentar obtener los clientes'
					}
				})
			}
		}
	}
}

function reset(type) {
	return {
		type,
	}
}

export const ClientsActions = {
	getClients,
	reset,
}