import axios from 'axios'
import { LocalityRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getLocalities(page, pageCount, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: 'LOCALITIES_FETCH_LOCALITIES_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })

            const response = await new LocalityRestClient(true).getLocalities(page, pageCount, criteria)

            dispatch({
                type: 'LOCALITIES_FETCH_LOCALITIES_SUCCESS',
                payload: {
                    page,
                    pageCount,
                    localities: response.data.items,
                    totals: response.data.totalItems,
                }
            })

        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'LOCALITIES_FETCH_LOCALITIES_FAIL'
                })

                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener las localidades'
                    }
                })
            }
        }
    }
}

function getLocality(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'LOCALITIES_FETCH_LOCALITY_START'
            })

            const response = await new LocalityRestClient(true).getLocality(id)

            dispatch({
                type: 'LOCALITIES_FETCH_LOCALITY_SUCCESS',
                payload: {
                    locality: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'LOCALITIES_FETCH_LOCALITY_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener la localidad'
                }
            })
        }
    }
}

function saveLocality(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'LOCALITIES_SAVE_LOCALITY_START',
                payload: {
                    id,
                    patch,
                }
            })

            if (id && !patch) {
                await new LocalityRestClient(true).updateLocality(id, data)
            } else if (id && patch) {
                await new LocalityRestClient(true).patchLocality(id, data)
            } else {
                await new LocalityRestClient(true).createLocality(data)
            }

            dispatch({
                type: 'LOCALITIES_SAVE_LOCALITY_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: id
                        ? 'La localidad se ha actualizado correctamente'
                        : 'La localidad se ha creado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'LOCALITIES_SAVE_LOCALITY_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'LOCALITIES_SAVE_LOCALITY_RESET'
            })
        }
    }
}

function updateFavorite(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'LOCALITIES_UPDATE_FAVORITE_START',
                payload: {
                    id,
                    patch,
                }
            })
            await new LocalityRestClient(true).patchLocality(id, data)

            dispatch({
                type: 'LOCALITIES_UPDATE_FAVORITE_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch(setFormPropertyValue('favorite', data.favorito))

            if (id) {
                dispatch({
                    type: 'SUCCESS_SET',
                    payload: {
                        title: 'Operación Exitosa!',
                        description: 'La localidad se actualizo correctamente'
                    }
                })
            }

        } catch (e) {
            dispatch({
                type: 'LOCALITIES_UPDATE_FAVORITE_FAIL'
            })

            dispatch(setFormPropertyValue('favorite', !data.favorito))

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'LOCALITIES_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}

function reset(type) {
    return {
        type,
    }
}


export const LocalitiesActions = {
    getLocalities,
    getLocality,
    saveLocality,
    setFormPropertyValue,
    updateFavorite,
    reset,
}