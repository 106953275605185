import React, { useCallback, useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
	Box,
	Grid,
	TextField,
	Button,
	CircularProgress,
	Card,
	CardHeader,
	CardContent,
	CardActions,
	Divider,
} from '@material-ui/core'
import { Breadcrumb, SearchAsyncAutocompleteInput } from 'components'
import { connect } from 'react-redux'
import { MovementsActions } from 'redux/actions';
import { AnDropdown } from 'components/an'
import { Transition } from 'react-transition-group';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3),
		},
	},
	header: {
		background: '#f3f3f3b3',
		padding: '10px 16px',
	},
	content: {
		marginTop: theme.spacing(2)
	},
	selectRoot: {
		height: '28px',
		lineHeight: '28px',
		fontWeight: 500,
	},
	inputRoot: {
		'& .MuiInput-input': {
			height: '28px',
			lineHeight: '28px',
			fontWeight: 500,
		},

	},
	switchLoader: {
		margin: 15
	}
}));

const MovementsForm = props => {
	const {
		saveMovement,
		setFormPropertyValue,
		movement,
	} = props

	const theme = useTheme();
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	const userFound = location.state && location.state.userFound

	const transitionStyles = {
		entered: { transform: 'translateY(0) scale(1)' },
	};

	useEffect(() => {

		if (userFound) {
			setFormPropertyValue('userId', userFound.id)
		}
	}, [userFound])

	useEffect(() => {

		if (movement.actions.save.success ||
			!userFound) {
			history.push('/movimientos')
		}
	}, [movement.actions.save.success, history, userFound])


	return (
		<div className={classes.root}>
			<Breadcrumb
				path={[
					{
						name: 'Movimientos'
					},
					{
						name: 'Nueva'
					}
				]}
			/>

			{(movement.actions.save.loading ? (
				<Box
					display="flex"
					justifyContent="center"
					alignItems="center"
					marginTop="30px"
				>
					<CircularProgress />
				</Box>
			) : (
					<Transition in={true} appear={true} timeout={0}>
						{state => (
							<Box paddingBottom={3}>
								<Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
									<CardHeader
										className={classes.header}
										title="Movimiento"
										subheader={"Crear nuevo movimiento"}
									/>

									<Divider />

									<CardContent className={classes.content}>
										<Grid container spacing={3}>
											<Grid item xs={12} sm={6}>
												<AnDropdown
													label="Tipo de movimiento"
													value={movement.data.movementType}
													onChange={(e) => {
														setFormPropertyValue('movementType', e.target.value)
													}}
													placeholder="Ej: $4312,30"
													inputProps={{
														name: 'Tipo de movimiento',
														id: 'movementType',
													}}
													options={[
														{
															id: 3,
															name: 'Pago',
														},
														{
															id: 8,
															name: 'Cobro',
														}
													]}
												/>
											</Grid>

											<Grid item xs={12} sm={6}>

												<TextField
													classes={{
														root: classes.inputRoot
													}}
													InputLabelProps={{
														shrink: true,
													}}
													startAdornment
													type="number"
													margin="normal"
													placeholder="Ej: $4312,30"
													fullWidth
													label="Monto ($)"
													value={movement.data.mount}
													onChange={(e) => {
														setFormPropertyValue('mount', e.target.value)
													}}
												/>

											</Grid>

											<Grid item xs={12} sm={6}>

												<TextField
													InputLabelProps={{
														shrink: true,
													}}
													inputProps={{
														readOnly: true
													}}
													classes={{
														root: classes.inputRoot,
													}}
													margin="normal"
													fullWidth
													label="Usuario"
													value={`${userFound.nombre} ${userFound.apellido}`}
													onChange={(e) => {
														// Nothing to do ...
													}}
												/>
											</Grid>

											<Grid item xs={12} sm={12}>
												<TextField
													multiline
													rows={4}
													InputLabelProps={{
														shrink: true,
													}}
													margin="normal"
													fullWidth
													variant="outlined"
													label="Agregar detalles"
													value={movement.data.details}
													onChange={(e) => {
														setFormPropertyValue('details', e.target.value)
													}}
												/>
											</Grid>

										</Grid>
									</CardContent>

									<Divider />

									<CardActions
										style={{
											justifyContent: 'flex-end'
										}}
									>
										<Button
											color="primary"
											onClick={() => {
												history.goBack()
											}}
										>
											VOLVER
                                    </Button>

										<Button
											disabled={!movement.data.movementType || !movement.data.mount || !movement.data.userId || !movement.data.details}
											color="primary"
											variant="contained"
											onClick={() => {
												saveMovement({
													tipo: movement.data.movementType,
													usuarioId: movement.data.userId,
													monto: parseFloat(movement.data.mount),
													detalle: movement.data.details
												})
											}}
										>
											GUARDAR
                                    </Button>
									</CardActions>
								</Card>
							</Box>
						)}
					</Transition>
				))
			}
		</div >
	);
};

const mapStateToProps = state => ({
	movement: state.movements.movement,
})

const mapDispatchToProps = dispatch => ({
	setFormPropertyValue: (property, value) => dispatch(MovementsActions.setFormPropertyValue(property, value)),
	saveMovement: (data) => dispatch(MovementsActions.saveMovement(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(MovementsForm);
