import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { PostulationsActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import GroupAddIcon from '@material-ui/icons/GroupAdd';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    content: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    emptyIcon: {
        fontSize: '200px',
        color: '#ccc'
    }
}));

const PostulationsList = React.memo(props => {
    const {
        getPostulations,
        postulations,
        postulation,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        savePostulation,
        query,
        setQuery,
        reset
    } = props

    const {
        p,
        r,
        q,
        s,
    } = query;

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('POSTULATIONS_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getPostulations(p, r, q, s)
        } else if (q.length === 0) {
            getPostulations(p, r, null, s)
        }
    }, [p, r, q, s, getPostulations])

    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onChangeSelect = useCallback((e) => {
        setQuery({ s: e.target.value, p: undefined })
    }, [setQuery])

    const onClickView = useCallback((id) => {
        history.push(`/postulaciones/detalle/${id}`);
    }, [history])

    const onClickAccept = useCallback((id) => {
        savePostulation(id, { estado: 2 }, true)
    }, [savePostulation])

    const onClickReject = useCallback((id) => {
        savePostulation(id, { estado: 3 }, true)
    }, [savePostulation])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Nombre' },
        { label: 'Email' },
        { label: 'Estado' },
        { label: 'Tipo' }
    ]), [])

    const rows = useMemo(() => (
        postulations.map(item => {
            return {
                view: [
                    { value: `${item.nombre} ${item.apellido}` },
                    { value: item.email },
                    {
                        value: item.estado === 1
                            ? 'Pendiente' :
                            item.estado === 2
                                ? 'Aprobada'
                                : 'Rechazada',
                        color: item.estado === 1
                            ? 'inherit' :
                            item.estado === 2
                                ? '#659030'
                                : '#d15c5c',
                    },
                    { value: item.tipo === 1 ? 'Promotor' : 'Proveedor' }
                ],
                data: {
                    id: item.id,
                    status: item.estado
                }
            }
        })
    ), [postulations])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Postulaciones'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getPostulations(p, r, q, s)
                            } else if (q.length === 0) {
                                getPostulations(p, r, null, s)
                            }
                        }}
                    />
                ) : (
                            <Box width="100%">
                                <div className={classes.content}>
                                    <AnTable
                                        toolbar={(
                                            <AnTableToolbar
                                                textSearchPlaceholder="Buscar postulación ...."
                                                textSearchValue={q}
                                                onSearch={onSearch}
                                                onChangeSelect={onChangeSelect}
                                                selectOptionValue={s}
                                                selectOptionsArray={[
                                                    {
                                                        id: 0,
                                                        name: 'Todas',
                                                        inactive: false
                                                    },
                                                    {
                                                        id: 1,
                                                        name: 'Pendientes',
                                                        inactive: false
                                                    },
                                                    {
                                                        id: 2,
                                                        name: 'Aprobadas',
                                                        inactive: false
                                                    },
                                                ]}
                                            />
                                        )}
                                        isActiveSearch={isActiveSearch}
                                        emptyIcon={(
                                            <GroupAddIcon className={classes.emptyIcon} />
                                        )}
                                        emptyMessage="Aún no hay postulaciones"
                                        actionsRowWidth="154px"
                                        headers={headers}
                                        rows={rows}
                                        loading={loading}
                                        save={postulation.actions.save}
                                        totals={totals}
                                        page={page}
                                        pageCount={pageCount}
                                        onClickView={onClickView}
                                        onClickAccept={onClickAccept}
                                        onClickReject={onClickReject}
                                        onPageChange={onPageChange}
                                        onPageCountChange={onPageCountChange}
                                    />
                                </div>
                            </Box>
                        )}
            </Box>
        </div>
    );
});

const mapStateToProps = state => ({
    initialLoading: state.postulations.initialLoading,
    loading: state.postulations.loading,
    error: state.postulations.error,
    postulations: state.postulations.data,
    page: state.postulations.page,
    pageCount: state.postulations.pageCount,
    totals: state.postulations.totals,
    isActiveSearch: state.postulations.isActiveSearch,
    postulation: state.postulations.postulation,
})

const mapDispatchToProps = dispatch => ({
    getPostulations: (page, pageCount, criteria, status) => dispatch(PostulationsActions.getPostulations(page, pageCount, criteria, status)),
    savePostulation: (id, data, patch) => dispatch(PostulationsActions.savePostulation(id, data, patch)),
    reset: (type) => dispatch(PostulationsActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, ""),// The current search criteria
        s: withDefault(NumberParam, 0)// The current status selected
    }, PostulationsList)
);
