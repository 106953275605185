import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { UsersActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import CategoryIcon from '@material-ui/icons/Category';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory, Link } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    content: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    emptyIcon: {
        fontSize: '200px',
        color: '#ccc'
    },
    link: {
        color: 'rgb(3, 131, 235)',
        textDecoration: 'underline'
    }
}));

const UsersList = props => {
    const {
        getUsers,
        users,
        user,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        impersonateUser,
        query,
        setQuery,
        sendAlert,
        reset
    } = props

    const {
        p,
        r,
        q,
    } = query;

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('USERS_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getUsers(p, r, { name: q })
        } else if (q.length === 0) {
            getUsers(p, r)
        }
    }, [p, r, q, getUsers])

    useEffect(() => {
        if (user.actions.impersonate.success && user.actions.impersonate.token) {
            window.open(`${process.env.REACT_APP_PUBLIC_WEBSITE_URL}/search?impersonateToken=${user.actions.impersonate.token}`, 'target="_blank"')

            reset('USERS_USER_RESET')
        }
    }, [user.actions.impersonate.success, user.actions.impersonate.token, reset])

    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickImpersonate = useCallback((id) => {
        impersonateUser(id)
    }, [impersonateUser])

    const onClickView = useCallback((id) => {
        history.push(`/usuarios/detalle/${id}`);
    }, [history])

    const onClickShareLink = useCallback((nic) => {
        let shareUrl = `${process.env.REACT_APP_PUBLIC_WEBSITE_URL}/search?promoter-nic=${nic || ''}`
        navigator.clipboard.writeText(shareUrl)
        sendAlert(
            'SUCCESS_SET',
            {
                title: 'Operación Exitosa!',
                description: 'El link se copió en el portapapeles'
            })
    })

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Nombre' },
        { label: 'Padre' },
        { label: 'Email' }
    ]), [])

    const rows = useMemo(() => (
        users.map(item => {
            return {
                view: [
                    { value: `${item.nombre} ${item.apellido}` },
                    {
                        value: item.parent ?
                            <Link
                                className={classes.link}
                                to={`/usuarios/detalle/${item.parent.id}`}
                            >
                                {item.parent.nombre}
                            </Link>
                            : 'No posee'
                    },
                    { value: item.email },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                    nic: item.codigo,
                }
            }
        })
    ), [users])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Usuarios'
                    }
                ]}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getUsers(p, r, { name: q })
                            } else if (q.length === 0) {
                                getUsers(p, r)
                            }
                        }}
                    />
                ) : (
                            <Box width="100%">
                                <div className={classes.content}>
                                    <AnTable
                                        toolbar={(
                                            <AnTableToolbar
                                                textSearchPlaceholder="Buscar usuario ...."
                                                textSearchValue={q}
                                                onSearch={onSearch}
                                            />
                                        )}
                                        isActiveSearch={isActiveSearch}
                                        emptyIcon={(
                                            <CategoryIcon className={classes.emptyIcon} />
                                        )}
                                        onClickImpersonate={onClickImpersonate}
                                        emptyMessage="Aún no se ha creado ningún usuario"
                                        actionsRowWidth="154px"
                                        headers={headers}
                                        rows={rows}
                                        loading={loading}
                                        impersonate={user.actions.impersonate}
                                        totals={totals}
                                        page={page}
                                        pageCount={pageCount}
                                        onPageChange={onPageChange}
                                        onPageCountChange={onPageCountChange}
                                        onClickView={onClickView}
                                        onClickShareLink={onClickShareLink}
                                    />
                                </div>
                            </Box>
                        )}
            </Box>
        </div>
    );
};

const mapStateToProps = state => ({
    initialLoading: state.users.initialLoading,
    loading: state.users.loading,
    error: state.users.error,
    users: state.users.data,
    page: state.users.page,
    pageCount: state.users.pageCount,
    totals: state.users.totals,
    isActiveSearch: state.users.isActiveSearch,
    user: state.users.user,
})

const mapDispatchToProps = dispatch => ({
    getUsers: (page, pageCount, criteria) => dispatch(UsersActions.getUsers(page, pageCount, criteria)),
    impersonateUser: (id) => dispatch(UsersActions.impersonateUser(id)),
    sendAlert: (type, message) => dispatch(UsersActions.sendAlert(type, message)),
    reset: (type) => dispatch(UsersActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, ""), // The current search criteria
    }, UsersList)
);
