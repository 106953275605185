/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class CountryRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getCountries
     */

    getCountries(page, pageCount, criteria) {
        const url = `/api/v1/public/paises`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria
            }
        })
    }
    
    /*
     *  @getCountry
     */

    getCountry(id) {
        const url = `/api/v1/public/paises/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchCountry
     */

    patchCountry(id, data) {
        const url = `/api/v1/secured/paises/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *  @updateCountry
     */

    updateCountry(id, data) {
        const url = `/api/v1/secured/paises/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createCountry
     */

    createCountry(data) {
        const url = `/api/v1/secured/paises`;

        return this.instance.post(url, data)
    }
}