/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class CategoryRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getCategories
     */

    getCategories(page, pageCount, criteria) {
        const url = `/api/v1/public/categorias`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria
            }
        })
    }

    /*
     *  @getCategory
     */

    getCategory(id) {
        const url = `/api/v1/public/categorias/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchCategory
     */

    patchCategory(id, data) {
        const url = `/api/v1/secured/categorias/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *  @updateCategory
     */

    updateCategory(id, data) {
        const url = `/api/v1/secured/categorias/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createCategory
     */

    createCategory(data) {
        const url = `/api/v1/secured/categorias`;

        return this.instance.post(url, data)
    }
}