/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient';
import axios from 'axios';

const CancelToken = axios.CancelToken;
let cancel;

export default class UserRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext);
    }

    /*
     *   @getUsers
     */

    getUsers(page, pageCount, criteria) {
        const url = `/api/v1/secured/usuarios`;

        if (typeof cancel === 'function') cancel();

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                email: criteria ? criteria.email : null,
                nombre: criteria ? criteria.name : null
            }
        });
    }

    /*
     *   @getUser
     */

    getUser(id) {
        const url = `/api/v1/secured/usuarios/${id}`;

        return this.instance.get(url);
    }

    /*
     *   @putUser
     */

    putUser(id, data) {
        const url = `/api/v1/secured/usuarios/${id}`;

        return this.instance.put(url, data);
    }
}
