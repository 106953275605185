function setError(error) {
    return {
        type: 'ERROR_SET',
        payload: {
            title: error.title,
            description: error.description
        }
    }
}

function setSuccess(success) {
    return {
        type: 'SUCCESS_SET',
        payload: {
            title: success.title,
            description: success.description
        }
    }
}

function clearSuccess() {
    return {
        type: 'CLEAR_SUCCESS',
    }
}

function clearError() {
    return {
        type: 'CLEAR_ERROR'
    }
}


export const DashboardActions = {
    setError,
    setSuccess,
    clearSuccess,
    clearError,
}