
import axios from 'axios'
import { BookingRestClient } from 'http/resources'
import { ErrorManager } from 'services'
import moment from 'moment'
import { saveAs } from 'file-saver'

function getBookings(params, criteria) {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKINGS_START',
                payload: {
                    isActiveSearch: criteria ? typeof criteria.name !== 'undefined' : false,
                }
            })
            const { dateFrom, dateTo } = getState().bookings

            const response = await new BookingRestClient(true).getBookings(
                {
                    ...params,
                    experienceDateFrom: dateFrom,
                    experienceDateTo: dateTo
                },
                criteria
            )
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKINGS_SUCCESS',
                payload: {
                    page: params.page ? params.page : 0,
                    pageCount: params.pageCount ? params.pageCount : 10,
                    bookings: response.data.items,
                    totals: response.data.totalItems,
                }
            })

        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'BOOKINGS_FETCH_BOOKINGS_FAIL'
                })

                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener las reservas'
                    }
                })
            }
        }
    }
}

function getBooking(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKING_START'
            })

            const response = await new BookingRestClient(true).getBooking(id)

            dispatch({
                type: 'BOOKINGS_FETCH_BOOKING_SUCCESS',
                payload: {
                    booking: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKING_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener la reserva'
                }
            })
        }
    }
}

function cancelBooking(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'BOOKINGS_CANCEL_BOOKING_START',
            })

            await new BookingRestClient(true).cancelBooking(id)

            dispatch({
                type: 'BOOKINGS_CANCEL_BOOKING_SUCCESS',
            })
        } catch (error) {
            dispatch({
                type: 'BOOKINGS_CANCEL_BOOKING_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar cancelar la reserva'
                }
            })
        } finally {
            dispatch({
                type: 'BOOKINGS_CANCEL_BOOKING_RESET'
            })
        }
    }
}


function getBookingReport() {
    return async (dispatch, getState) => {
        try {
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKINGS_REPORT_START'
            })
            const { dateFrom, dateTo } = getState().bookings
            let dates = {
                dateFrom,
                dateTo
            }
            const response = await new BookingRestClient(true).getBookingReport(dates)

            dispatch({
                type: 'BOOKINGS_FETCH_BOOKINGS_REPORT_SUCCESS',
            })

            let blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

            saveAs(blob, "reporte.xlsx") //save file on desktop
        } catch (e) {
            dispatch({
                type: 'BOOKINGS_FETCH_BOOKINGS_REPORT_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar descargar el reporte'
                }
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'BOOKINGS_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}


function setDateFrom(date) {
    return {
        type: 'BOOKINGS_SET_DATE_FROM',
        payload: {
            dateFrom: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
        }
    }
}

function setDateTo(date) {
    return {
        type: 'BOOKINGS_SET_DATE_TO',
        payload: {
            dateTo: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'),
        }
    }
}

function reset(type) {
    return {
        type,
    }
}

export const BookingsActions = {
    getBookings,
    getBooking,
    cancelBooking,
    setFormPropertyValue,
    reset,
    setDateFrom,
    setDateTo,
    getBookingReport,
}