const defaultState = {
    loading: false,
    error: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'SIGNIN_START':
            return Object.assign({}, state, {
                loading: true,
                error: null,
            })
        
        case 'SIGNIN_SUCCESS':
            return Object.assign({}, state, {
                loading: false,
            })

        case 'SIGNIN_ERROR':
            return Object.assign({}, state, {
                loading: false,
                error: action.payload.error,
            })
        default:
            return state;
    }
}
