const defaultState = {
    scheme: {
        loading: true,
        error: null,
        data: {
            gateway: 0,
            platform: 0,
            promoter: 0,
            promoterLevel1: 0,
            promoterLevel2: 0,
        },
        actions: {
            save: {
                loading: false,
                success: false,
                error: null,
            },
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'COMMISSIONS_SCHEMES_FETCH_SCHEME_START':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    loading: true,
                    error: null,
                })
            })

        case 'COMMISSIONS_SCHEMES_FETCH_SCHEME_SUCCESS':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    loading: false,
                    data: {
                        gateway: action.payload.scheme.comisionGatewayPagos,
                        platform: action.payload.scheme.comisionPlataforma ,
                        promoter: action.payload.scheme.comisionPromotor,
                        promoterLevel1: action.payload.scheme.comisionPromotorNivel0,
                        promoterLevel2: action.payload.scheme.comisionPromotorNivel1,
                    }
                })
            })

        case 'COMMISSIONS_SCHEMES_FETCH_SCHEME_FAIL':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    loading: false,
                    error: true,
                })
            })

        case 'COMMISSIONS_SCHEMES_SAVE_SCHEME_START':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    actions: Object.assign({}, state.scheme.actions, {
                        save: {
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'COMMISSIONS_SCHEMES_SAVE_SCHEME_SUCCESS':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    actions: Object.assign({}, state.scheme.actions, {
                        save: {
                            loading: false,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'COMMISSIONS_SCHEMES_SAVE_SCHEME_FAIL':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    actions: Object.assign({}, state.scheme.actions, {
                        save: {
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'COMMISSIONS_SCHEMES_SAVE_SCHEME_RESET':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    actions: Object.assign({}, state.scheme.actions, {
                        save: defaultState.scheme.actions.save,
                    })
                })
            })

        case 'COMMISSIONS_SCHEMES_FORM_PROPERTY_VALUE_SET':
            return Object.assign({}, state, {
                scheme: Object.assign({}, state.scheme, {
                    data: Object.assign({}, state.scheme.data, {
                        [action.payload.property]: action.payload.value
                    })
                })
            })

        case 'COMMISSIONS_SCHEMES_SCHEME_RESET':
            return Object.assign({}, state, {
                scheme: Object.assign({}, defaultState.scheme)
            })

        default:
            return state;
    }
}
