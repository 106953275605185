
import axios from 'axios'
import { ProvinceRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getProvinces(page, pageCount, countryId, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: 'PROVINCES_FETCH_PROVINCES_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })
            
            const response = await new ProvinceRestClient(true).getProvinces(page, pageCount, countryId, criteria)

            dispatch({
                type: 'PROVINCES_FETCH_PROVINCES_SUCCESS',
                payload: {
                    page: page ? page : 0,
                    pageCount: pageCount ? pageCount : 10,
                    provinces: response.data.items,
                    totals: response.data.totalItems,
                }
            })
            
        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'PROVINCES_FETCH_PROVINCES_FAIL'
                })
    
                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener las provincias'
                    }
                })
            }
        }   
    }
}

function getProvince(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'PROVINCES_FETCH_PROVINCE_START'
            })

            const response = await new ProvinceRestClient(true).getProvince(id)

            dispatch({
                type: 'PROVINCES_FETCH_PROVINCE_SUCCESS',
                payload: {
                    province: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'PROVINCES_FETCH_PROVINCE_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener la provincia'
                }
            })
        }
    }
}

function saveProvince(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'PROVINCES_SAVE_PROVINCE_START',
                payload: {
                    id,
                    patch,
                }
            })

            if (id && !patch) {
                await new ProvinceRestClient(true).updateProvince(id, data)
            } else if (id && patch) {
                await new ProvinceRestClient(true).patchProvince(id, data)
            } else {
                await new ProvinceRestClient(true).createProvince(data)
            }

            dispatch({
                type: 'PROVINCES_SAVE_PROVINCE_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: id
                        ? 'La provincia se ha actualizado correctamente'
                        : 'La provincia se ha creado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'PROVINCES_SAVE_PROVINCE_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'PROVINCES_SAVE_PROVINCE_RESET'
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'PROVINCES_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}

function reset(type) {
    return {
        type,
    }
}


export const ProvincesActions = {
    getProvinces,
    getProvince,
    saveProvince,
    setFormPropertyValue,
    reset,
}