const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    postulation: {
        loading: true,
        error: null,
        data: {
            id: null,
            name: null,
            surname: null,
            email: null,
            phohe: null,
            status: null,
            type: null,
            info: null,
            parent: {
                id: null,
                name: null,
            },
        },
        actions: {
            save: {
                id: null,
                loading: false,
                success: false,
                error: null,
            }
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'POSTULATIONS_FETCH_POSTULATIONS_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'POSTULATIONS_FETCH_POSTULATIONS_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.postulations),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'POSTULATIONS_FETCH_POSTULATIONS_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true,
            })

        case 'POSTULATIONS_FETCH_POSTULATION_START':
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    loading: true,
                })
            })

        case 'POSTULATIONS_FETCH_POSTULATION_SUCCESS':
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    loading: false,
                    data: {
                        id: action.payload.postulation.id,
                        name: action.payload.postulation.nombre,
                        surname: action.payload.postulation.apellido,
                        email: action.payload.postulation.email,
                        phone: action.payload.postulation.telefono,
                        status: action.payload.postulation.estado,
                        type: action.payload.postulation.tipo,
                        info: action.payload.postulation.informacionAdicional,
                        parent: {
                            id: action.payload.postulation.usuarioParent ? action.payload.postulation.usuarioParent.id : null,
                            name: action.payload.postulation.usuarioParent ? action.payload.postulation.usuarioParent.nombre : null,
                        },
                    }
                })
            })

        case 'POSTULATIONS_FETCH_POSTULATION_FAIL':
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    loading: false,
                    error: true,
                })
            })

        case 'POSTULATIONS_SAVE_POSTULATION_START': {
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    actions: Object.assign({}, state.postulation.actions, {
                        save: {
                            id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })
        }

        case 'POSTULATIONS_SAVE_POSTULATION_SUCCESS': {
            const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
            }) : null

            return Object.assign({}, state, {
                data: action.payload && action.payload.patch && action.payload.id ? [].concat(data) : state.data,
                postulation: Object.assign({}, state.postulation, {
                    actions: Object.assign({}, state.postulation.actions, {
                        save: {
                            id: null,
                            loading: true,
                            success: true,
                            error: null,
                        }
                    })
                })
            })
        }

        case 'POSTULATIONS_SAVE_POSTULATION_FAIL':
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    actions: Object.assign({}, state.postulation.actions, {
                        save: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'POSTULATIONS_SAVE_POSTULATION_RESET':
            return Object.assign({}, state, {
                postulation: Object.assign({}, state.postulation, {
                    actions: Object.assign({}, state.postulation.actions, {
                        save: defaultState.postulation.actions.save
                    })
                })
            })

        case 'POSTULATIONS_POSTULATION_RESET':
            return Object.assign({}, state, {
                postulation: Object.assign({}, defaultState.postulation)
            })

        case 'POSTULATIONS_RESET':
            return  Object.assign({}, state, defaultState)
        default:
            return state;
    }
}
