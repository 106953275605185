/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class ExperienceRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getExperiences
     */

    getExperiences(page, pageCount, criteria) {
        const url = `/api/v1/public/experiencias`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria,
            }
        })
    }

    /*
     *  @getExperience
     */

    getExperience(id) {
        const url = `/api/v1/public/experiencias/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchExperience
     */

    patchExperience(id, data) {
        const url = `/api/v1/secured/experiencias/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *  @updateExperience
     */

    updateExperience(id, data) {
        const url = `/api/v1/secured/experiencias/${id}`

        return this.instance.put(url, data)
    }
}