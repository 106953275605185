/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class TagsRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getTags
     */

    getTags(page, pageCount, criteria) {
        const url = `/api/v1/public/tags`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria
            }
        })
    }

    /*
     *  @getTag
     */

    getTag(id) {
        const url = `/api/v1/public/tags/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchTag
     */

    patchTag(id, data) {
        const url = `/api/v1/secured/tags/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *  @updateTag
     */

    updateTag(id, data) {
        const url = `/api/v1/secured/tags/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createTag
     */

    createTag(data) {
        const url = `/api/v1/secured/tags`;

        return this.instance.post(url, data)
    }
}