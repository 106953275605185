/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'

export default class CommissionsSchemeRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getCommissionsScheme
     */

    getCommissionsScheme() {
        const url = `/api/v1/secured/esquemascomisionales/default`;

        return this.instance.get(url)
    }


    /*
     *  @updateCommissionsScheme
     */

    updateCommissionsScheme(data) {
        const url = `/api/v1/secured/esquemascomisionales/default`

        return this.instance.put(url, data)
    }
}