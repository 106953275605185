import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import clsx from 'clsx';
import { Alert, AlertTitle } from '@material-ui/lab'
import { useMediaQuery } from '@material-ui/core'
import { makeStyles, useTheme } from '@material-ui/core/styles';
import {
    Paper,
    Box,
    Card,
    CardActions,
    CardContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TablePagination,
    Typography,
    Button,
    Link,
} from '@material-ui/core';

import { AnTableRow } from 'components/an'
import { Transition } from 'react-transition-group';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
    root: {},
    card: {
        [theme.breakpoints.down('sm')]: {
            background: 'transparent',
            boxShadow: 'none'
        },
    },
    content: {
        padding: 0
    },
    actions: {
        justifyContent: 'flex-end'
    },
    tableRoot: {
        whiteSpace: 'nowrap',
        tableLayout: 'fixed',
        borderCollapse: 'separate',
        borderSpacing: '3px',
    },
    tableHeadRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        },
    },
    tableRowRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
        },
    },
    aaa: {
        color: 'orange'
    }
}));

const TableA = React.memo(props => {
    const {
        toolbar,
        headers,
        loading,
        rows,
        totals,
        save,
        impersonate,
        remove,
        onClickEdit,
        onClickView,
        onClickShareLink,
        onClickToggleActivate,
        onClickAccept,
        onClickReject,
        onClickRemove,
        onClickImpersonate,
        onPageChange,
        onPageCountChange,
        pageCount,
        page,
        actionsRowWidth,
        emptyIcon,
        emptyMessage,
        emptyButtonTo,
        emptyButtonText,
        isActiveSearch,
        showToolbarOnError,
    } = props;

    const theme = useTheme()
    const classes = useStyles();

    const handlePageChange = useCallback((event, page) => {
        onPageChange(page)
    }, [onPageChange]);

    const handleRowsPerPageChange = useCallback((event) => {
        onPageCountChange(event.target.value)
    }, [onPageCountChange]);

    const isDesktop = useMediaQuery(theme.breakpoints.up('md'), {
        defaultMatches: true,
    })

    const transitionStyles = useMemo(() => ({
        entered: { transform: 'translateY(0) scale(1)' },
    }), []);

    const rowsPerPageOptions = useMemo(() => ([
        5, 10, 25
    ]), [])

    if (totals === 0 && !isActiveSearch && !loading) {
        return (
            <>
                { showToolbarOnError && toolbar}
                <Box
                    marginTop="20px"
                    textAlign="center"
                    padding="45px 20px"
                >

                    {emptyIcon}

                    <Typography>
                        {emptyMessage}
                    </Typography>

                    {emptyButtonTo && emptyButtonText ? (
                        <Box
                            marginTop="20px"
                        >
                            <Link
                                component={RouterLink}
                                to={emptyButtonTo}
                                variant="h6"
                            >
                                <Button
                                    color="primary"
                                    variant="contained"
                                >
                                    {emptyButtonText}
                                </Button>
                            </Link>
                        </Box>
                    ) : null}
                </Box>
            </>)
    }

    return (
        <Transition in={true} appear={true} timeout={0}>
            {state => (
                <Box>
                    {toolbar}

                    {totals === 0 && isActiveSearch ? (
                        <Paper elevation={1}>
                            <Alert severity="info">
                                <AlertTitle>0 registros encontrados</AlertTitle>
                                <Typography variant="subtitle2">No se han encontrado resultados para la búsqueda establecida</Typography>
                            </Alert>
                        </Paper>
                    ) : (
                            <Card
                                style={{
                                    opacity: loading ? 0.3 : 1,
                                    // marginBottom: 24,
                                    transform: 'translateY(15px) scale(0.99)',
                                    transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }),
                                    ...transitionStyles[state]
                                }}
                                className={clsx(
                                    { [classes.card]: true },
                                    { [classes.aaa]: true }
                                )}
                            >
                                <CardContent className={classes.content}>
                                    <PerfectScrollbar>
                                        <div className={classes.inner}>
                                            <Table className={classes.tableRoot}>
                                                <TableHead className={classes.tableHeadRoot}>
                                                    <TableRow>
                                                        {(headers || []).map((item, index) => (
                                                            <TableCell key={index.toString()} style={{ width: item.width || 'inherit' }}>{item.label}</TableCell>
                                                        ))}

                                                        <TableCell style={{ width: actionsRowWidth || '106px' }}></TableCell>
                                                    </TableRow>
                                                </TableHead>

                                                <TableBody>
                                                    {rows.map((row, index) => {
                                                        return (
                                                            <AnTableRow
                                                                key={index.toString()}
                                                                isDesktop={isDesktop}
                                                                headers={headers}
                                                                remove={remove}
                                                                save={save}
                                                                impersonate={impersonate}
                                                                row={row}
                                                                onClickEdit={onClickEdit}
                                                                onClickView={onClickView}
                                                                onClickShareLink={onClickShareLink}
                                                                onClickToggleActivate={onClickToggleActivate}
                                                                onClickAccept={onClickAccept}
                                                                onClickReject={onClickReject}
                                                                onClickRemove={onClickRemove}
                                                                onClickImpersonate={onClickImpersonate}
                                                            />
                                                        )
                                                    })}
                                                </TableBody>
                                            </Table>
                                        </div>
                                    </PerfectScrollbar>
                                </CardContent>

                                <CardActions className={classes.actions}>
                                    <TablePagination
                                        component="div"
                                        count={totals}
                                        onChangePage={handlePageChange}
                                        onChangeRowsPerPage={handleRowsPerPageChange}
                                        page={page}
                                        labelRowsPerPage="Filas"
                                        rowsPerPage={pageCount}
                                        rowsPerPageOptions={rowsPerPageOptions}
                                    />
                                </CardActions>
                            </Card>
                        )}
                </Box>
            )}
        </Transition>
    );
});

TableA.propTypes = {
    toolbar: PropTypes.element,
    headers: PropTypes.array.isRequired,
    rows: PropTypes.array.isRequired,
    loading: PropTypes.bool,
    page: PropTypes.number.isRequired,
    pageCount: PropTypes.number.isRequired,
    totals: PropTypes.number.isRequired,
    actionsRowWidth: PropTypes.string,
    emptyIcon: PropTypes.element,
    emptyMessage: PropTypes.string,
    emptyButtonTo: PropTypes.string,
    emptyButtonText: PropTypes.string,
    isActiveSearch: PropTypes.bool,
    showToolbarOnError: PropTypes.bool,
};

TableA.defaultProps = {
    rows: []
};

export default TableA
