
const MovementsTypes = {
	1: "Venta",
	2: "Pago por MP",
	3: "Pago",
	4: "Cobro destino",
	5: "Comisión Promoción",
	6: "Comisión Spiderweb",
	7: "Comisión MP",
	8: 'Cobro'
}

export default MovementsTypes
