import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Box, Typography } from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'

const useStyles = makeStyles(() => ({
    outer: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom:"1px solid #ddd",
        padding: "10px 0px",
        marginBottom: "20px"
    },
    inner: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }
}))

const Breadcrumb = React.memo(props => {
    const { path } = props
    const classes = useStyles()

    console.warn('render breadcrumb')
    
    return (
        <Box
            classes={{
                root: classes.outer
            }}
        >
            <HomeIcon />
            
            {path.map((item, index) => (
                <Box
                    key={index.toString()}
                    classes={{
                        root: classes.inner,
                    }}
                >
                    <Typography
                        style={{
                            marginRight: 5,
                            marginLeft: 5,
                        }}
                    >
                        /
                    </Typography>

                    <Typography>
                        { item.name }
                    </Typography>
                </Box>
            ))}
        </Box>
    )
})

export default Breadcrumb