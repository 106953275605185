import 'firebase/auth';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './assets/scss/index.scss';

// -------

import React, { useEffect } from 'react';
import { Router, Route } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { ThemeProvider } from '@material-ui/core/styles';
import validate from 'validate.js';
import firebase from 'firebase/app';
import Routes from 'routes';
import { ContextActions } from 'redux/actions'
import { connect } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import theme from './theme';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment'

firebase.initializeApp({
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
})

const browserHistory = createBrowserHistory();


validate.options = {
    fullMessages: false
}

const App = (props) => {
    useEffect(() => {
        // We wait to firebese context initializing to initalize
        // our auth context
        const unsuscribe = firebase.auth().onAuthStateChanged((user) => {
            // Once firebase initiaze
            // we can initialize too
            props.initializeContext()

            // We unsuscribe from the listener
            // we doesnt' need it anymore
            unsuscribe()
        })
    }, [])

    return (
        <ThemeProvider theme={theme} >
            <Router history={browserHistory}>
                <QueryParamProvider ReactRouterRoute={Route}>
                    <MuiPickersUtilsProvider utils={MomentUtils} libInstance={moment} locale="es">
                        <Routes />
                    </MuiPickersUtilsProvider>
                </QueryParamProvider>
            </Router>
        </ThemeProvider>
    )
}

const mapDispatchToProps = dispatch => ({
    initializeContext: () => dispatch(
        ContextActions.initializeContext()
    ),
})

export default connect(null, mapDispatchToProps)(App)
