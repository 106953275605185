/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class LocalityRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getLocalities
     */

    getLocalities(page, pageCount, criteria) {
        const url = `/api/v1/public/localidades`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria,
            }
        })
    }

    /*
     *   @getLocality
     */

    getLocality(id) {
        const url = `/api/v1/public/localidades/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchLocality
     */

    patchLocality(id, data) {
        const url = `/api/v1/secured/localidades/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *   @updateLocality
     */

    updateLocality(id, data) {
        const url = `/api/v1/secured/localidades/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createLocality
     */

    createLocality(data) {
        const url = `/api/v1/secured/localidades`;

        return this.instance.post(url, data)
    }
}