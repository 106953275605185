import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { LanguagesActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'
import TranslateIcon from '@material-ui/icons/Translate';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  content: {
    paddingBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
    
    [theme.breakpoints.up('md')]: {
        paddingBottom: theme.spacing(3),
    },
  },
  emptyIcon: {
    fontSize: '200px',
    color: '#ccc'
  }
}));

const LanguagesList = props => {
    const {
        getLanguages,
        languages,
        language,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        saveLanguage,
        query,
        setQuery,
        reset,
    } = props

    const {
        p,
        r,
        q,
    } = query;
    
    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('LANGUAGES_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getLanguages(p, r, q)
        } else if (q.length === 0) {
            getLanguages(p, r)
        }
    }, [p, r, q, getLanguages])
    
    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/idiomas/editar/${id}`);
    }, [history])

    const onClickToggleActivate = useCallback((id, inactive)=> {
        saveLanguage(id, { inactivo: !inactive }, true)
    }, [saveLanguage])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Idioma' },
    ]), [])

    const rows = useMemo(() => (
        languages.map(item => {
            return {
                view: [
                    { value: item.nombre },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [languages])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={useMemo(() => (
                    [
                        {
                            name: 'Idiomas'
                        }
                    ]
                ), [])}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getLanguages(p, r, q)
                            } else if (q.length === 0) {
                                getLanguages(p, r)
                            }
                        }}
                    />
                ) : (
                    <Box width="100%">
                        <div className={classes.content}>
                            <AnTable
                                toolbar={(
                                    <AnTableToolbar
                                        textSearchPlaceholder="Buscar idioma ...."
                                        textSearchValue={q}
                                        createButtonText="Crear Idioma"
                                        createButtonTo="/idiomas/nuevo"
                                        onSearch={onSearch}
                                    />
                                )}
                                isActiveSearch={isActiveSearch}
                                emptyIcon={(
                                    <TranslateIcon className={classes.emptyIcon} />
                                )}
                                emptyMessage="Aún no has creado ningún idioma"
                                emptyButtonTo="/idiomas/nuevo"
                                emptyButtonText="Crear Idioma"
                                headers={headers}
                                rows={rows}
                                loading={loading}
                                save={language.actions.save}
                                totals={totals}
                                page={page}
                                pageCount={pageCount}
                                onClickEdit={onClickEdit}
                                onClickToggleActivate={onClickToggleActivate}
                                onPageChange={onPageChange}
                                onPageCountChange={onPageCountChange}
                            />
                        </div>
                    </Box>
                )}
            </Box>
        </div>
    );
};

const mapStateToProps = state => ({
    initialLoading: state.languages.initialLoading,
    loading: state.languages.loading,
    error: state.languages.error,
    languages: state.languages.data,
    page: state.languages.page,
    pageCount: state.languages.pageCount,
    totals: state.languages.totals,
    isActiveSearch: state.languages.isActiveSearch,
    language: state.languages.language,
})

const mapDispatchToProps = dispatch => ({
    getLanguages: (page, pageCount, criteria) => dispatch(LanguagesActions.getLanguages(page, pageCount, criteria)),
    saveLanguage: (id, data, patch) => dispatch(LanguagesActions.saveLanguage(id, data, patch)),
    reset: (type) => dispatch(LanguagesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, "") // The current search criteria
    }, LanguagesList)
);
