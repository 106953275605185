import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import {
    Table,
    TableBody,
    TableRow,
    TableCell,
    IconButton,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    Box,
    Button,
    CircularProgress,
    Tooltip,
} from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit'
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import LinkIcon from '@material-ui/icons/Link';

const useStyles = makeStyles(theme => ({
    inactive: {
        backgroundColor: '#f7efef',
    },

    outerTableRowRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '15px',
            borderRadius: '4px',
            backgroundColor: '#fff',
            boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
        },
    },
    innerTableRowRoot: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
        },
    },
    outerTableCellRoot: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        [theme.breakpoints.down('sm')]: {
            padding: '10px 10px',
        },
    },
    actionsTableCellRoot: {
        textAlign: 'right',
        paddingRight: '5px',
        paddingLeft: '5px',

        [theme.breakpoints.down('sm')]: {
            padding: '5px 2px',

            '& .MuiIconButton-root:hover': {
                background: 'transparent'
            },
            '& .MuiIconButton-root:last-child': {
                paddingRight: '10px',
            }
        },
    },
    mobileThead: {
        borderBottom: 'none',
        padding: '10px 0px',
        marginRight: '20px',
        fontWeight: 500,
        width: '30%',
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    td: {
        borderBottom: 'none',
        padding: '10px 0px',
        flex: 1,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        textAlign: 'left',

        [theme.breakpoints.down('sm')]: {
            textAlign: 'right',
            width: '70%',
        },
    }
}));

const AnTableRow = React.memo(props => {
    const {
        headers,
        row,
        onClickView,
        onClickShareLink,
        onClickEdit,
        onClickRemove,
        onClickAccept,
        onClickReject,
        onClickToggleActivate,
        onClickImpersonate,
        remove,
        save,
        impersonate
    } = props

    const classes = useStyles();

    console.warn('render antablerow')

    const [open, setOpen] = useState(false)

    const handleClose = () => {
        setOpen(false)
    }

    const handleOpen = () => {
        setOpen(true)
    }

    const onRequestShareLink = () => {
        onClickShareLink(row.data.nic)
    }

    const onRequestView = () => {
        onClickView(row.data.id)
    }

    const onRequestEdit = () => {
        onClickEdit(row.data.id)
    }

    const onRequestAccept = () => {
        onClickAccept(row.data.id)
    }

    const onRequestReject = () => {
        onClickReject(row.data.id)
    }

    const onRequestToggleActivate = () => {
        onClickToggleActivate(row.data.id, row.data.inactive)
    }

    const onRequestImpersonate = () => {
        onClickImpersonate(row.data.id)
    }

    const onRequestRemove = () => {
        setOpen(false)
        onClickRemove(row.data.id)
    }

    return (
        <TableRow
            className={clsx(
                { [classes.outerTableRowRoot]: true },
                { [classes.inactive]: row.data.inactive }
            )}
            key={row.data.id}
        >
            {row.view.map((item, index) => (
                <TableCell
                    key={index.toString()}
                    className={clsx(
                        { [classes.outerTableCellRoot]: true }
                    )}
                >
                    <Table
                        style={{
                            width: '100%', whiteSpace: 'nowrap', tableLayout: 'fixed'
                        }}
                    >
                        <TableBody>
                            <TableRow
                                className={clsx(
                                    { [classes.innerTableRowRoot]: true }
                                )}
                            >
                                <TableCell
                                    className={clsx(
                                        { [classes.mobileThead]: true }
                                    )}
                                >
                                    {headers[index].label}
                                </TableCell>

                                <TableCell
                                    className={clsx(
                                        { [classes.td]: true }
                                    )}
                                    style={{
                                        color: item.color ? item.color : 'inherit'
                                    }}
                                >
                                    {item.value ? item.value : item.component}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableCell>
            ))}

            <TableCell
                className={clsx(
                    { [classes.actionsTableCellRoot]: true }
                )}
            >
                {(remove && remove.id === row.data.id) || (save && save.id === row.data.id) || (impersonate && impersonate.id === row.data.id) ? (
                    <Box
                        height={48}
                        display="flex"
                        justifyContent="flex-end"
                        alignItems="center"
                        padding="12px"
                    >
                        <CircularProgress color="primary" size={20} />
                    </Box>
                ) : (
                        <Box>
                            {typeof onClickView === 'function' && (
                                <Tooltip title="Ver">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestView}
                                    >
                                        <VisibilityIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {typeof onClickShareLink === 'function' && (
                                <Tooltip title="Copiar link">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestShareLink}
                                    >
                                        <LinkIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {typeof onClickImpersonate === 'function' && (
                                <Tooltip title="Impersonar">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestImpersonate}
                                    >
                                        <ExitToAppIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {typeof onClickEdit === 'function' && (
                                <Tooltip title="Editar">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestEdit}
                                    >
                                        <EditIcon />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {typeof onClickAccept === 'function' && row.data.status !== 2 && (
                                <Tooltip title="Aprobar">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestAccept}
                                    >
                                        <CheckIcon style={{ color: '#659030' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {typeof onClickReject === 'function' && row.data.status !== 2 && row.data.status !== 3 && (
                                <Tooltip title="Rechazar">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={onRequestReject}
                                    >
                                        <CloseIcon style={{ color: '#d15c5c' }} />
                                    </IconButton>
                                </Tooltip>
                            )}

                            {(row.data.inactive && typeof onClickToggleActivate === 'function' ? (
                                <Tooltip title="Activar">
                                    <IconButton
                                        onClick={onRequestToggleActivate}
                                    >
                                        <AddCircleOutlineIcon
                                            style={{ color: '#659030' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : typeof onClickToggleActivate === 'function' ? (
                                <Tooltip title="Desactivar">
                                    <IconButton
                                        onClick={onRequestToggleActivate}
                                    >
                                        <BlockIcon
                                            style={{ color: '#d15c5c' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            ) : null)}

                            {typeof onClickRemove === 'function' && (
                                <Tooltip title="Eliminar">
                                    <IconButton
                                        classes={{
                                            root: classes.iconButtonRoot
                                        }}
                                        color="inherit"
                                        onClick={handleOpen}
                                    >
                                        <DeleteIcon
                                            style={{ color: '#d15c5c' }}
                                        />
                                    </IconButton>
                                </Tooltip>
                            )}
                        </Box>
                    )}

                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-slide-title"
                    aria-describedby="alert-dialog-slide-description"
                >
                    <DialogTitle id="alert-dialog-slide-title">
                        TODO
                    </DialogTitle>

                    <DialogContent>
                        <DialogContentText id="alert-dialog-slide-description">
                            Tenga en cuenta que esta acción es permanente y no podrá deshacerse
                        </DialogContentText>
                    </DialogContent>

                    <DialogActions>
                        {remove && remove.id === row.data.id && (
                            <CircularProgress size={18} />
                        )}

                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                            <Box
                                marginLeft={2}
                            >
                                <Button
                                    disabled={remove && remove.loading}
                                    onClick={handleClose}
                                    color="primary"
                                >
                                    Cancelar
                                </Button>
                            </Box>

                            <Box
                                marginLeft={2}
                            >
                                <Button
                                    disabled={remove && remove.loading}
                                    onClick={onRequestRemove}
                                    variant="contained"
                                    style={{ backgroundColor: '#d15c5c', color: '#fff' }}
                                >
                                    Eliminar
                                </Button>
                            </Box>
                        </Box>
                    </DialogActions>
                </Dialog>
            </TableCell>
        </TableRow>
    )
})

AnTableRow.propTypes = {

}

export default AnTableRow