const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    language: {
        loading: true,
        error: null,
        data: {
            id: null,
            name: '',
        },
        actions: {
            save: {
                id: null,
                loading: false,
                success: false,
                error: null,
            },
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'LANGUAGES_FETCH_LANGUAGES_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'LANGUAGES_FETCH_LANGUAGES_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.languages),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'LANGUAGES_FETCH_LANGUAGES_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true,
            })

        case 'LANGUAGES_FETCH_LANGUAGE_START':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    loading: true,
                })
            })

        case 'LANGUAGES_FETCH_LANGUAGE_SUCCESS':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    loading: false,
                    data: {
                        id: action.payload.language.id,
                        name: action.payload.language.nombre,
                    }
                })
            })

        case 'LANGUAGES_FETCH_LANGUAGE_FAIL':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    loading: false,
                    error: true,
                })
            })

        case 'LANGUAGES_SAVE_LANGUAGE_START':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    actions: Object.assign({}, state.language.actions, {
                        save: {
                            id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                            loading: true,
                            success: false,
                            error: null,
                        }
                    })
                })
            })

        case 'LANGUAGES_SAVE_LANGUAGE_SUCCESS':
            const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
            }) : null

            return Object.assign({}, state, {
                data: action.payload && action.payload.patch && action.payload.id ? [].concat(data) : state.data,
                language: Object.assign({}, state.language, {
                    actions: Object.assign({}, state.language.actions, {
                        save: {
                            id: null,
                            loading: true,
                            success: true,
                            error: null,
                        }
                    })
                })
            })

        case 'LANGUAGES_SAVE_LANGUAGE_FAIL':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    actions: Object.assign({}, state.language.actions, {
                        save: {
                            id: null,
                            loading: false,
                            success: false,
                            error: true,
                        }
                    })
                })
            })

        case 'LANGUAGES_SAVE_LANGUAGE_RESET':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    actions: Object.assign({}, state.language.actions, {
                        save: defaultState.language.actions.save
                    })
                })
            })

        case 'LANGUAGES_FORM_PROPERTY_VALUE_SET':
            return Object.assign({}, state, {
                language: Object.assign({}, state.language, {
                    data: Object.assign({}, state.language.data, {
                        [action.payload.property]: action.payload.value
                    })
                })
            })

        case 'LANGUAGES_LANGUAGE_RESET':
            return Object.assign({}, state, {
                language: Object.assign({}, defaultState.language)
            })

        case 'LANGUAGES_RESET':
            return  Object.assign({}, state, defaultState)
        default:
            return state;
    }
}
