
import axios from 'axios'
import { ExperienceRestClient } from 'http/resources'
import { ErrorManager } from 'services'

function getExperiences(page, pageCount, criteria) {
    return async dispatch => {
        try {
            dispatch({
                type: 'EXPERIENCES_FETCH_EXPERIENCES_START',
                payload: {
                    isActiveSearch: typeof criteria !== 'undefined',
                }
            })
            
            const response = await new ExperienceRestClient(true).getExperiences(page, pageCount, criteria)

            dispatch({
                type: 'EXPERIENCES_FETCH_EXPERIENCES_SUCCESS',
                payload: {
                    page,
                    pageCount,
                    experiences: response.data.experiencias.items,
                    totals: response.data.experiencias.totalItems,
                }
            })
            
        } catch (e) {
            if (e instanceof axios.Cancel) {
                // Nothing to do, just canceled request by next request
            } else {
                dispatch({
                    type: 'EXPERIENCES_FETCH_EXPERIENCES_FAIL'
                })
    
                dispatch({
                    type: 'ERROR_SET',
                    payload: {
                        title: 'Error en la operación!',
                        description: 'Ha ocurrido un error al intentar obtener las experiencias'
                    }
                })
            }
        }   
    }
}

function getExperience(id) {
    return async dispatch => {
        try {
            dispatch({
                type: 'EXPERIENCES_FETCH_EXPERIENCE_START'
            })

            const response = await new ExperienceRestClient(true).getExperience(id)

            dispatch({
                type: 'EXPERIENCES_FETCH_EXPERIENCE_SUCCESS',
                payload: {
                    experience: response.data
                }
            })
        } catch (e) {
            dispatch({
                type: 'EXPERIENCES_FETCH_EXPERIENCE_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'Error en la operación!',
                    description: 'Ha ocurrido un error al intentar obtener la experiencia'
                }
            })
        }
    }
}

function saveExperience(id, data, patch) {
    return async dispatch => {
        try {
            dispatch({
                type: 'EXPERIENCES_SAVE_EXPERIENCE_START',
                payload: {
                    id,
                    patch,
                }
            })

            if (id && !patch) {
                await new ExperienceRestClient(true).updateExperience(id, data)
            } else if (id && patch) {
                await new ExperienceRestClient(true).patchExperience(id, data)
            } else {
                throw new Error('El administrador no puede crear experiencias')
            }

            dispatch({
                type: 'EXPERIENCES_SAVE_EXPERIENCE_SUCCESS',
                payload: {
                    id,
                    data,
                    patch,
                }
            })

            dispatch({
                type: 'SUCCESS_SET',
                payload: {
                    title: 'Operación Exitosa!',
                    description: id
                        ? 'La experiencia se ha actualizado correctamente'
                        : 'La experiencia se ha creado correctamente'
                }
            })
        } catch (e) {
            dispatch({
                type: 'EXPERIENCES_SAVE_EXPERIENCE_FAIL'
            })

            dispatch({
                type: 'ERROR_SET',
                payload: {
                    title: 'UPS! Ha ocurrido un error en la operación.',
                    description: ErrorManager.toMessage(e),
                }
            })
        } finally {
            dispatch({
                type: 'EXPERIENCES_SAVE_EXPERIENCE_RESET'
            })
        }
    }
}

function setFormPropertyValue(property, value) {
    return {
        type: 'EXPERIENCES_FORM_PROPERTY_VALUE_SET',
        payload: {
            property,
            value
        }
    }
}

function reset(type) {
    return {
        type,
    }
}


export const ExperiencesActions = {
    getExperiences,
    getExperience,
    saveExperience,
    setFormPropertyValue,
    reset,
}