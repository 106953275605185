/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class LanguageRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getLanguages
     */

    getLanguages(page, pageCount, criteria) {
        const url = `/api/v1/public/idiomas`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                nombre: criteria
            }
        })
    }

    /*
     *   @getLanguage
     */

    getLanguage(id) {
        const url = `/api/v1/public/idiomas/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchLanguage
     */

    patchLanguage(id, data) {
        const url = `/api/v1/secured/idiomas/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *   @updateLanguage
     */

    updateLanguage(id, data) {
        const url = `/api/v1/secured/idiomas/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createLanguage
     */

    createLanguage(data) {
        const url = `/api/v1/secured/idiomas`;

        return this.instance.post(url, data)
    }
}