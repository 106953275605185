import { FirebaseErrorsMap } from 'consts';

const defaultErrorMessage = (
    'Ha ocurrido un error inesperado.\n\n' +
    'en breve estaremos trabajando para solucionarlo.\n\n' +
    'Si el problema persiste comuníquese con soporte'
);

export default class ErrorManager {
    static toMessage(error) {
        if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[0]) {
            return error.response.data.errors[0].message
        }
        
        if (error instanceof Error) {
            if (error.code) {
                if (FirebaseErrorsMap[error.code]) {
                    return FirebaseErrorsMap[error.code];
                }

                return defaultErrorMessage;
            }
        }
        
        return defaultErrorMessage;
    }
}
