const defaultState = {
    loading: true,
    user: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'CONTEXT_SET':
            return Object.assign({}, state, {
                user: action.payload.user ? {
                    id: action.payload.user.sub,
                    email: action.payload.user.unique_name,
                } : null,
                loading: false,
            })
        default:
            return state;
    }
}
