const defaultState = {
    error: null,
    success: null,
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'ERROR_SET':
            return Object.assign({}, state, {
                error: {
                    title: action.payload.title,
                    description: action.payload.description,
                }
            })

        case 'SUCCESS_SET':
            return Object.assign({}, state, {
                success: {
                    title: action.payload.title,
                    description: action.payload.description,
                }
            })
        
        case 'CLEAR_SUCCESS':
            return Object.assign({}, state, {
                success: null,
            })

        case 'CLEAR_ERROR':
            return Object.assign({}, state, {
                error: null,
            })
        default:
            return state;
    }
}
