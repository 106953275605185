import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux'
import validate from 'validate.js';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button, TextField, Box, Typography, CircularProgress, LinearProgress } from '@material-ui/core';
import { AuthActions } from 'redux/actions'

const schema = {
    email: {
        presence: { allowEmpty: false, message: 'El email es requerido' },
        email: {
            message: "El formato del email no es válido"
        },
        length: {
            maximum: 64
        }
    },
    password: {
        presence: { allowEmpty: false, message: 'La contraseña es requerida' },
    }
};

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.background.default,
        height: '100%'
    },
    grid: {
        height: '100%'
    },
    quoteContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'none'
        }
    },
    quote: {
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundImage: 'url(/images/auth.jpg)',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundColor: '#babfc2',
    },
    quoteInner: {
        textAlign: 'center',
        flexBasis: '600px'
    },
    quoteText: {
        color: theme.palette.white,
        fontWeight: 300
    },
    name: {
        marginTop: theme.spacing(3),
        color: theme.palette.white
    },
    bio: {
        color: theme.palette.white
    },
  contentContainer: {},
  content: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  contentHeader: {
    display: 'flex',
    alignItems: 'center',
    paddingTop: theme.spacing(5),
    paddingBototm: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2)
  },
  logoImage: {
    marginLeft: theme.spacing(4)
  },
  contentBody: {
    flexGrow: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [theme.breakpoints.down('md')]: {
      justifyContent: 'center'
    }
  },
  form: {
    padding: '30px',
    
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2)
    }
  },
  title: {
    marginTop: theme.spacing(3)
  },
  socialButtons: {
    marginTop: theme.spacing(3)
  },
  socialIcon: {
    marginRight: theme.spacing(1)
  },
  sugestion: {
    marginTop: theme.spacing(2)
  },
  textField: {
    marginTop: theme.spacing(2)
  },
  signInButton: {
    margin: theme.spacing(2, 0)
  }
}));

const SignIn = props => {
    const {
        error
    } = props
    
    const classes = useStyles();
    
    const [formState, setFormState] = useState({
        isValid: false,
        values: {},
        touched: {},
        errors: {}
    });

    useEffect(() => {
        const errors = validate(formState.values, schema);

        setFormState(formState => ({
            ...formState,
            isValid: errors ? false : true,
            errors: errors || {}
        }));
    }, [formState.values]);

    const handleChange = event => {
        event.persist();

        setFormState(formState => ({
            ...formState,
            values: {
                ...formState.values,
                [event.target.name]:
                    event.target.type === 'checkbox'
                        ? event.target.checked
                        : event.target.value
            },
            touched: {
                ...formState.touched,
                [event.target.name]: true
            }
        }));
    };

    const handleSignIn = event => {
        event.preventDefault();
        props.login(formState.values.email, formState.values.password)
    };

    const hasError = field => {
        return formState.touched[field] && formState.errors[field] ? true : false;
    }

    if (props.contextLoading) {
        return (
            <Box display="flex" height="100%" alignItems="center" justifyContent="center">
                <CircularProgress  />
            </Box>
        )
    }

    return (
        <div className={classes.root}>
            <Grid
                className={classes.grid}
                container
            >
                <Grid
                    className={classes.quoteContainer}
                    item
                    md={6}
                >
                    <div className={classes.quote}>
                        
                    </div>
                </Grid>
        
                <Grid
                    className={classes.content}
                    item
                    md={6}
                    xs={12}
                >
                    <div className={classes.content}>
                        <div className={classes.contentBody}>
                            <form
                                className={classes.form}
                                onSubmit={handleSignIn}
                            >
                                <Box mb={2}>
                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontWeight: 500,
                                            fontSize: 40,
                                            lineHeight: '40px',
                                            textAlign: 'center',
                                            marginBottom: '20px',
                                        }}
                                    >
                                        SPIDERWEB
                                    </Typography>

                                    <Typography
                                        variant="h5"
                                        style={{
                                            fontFamily: 'Roboto',
                                            textAlign: 'center',
                                        }}
                                    >
                                        Ingrese sus credenciales de administrador para acceder al sistema
                                    </Typography>
                                </Box>
                                
                                <Box mb={2}>
                                    <TextField
                                        className={classes.textField}
                                        error={hasError('email')}
                                        fullWidth
                                        helperText={
                                            hasError('email') ? formState.errors.email[0] : null
                                        }
                                        label="Email"
                                        name="email"
                                        onChange={handleChange}
                                        type="email"
                                        value={formState.values.email || ''}
                                        variant="outlined"
                                    />
                                </Box>
                
                                <Box mb={2}>
                                    <TextField
                                        className={classes.textField}
                                        error={hasError('password')}
                                        fullWidth
                                        helperText={
                                            hasError('password') ? formState.errors.password[0] : null
                                        }
                                        label="Contraseña"
                                        name="password"
                                        onChange={handleChange}
                                        type="password"
                                        value={formState.values.password || ''}
                                        variant="outlined"
                                    />
                                </Box>
                                
                                {props.loading && (
                                    <Box mb={0}>
                                        <LinearProgress />
                                    </Box>
                                )}

                                {(error ? (
                                    <Box
                                        mb={0}
                                    >
                                        <Typography style={{ color: '#d15c5c', textAlign: 'center', fontWeight: 500 }}>
                                            {error}
                                        </Typography>
                                    </Box>
                                ) : null)}

                                <Button
                                    className={classes.signInButton}
                                    color="primary"
                                    disabled={!formState.isValid || props.loading}
                                    fullWidth
                                    size="large"
                                    type="submit"
                                    variant="contained"
                                >
                                    INGRESAR
                                </Button>
                            </form>
                        </div>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

const mapStateToProps = state => ({
    loading: state.auth.loading,
    error: state.auth.error,
    contextLoading: state.context.loading,
})

const mapDispatchToProps = dispatch => ({
    login: (email, password) => dispatch(
        AuthActions.login(email, password)
    )
})

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SignIn)
);
