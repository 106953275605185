/* eslint-disable no-alert, no-useless-constructor */
import RestClient from 'http/RestClient'
import axios from 'axios'

const CancelToken = axios.CancelToken;
let cancel;

export default class ProvinceRestClient extends RestClient {
    constructor(useCredentials, refreshContext) {
        super(useCredentials, refreshContext)
    }

    /*
     *   @getProvinces
     */

    getProvinces(page, pageCount, paisId, criteria) {
        const url = `/api/v1/public/provincias`;

        if (typeof cancel === 'function') cancel()

        return this.instance.get(url, {
            cancelToken: new CancelToken(function executor(c) {
                // An executor function receives a cancel function as a parameter
                cancel = c;
            }),
            params: {
                page,
                pageCount,
                paisId,
                nombre: criteria
            }
        })
    }

    /*
     *   @getProvince
     */

    getProvince(id) {
        const url = `/api/v1/public/provincias/${id}`

        return this.instance.get(url)
    }

    /*
     *  @patchProvince
     */

    patchProvince(id, data) {
        const url = `/api/v1/secured/provincias/${id}`

        return this.instance.patch(url, data)
    }

    /*
     *   @updateProvince
     */

    updateProvince(id, data) {
        const url = `/api/v1/secured/provincias/${id}`

        return this.instance.put(url, data)
    }

    /*
     *  @createProvince
     */

    createProvince(data) {
        const url = `/api/v1/secured/provincias`;

        return this.instance.post(url, data)
    }
}