import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    Typography,
} from '@material-ui/core'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

const useStyles = makeStyles(theme => ({
    selectRoot: {
        height: '28px',
        lineHeight: '28px',
        fontWeight: 500,
      },
}));

const AnDropdown = React.memo(props => {
    const {
        label,
        value,
        onChange,
        displayEmpty,
        loadingOptions,
        options,
        inputProps,
        disabled,
        loadingOptionsLabel,
        emptyOptionsLabel,
        showInactives,
    } = props


    const classes = useStyles()
    
    return (
        <FormControl fullWidth margin="normal">
            <InputLabel shrink>{ label }</InputLabel>
            <Select
                classes={{
                    root: classes.selectRoot
                }}
                style={{
                    color: disabled ? '#ccc' : null,
                }}
                inputProps={inputProps}
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
                displayEmpty={displayEmpty || false}
                IconComponent={() => (
                    loadingOptions ? <CircularProgress size={20} /> : <ArrowDropDownIcon />
                )}
            >
                {loadingOptions ? (
                    <MenuItem style={{ display: 'none' }} value={null}>{loadingOptionsLabel}</MenuItem>
                ) : options.length === 0 ? (
                    <MenuItem style={{ display: 'none' }} value={null} disabled>{emptyOptionsLabel}</MenuItem>
                ) : !disabled && !value ? (
                    <MenuItem style={{ display: 'none' }} value={null}>Seleccionar</MenuItem>
                ) : null}
                
                
                {options.filter((option) => showInactives || !option.inactive || (option.inactive && option.id === value)).map((option) => (
                    <MenuItem value={option.id} disabled={option.inactive}>
                        <Box
                            display="flex"
                            flexDirection="row"
                        >
                            <Typography style={{ fontWeight: 500 }}>{ option.name }</Typography>
                            
                            { option.inactive && (
                                <Typography style={{ color: 'red', marginLeft: 4 }}>( inactivo )</Typography>
                            )}
                        </Box>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
});

AnDropdown.propTypes = {
    
};

export default AnDropdown;
