const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    province: {
        loading: true,
        error: null,
        data: {
            id: null,
            countryId: null,
            provinceId: null,
            name: '',
        },
        actions: {
            save: {
                id: null,
                loading: false,
                success: false,
                error: null,
            },
        }
    },
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'PROVINCES_FETCH_PROVINCES_START':
            return Object.assign({}, state, {
                initialLoading: state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch,
            })

        case 'PROVINCES_FETCH_PROVINCES_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.provinces),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page: action.payload.pageCount * (action.payload.page) >= action.payload.totals ? action.payload.page - 1 : action.payload.page
            })

        case 'PROVINCES_FETCH_PROVINCES_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true
            })

            case 'PROVINCES_FETCH_PROVINCE_START':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        loading: true,
                    })
                })
    
            case 'PROVINCES_FETCH_PROVINCE_SUCCESS':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        loading: false,
                        data: {
                            id: action.payload.province.id,
                            countryId:  action.payload.province.pais.id,
                            name: action.payload.province.nombre,
                        }
                    })
                })
    
            case 'PROVINCES_FETCH_PROVINCE_FAIL':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        loading: false,
                        error: true,
                    })
                })
    
            case 'PROVINCES_SAVE_PROVINCE_START':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        actions: Object.assign({}, state.province.actions, {
                            save: {
                                id: action.payload && action.payload.patch && action.payload.id ? action.payload.id : null,
                                loading: true,
                                success: false,
                                error: null,
                            }
                        })
                    })
                })
    
            case 'PROVINCES_SAVE_PROVINCE_SUCCESS':
                const data = action.payload && action.payload.patch && action.payload.id ? state.data.map((item) => {
                    return item.id !== action.payload.id ? item : { ...item, ...action.payload.data }
                }) : null
    
                return Object.assign({}, state, {
                    data: action.payload && action.payload.patch && action.payload.id ? [].concat(data) : state.data,
                    province: Object.assign({}, state.province, {
                        actions: Object.assign({}, state.province.actions, {
                            save: {
                                id: null,
                                loading: true,
                                success: true,
                                error: null,
                            }
                        })
                    })
                })
    
            case 'PROVINCES_SAVE_PROVINCE_FAIL':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        actions: Object.assign({}, state.province.actions, {
                            save: {
                                id: null,
                                loading: false,
                                success: false,
                                error: true,
                            }
                        })
                    })
                })

            case 'PROVINCES_SAVE_PROVINCE_RESET':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        actions: Object.assign({}, state.province.actions, {
                            save: defaultState.province.actions.save
                        })
                    })
                })
    
            case 'PROVINCES_FORM_PROPERTY_VALUE_SET':
                return Object.assign({}, state, {
                    province: Object.assign({}, state.province, {
                        data: Object.assign({}, state.province.data, {
                            [action.payload.property]: action.payload.value
                        })
                    })
                })
    
            case 'PROVINCES_PROVINCE_RESET':
                return Object.assign({}, state, {
                    province: Object.assign({}, defaultState.province)
                })
    
            case 'PROVINCES_RESET':
                return  Object.assign({}, state, defaultState)

        default:
            return state;
    }
}
