import 'config/reactotron'

// ------

import React from 'react';
import ReactDOM from 'react-dom';
import Store from 'redux/index';
import { Provider } from 'react-redux';
import * as serviceWorker from './serviceWorker';
import App from './App';

ReactDOM.render(
	<Provider store={Store}>
		<App />
	</Provider>, document.getElementById('root'));

serviceWorker.unregister();
