import { combineReducers } from 'redux'
import context from './context'
import auth from './auth'
import dashboard from './dashboard'
import languages from './languages'
import tags from './tags'
import countries from './countries'
import provinces from './provinces'
import localities from './localities'
import categories from './categories'
import experiences from './experiences'
import postulations from './postulations'
import users from './users'
import commissionsSchemes from './commissions-schemes'
import bookings from './bookings'
import clients from './clients'
import commissions from './commissions'
import movements from './movements'

const rootReducer = combineReducers({
    context,
    auth,
    dashboard,
    languages,
    tags,
    countries,
    provinces,
    localities,
    categories,
    experiences,
    postulations,
    users,
    commissionsSchemes,
    bookings,
    clients,
    commissions,
    movements,
})

export default rootReducer;