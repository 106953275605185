const defaultState = {
    initialLoading: true,
    loading: false,
    error: false,
    totals: 0,
    page: 0,
    pageCount: 10,
    isActiveSearch: false,
    data: [],
    user: {
        loading: true,
        error: null,
        data: {
            id: null,
            name: null,
            surname: null,
            email: null,
            code: null,
            type: null,
            parent: {
                id: null,
                name: null
            },
            childs: []
        },
        updatedUser: {
            name: null,
            surname: null,
            code: null,
            parent: {
                id: null,
                name: null
            }
        },
        actions: {
            impersonate: {
                id: null,
                loading: false,
                success: false,
                token: null,
                error: null
            },
            updateUser: {
                loading: false,
                success: false,
                error: null
            }
        }
    }
};

export default function reducer(state = defaultState, action) {
    switch (action.type) {
        case 'USERS_FETCH_USERS_START':
            return Object.assign({}, state, {
                initialLoading:
                    state.initialLoading || state.error ? true : false,
                loading: true,
                error: false,
                isActiveSearch: action.payload.isActiveSearch
            });

        case 'USERS_FETCH_USERS_SUCCESS':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                data: [].concat(action.payload.users),
                totals: action.payload.totals,
                pageCount: action.payload.pageCount,
                page:
                    action.payload.pageCount * action.payload.page >=
                    action.payload.totals
                        ? action.payload.page - 1
                        : action.payload.page
            });

        case 'USERS_FETCH_USERS_FAIL':
            return Object.assign({}, state, {
                initialLoading: false,
                loading: false,
                error: true
            });

        /* IMPERSONATE USER */

        case 'USERS_IMPERSONATE_USER_START':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        impersonate: {
                            id: action.payload.id,
                            loading: true,
                            success: false,
                            token: null,
                            error: null
                        }
                    })
                })
            });
        case 'USERS_IMPERSONATE_USER_SUCCESS':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        impersonate: {
                            id: null,
                            loading: false,
                            success: true,
                            token: action.payload.token,
                            error: null
                        }
                    })
                })
            });

        case 'USERS_IMPERSONATE_USER_FAIL':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        impersonate: {
                            id: null,
                            loading: false,
                            success: false,
                            token: null,
                            error: true
                        }
                    })
                })
            });

        /* PUT USER */

        case 'USERS_PUT_USER_START':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        updateUser: {
                            loading: true,
                            success: false,
                            error: null
                        }
                    })
                })
            });
        case 'USERS_PUT_USER_SUCCESS':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    data: Object.assign({}, state.user.data, {
                        name: state.user.updatedUser.name,
                        surname: state.user.updatedUser.surname,
                        code: state.user.updatedUser.code,
                        parent: state.user.updatedUser.parent
                    }),

                    actions: Object.assign({}, state.user.actions, {
                        updateUser: {
                            id: null,
                            loading: false,
                            success: true
                        }
                    })
                })
            });

        case 'USERS_PUT_USER_FAIL':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        updateUser: {
                            loading: false,
                            success: false,
                            error: true
                        }
                    })
                })
            });

        case 'USERS_IMPERSONATE_USER_RESET':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    actions: Object.assign({}, state.user.actions, {
                        impersonate: defaultState.user.actions.impersonate
                    })
                })
            });

        case 'USERS_FETCH_USER_START':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    loading: true
                })
            });

        case 'USERS_FETCH_USER_SUCCESS':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    loading: false,
                    data: {
                        id: action.payload.user.id,
                        name: action.payload.user.nombre,
                        surname: action.payload.user.apellido,
                        email: action.payload.user.email,
                        code: action.payload.user.codigo,
                        type: action.payload.user.tipoPostulacion,
                        childs: action.payload.user.childs,
                        parent: {
                            id: action.payload.user.parent
                                ? action.payload.user.parent.id
                                : null,
                            name: action.payload.user.parent
                                ? action.payload.user.parent.nombre
                                : null
                        }
                    },
                    updatedUser: {
                        name: action.payload.user.nombre,
                        surname: action.payload.user.apellido,
                        email: action.payload.user.email,
                        code: action.payload.user.codigo,
                        parent: {
                            id: action.payload.user.parent
                                ? action.payload.user.parent.id
                                : null,
                            name: action.payload.user.parent
                                ? action.payload.user.parent.nombre
                                : null
                        }
                    }
                })
            });

        case 'USERS_FETCH_USER_FAIL':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    loading: false,
                    error: true
                })
            });

        case 'USERS_USER_RESET':
            return Object.assign({}, state, {
                user: Object.assign({}, defaultState.user)
            });

        case 'USERS_USER_SET_USER_DATA':
            return Object.assign({}, state, {
                user: Object.assign({}, state.user, {
                    updatedUser: {
                        ...state.user.updatedUser,
                        [action.payload.name]: action.payload.value
                    },
                    actions: Object.assign({}, state.user.actions, {
                        updateUser: defaultState.user.actions.updateUser
                    })
                })
            });

        case 'USERS_RESET':
            return Object.assign({}, state, defaultState);

        default:
            return state;
    }
}
