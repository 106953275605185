import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { Box, Typography } from '@material-ui/core'

function Alert(props) {
  return <MuiAlert variant="filled" {...props} />;
}


class AnAlertBar extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      show: false,
      type: 'error',
      title: '',
      description: '',
      duration: 5000
    }
  }

  show(params) {
    this.setState({
      show: true,
      title: params.title,
      description: params.description,
      type: params.type
    });
  }

  handleClose = () => {
    this.setState({
      show: false,
    })
  }
  render() {
    return (
      <Snackbar
        open={this.state.show}
        autoHideDuration={this.state.duration}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}  
        style={{ maxWidth: 700 }}
        onClose={this.handleClose}>
        <Alert
          onClose={this.handleClose}
          severity={this.state.type}>
          <Box>
            <Typography
                variant="h6"
                style={{
                    color: '#fff',
                }}
            >
                {this.state.title}
            </Typography>
          </Box>
          <Box>
            <Typography
                    variant="caption"
                    style={{
                        color: '#f1f1f1',
                    }}
                >
                    {this.state.description}
                </Typography>
          </Box>
        </Alert>
      </Snackbar>
    );
  }
}

export default AnAlertBar