import React, { useEffect, useCallback, useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, CircularProgress } from '@material-ui/core'
import { connect } from 'react-redux'
import { LocalitiesActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox } from 'components'
import RoomIcon from '@material-ui/icons/Room'
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import { useHistory } from 'react-router-dom'


const useStyles = makeStyles(theme => ({
    root: {
        padding: theme.spacing(2),
        width: '100%',

        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    content: {
        paddingBottom: theme.spacing(2),
        marginTop: theme.spacing(2),

        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(3),
        },
    },
    emptyIcon: {
        fontSize: '200px',
        color: '#ccc'
    }
}));

const LocalitiesList = React.memo(props => {
    const {
        getLocalities,
        localities,
        locality,
        initialLoading,
        loading,
        error,
        totals,
        page,
        pageCount,
        isActiveSearch,
        saveLocality,
        query,
        setQuery,
        reset,
    } = props

    const {
        p,
        r,
        q,
    } = query;

    const classes = useStyles();
    const history = useHistory();

    useEffect(() => {
        // Nothing to do, we only use this effect
        // to register the function thats makes the cleanup
        // when the component unmount
        return () => {
            reset('LOCALITIES_RESET')
        }
    }, [reset])

    useEffect(() => {
        if (q.length > 2) {
            getLocalities(p, r, q)
        } else if (q.length === 0) {
            getLocalities(p, r)
        }
    }, [p, r, q, getLocalities])

    const onSearch = useCallback((text) => {
        if (text.length > 2) {
            setQuery({ q: text, p: undefined })
        } else {
            setQuery({ q: undefined, p: undefined })
        }
    }, [setQuery])

    const onClickEdit = useCallback((id) => {
        history.push(`/localidades/editar/${id}`);
    }, [history])

    const onClickToggleActivate = useCallback((id, inactive) => {
        saveLocality(id, { inactivo: !inactive }, true)
    }, [saveLocality])

    const onPageChange = useCallback((page) => {
        setQuery({ p: page > 0 ? page : undefined })
    }, [setQuery])

    const onPageCountChange = useCallback((pageCount) => {
        setQuery({ r: pageCount !== 10 ? pageCount : undefined })
    }, [setQuery])

    const headers = useMemo(() => ([
        { label: 'Localidad' },
        { label: 'Provincia' },
        { label: 'País' },
        { label: 'Frecuente' },
    ]), [])

    const rows = useMemo(() => (
        localities.map(item => {
            return {
                view: [
                    { value: item.nombre },
                    { value: item.provincia.nombre },
                    { value: item.provincia.pais.nombre },
                    { value: item.favorito ? 'Si' : 'No' },
                ],
                data: {
                    id: item.id,
                    inactive: item.inactivo,
                }
            }
        })
    ), [localities])

    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Localidades'
                    }
                ]}
            />

            <Box>
                {initialLoading ? (
                    <Box
                        display="flex"
                        justifyContent="center"
                    >
                        <CircularProgress />
                    </Box>
                ) : error ? (
                    <ErrorBox
                        onRetry={() => {
                            if (q.length > 2) {
                                getLocalities(p, r, q)
                            } else if (q.length === 0) {
                                getLocalities(p, r)
                            }
                        }}
                    />
                ) : (
                            <Box width="100%">
                                <div className={classes.content}>
                                    <AnTable
                                        toolbar={(
                                            <AnTableToolbar
                                                textSearchPlaceholder="Buscar localidad ...."
                                                textSearchValue={q}
                                                createButtonText="Crear Localidad"
                                                createButtonTo="/localidades/nueva"
                                                onSearch={onSearch}
                                            />
                                        )}
                                        isActiveSearch={isActiveSearch}
                                        emptyIcon={(
                                            <RoomIcon className={classes.emptyIcon} />
                                        )}
                                        emptyMessage="Aún no has creado ninguna localidad"
                                        emptyButtonTo="/localidades/nueva"
                                        emptyButtonText="Crear Localidad"
                                        headers={headers}
                                        rows={rows}
                                        loading={loading}
                                        save={locality.actions.save}
                                        totals={totals}
                                        page={page}
                                        pageCount={pageCount}
                                        onClickEdit={onClickEdit}
                                        onClickToggleActivate={onClickToggleActivate}
                                        onPageChange={onPageChange}
                                        onPageCountChange={onPageCountChange}
                                    />
                                </div>
                            </Box>
                        )}
            </Box>
        </div>
    );
});

const mapStateToProps = state => ({
    initialLoading: state.localities.initialLoading,
    loading: state.localities.loading,
    error: state.localities.error,
    localities: state.localities.data,
    page: state.localities.page,
    pageCount: state.localities.pageCount,
    totals: state.localities.totals,
    isActiveSearch: state.localities.isActiveSearch,
    locality: state.localities.locality,
})

const mapDispatchToProps = dispatch => ({
    getLocalities: (page, pageCount, criteria) => dispatch(LocalitiesActions.getLocalities(page, pageCount, criteria)),
    saveLocality: (id, data, patch) => dispatch(LocalitiesActions.saveLocality(id, data, patch)),
    reset: (type) => dispatch(LocalitiesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(
    withQueryParams({
        p: withDefault(NumberParam, 0), // The current page
        r: withDefault(NumberParam, 10), // The current page count
        q: withDefault(StringParam, "") // The current search criteria
    }, LocalitiesList)
);
