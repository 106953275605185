import React, { useEffect } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box,Grid, TextField, Button, CircularProgress, Card, CardHeader, CardContent, CardActions, Divider } from '@material-ui/core'
import { Breadcrumb } from 'components'
import { connect } from 'react-redux'
import { LanguagesActions } from 'redux/actions';
import { Transition } from 'react-transition-group';
import { useHistory, useRouteMatch } from 'react-router-dom'

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2),
    width: '100%',

    [theme.breakpoints.up('md')]: {
        padding: theme.spacing(3),
    },
  },
  header: {
    background: '#f3f3f3b3',
    padding: '10px 16px',
  },
  content: {
    marginTop: theme.spacing(2)
  },
  selectRoot: {
    height: '28px',
    lineHeight: '28px',
    fontWeight: 500,
  },
  inputRoot: {
      '& .MuiInput-input': {
        height: '28px',
        lineHeight: '28px',
        fontWeight: 500,
      },
      
  },
}));

const LanguagesForm = props => {
    const {
        getLanguage,
        saveLanguage,
        setFormPropertyValue,
        language,
        reset,
    } = props
    
    const theme = useTheme();
    const classes = useStyles();
    const history = useHistory();
    const match = useRouteMatch();
    
    const transitionStyles = {
        entered:  { transform: 'translateY(0) scale(1)' },
    };

    useEffect(() => {
        if (match.params.id) {
            getLanguage(match.params.id)
        }
        return () => {
            reset('LANGUAGES_LANGUAGE_RESET')
        }
    }, [getLanguage, reset, match.params.id])

    useEffect(() => {
        if (language.actions.save.success) {
            history.goBack()
        }
    }, [language.actions.save.success, history])
  
    return (
        <div className={classes.root}>
            <Breadcrumb
                path={[
                    {
                        name: 'Idiomas'
                    },
                    {
                        name: match.params.id ? 'Editar' : 'Nuevo'
                    }
                ]}
            />

            {((match.params.id && language.loading) || language.actions.save.loading ? (
                <Box
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    marginTop="30px"
                >
                    <CircularProgress />
                </Box>
            ) : (
                <Transition in={true} appear={true} timeout={0}>
                    {state => (
                        <Box paddingBottom={3}>
                            <Card style={{ transform: 'translateY(15px) scale(0.99)', transition: theme.transitions.create(['transform'], { duration: 150, easing: theme.transitions.easing.sharp }), ...transitionStyles[state] }}>
                                <CardHeader
                                    className={classes.header}
                                    title="Idioma"
                                    subheader={match.params.id ? "Editar Idioma" : "Crear nuevo idioma"}
                                />

                                <Divider />

                                <CardContent className={classes.content}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>
                                            <TextField
                                                classes={{
                                                    root: classes.inputRoot,
                                                }}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                margin="normal"
                                                fullWidth
                                                label="Idioma"
                                                value={language.data.name}
                                                onChange={(e) => {
                                                    setFormPropertyValue('name', e.target.value)
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </CardContent>

                                <Divider />

                                <CardActions
                                    style={{
                                        justifyContent: 'flex-end'
                                    }}
                                >
                                    <Button
                                        color="primary"
                                        onClick={() => {
                                            history.goBack()
                                        }}
                                    >
                                        VOLVER
                                    </Button>
                                    
                                    <Button
                                        disabled={!language.data.name}
                                        color="primary"
                                        variant="contained"
                                        onClick={() => {
                                            if (match.params.id) {
                                                return saveLanguage(language.data.id, {
                                                    nombre: language.data.name,
                                                })
                                            }

                                            saveLanguage(null, {
                                                nombre: language.data.name,
                                            })
                                        }}
                                    >
                                        GUARDAR
                                    </Button>
                                </CardActions>
                            </Card>
                        </Box>
                    )}
                </Transition>
            ))}
        </div>
    );
};

const mapStateToProps = state => ({
    language: state.languages.language,
})

const mapDispatchToProps = dispatch => ({
    getLanguage: (id) => dispatch(LanguagesActions.getLanguage(id)),
    setFormPropertyValue: (property, value) => dispatch(LanguagesActions.setFormPropertyValue(property, value)),
    saveLanguage: (id, data, patch) => dispatch(LanguagesActions.saveLanguage(id, data, patch)),
    reset: (type) => dispatch(LanguagesActions.reset(type))
})

export default connect(mapStateToProps, mapDispatchToProps)(LanguagesForm);
