import React, { useEffect, useCallback, useMemo, useState, useRef, useLayoutEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Button, CircularProgress, Link } from '@material-ui/core'
import { connect } from 'react-redux'
import { MovementsActions } from 'redux/actions';
import { AnTable, AnTableToolbar } from 'components/an'
import { Breadcrumb, ErrorBox, SearchAsyncAutocompleteInput, } from 'components'
import SwapHorizontalCircleIcon from '@material-ui/icons/SwapHorizontalCircle';
import { withQueryParams, StringParam, NumberParam, withDefault } from 'use-query-params';
import moment from 'moment'
import { formatCurrencyData } from 'utils/formatCurrencyData'
import MovementsTypes from 'consts/wallet-types';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
	root: {
		padding: theme.spacing(2),
		width: '100%',

		[theme.breakpoints.up('md')]: {
			padding: theme.spacing(3),
		},
	},
	content: {
		paddingBottom: theme.spacing(2),
		marginTop: theme.spacing(2),

		[theme.breakpoints.up('md')]: {
			paddingBottom: theme.spacing(3),
		},
	},
	emptyIcon: {
		fontSize: '200px',
		color: '#ccc'
	},
	search: {
		display: 'flex',
		alignItems: 'center',
		maxWidth: 400,
		position: 'relative',
		paddingBottom: theme.spacing(1),
	},
	searchButton: {
		height: '3.87em',
		marginLeft: 10
	},
	buttonProgress: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		marginTop: -12,
		marginLeft: -12,
	},
	create: {
		paddingBottom: theme.spacing(2),
	},
}));

const MovementsList = React.memo(props => {
	const {
		getMovements,
		movements,
		loading,
		error,
		totals,
		page,
		pageCount,
		isActiveSearch,
		query,
		setQuery,
		reset,
		dateFrom,
		dateTo,
		setDateFrom,
		setDateTo,

		search,
	} = props

	const {
		p,
		r,
		f,
		t,
	} = query;

	const [userFound, setUserFound] = useState(null)

	const classes = useStyles();

	useEffect(() => {
		// Nothing to do, we only use this effect
		// to register the function thats makes the cleanup
		// when the component unmount
		return () => {
			reset('MOVEMENTS_RESET')
		}
	}, [reset])


	const firstUpdate = useRef(true);

	useLayoutEffect(() => {
		if (firstUpdate.current) {
			firstUpdate.current = false;
		} else {
			if (search.success) {
				getMovements({ page: p, pageCount: r }, { userId: userFound ? userFound.id : '' })
			}
		}
	}, [p, r, getMovements, dateFrom, dateTo, search]);

	useEffect(() => {
		if (f.length > 0 && t.length > 0) {
			setDateFrom(f)
			setDateTo(t)
		} else {
			setQuery({ t: dateTo, f: dateFrom })
		}

	}, [f, t])

	const onPageChange = useCallback((page) => {
		setQuery({ p: page > 0 ? page : undefined })
	}, [setQuery])

	const onPageCountChange = useCallback((pageCount) => {
		setQuery({ r: pageCount !== 10 ? pageCount : undefined })
	}, [setQuery])


	const handleDateFromChange = useCallback((date) => {
		if (date && date._isValid) {
			setQuery({ p: 0, f: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'), })
			setDateFrom(date)
		}
	}, [setQuery])

	const handleDateToChange = useCallback((date) => {
		if (date && date._isValid) {
			setQuery({ p: 0, t: moment(date).format('YYYY-MM-DDThh:mm:ss.SZ'), })
			setDateTo(date)
		}
	}, [setQuery])

	const headers = useMemo(() => ([
		{ label: 'Fecha' },
		{ label: 'Tipo' },
		{ label: 'Experiencia' },
		{ label: 'Neto' },
		{ label: 'Saldo' },
	]), [])

	const rows = useMemo(() => (
		movements.map(item => {
			return {
				view: [
					{ value: `${moment(moment.utc(item.creado).toDate()).format('DD/MM/YY HH:mm')}` },
					{ value: `${typeof item.tipo === 'number' ? MovementsTypes[item.tipo] : 'No hay información'}` },
					{ value: `${item.experiencia ? item.experiencia.nombre : 'No hay información'}` },
					{ value: `${formatCurrencyData(item.monto)}` },
					{ value: `${formatCurrencyData(item.saldo)}` }
				],
				data: {
					id: item.id,
					status: item.estado
				}
			}
		})
	), [movements])

	const onClickSearch = useCallback(() => {
		getMovements({ page: p, pageCount: r }, { userId: userFound ? userFound.id : '' }, true)
	}, [userFound])

	const handleGetOptionSelected = useCallback((value) => {
		setUserFound(value)
	}, [])

	return (
		<div className={classes.root}>
			<Breadcrumb
				path={useMemo(() => (
					[
						{
							name: 'Movimientos'
						}
					]
				), [])}
			/>

			<Box>

				<div className={classes.search} >
					<SearchAsyncAutocompleteInput
						placeholder="Buscar usuario por nombre"
						variant="outlined"
						getOptionSelected={handleGetOptionSelected}
						autocompleteStyles={{
							width: 350
						}}
					/>

					<Button
						variant="contained"
						color="primary"
						className={classes.searchButton}
						onClick={onClickSearch}
						disabled={search.loading || !userFound}
					>
						Seleccionar
                        {search.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
					</Button>
				</div >
			</Box >
			{
				<Box>
					{
						error ? (
							<ErrorBox
								onRetry={() => {
									getMovements({ page: p, pageCount: r }, { userId: userFound ? userFound.id : '' })
								}}
							/>
						) : search.success ? (
							<Box width="100%">
								<div className={classes.content}>
									<AnTable
										toolbar={(
											<AnTableToolbar
												dateFromValue={dateFrom}
												dateToValue={dateTo}
												handleDateFromChange={handleDateFromChange}
												handleDateToChange={handleDateToChange}
												createButtonText="Crear Movimiento"
												createButtonTo={{
													pathname: "/movimientos/nuevo",
													state: {
														userFound
													}
												}}
											/>
										)}
										isActiveSearch={isActiveSearch}
										emptyIcon={(
											<SwapHorizontalCircleIcon className={classes.emptyIcon} />
										)}
										emptyMessage="No hay movimientos en las fechas seleccionadas"
										actionsRowWidth="58px"
										headers={headers}
										rows={rows}
										loading={loading}
										totals={totals}
										page={page}
										pageCount={pageCount}
										onPageChange={onPageChange}
										onPageCountChange={onPageCountChange}
										showToolbarOnError
									/>
								</div>
							</Box>
						)
								:
								null
					}
				</Box>}
		</div >
	);
});

const mapStateToProps = state => ({
	loading: state.movements.loading,
	error: state.movements.error,
	movements: state.movements.data,
	page: state.movements.page,
	pageCount: state.movements.pageCount,
	totals: state.movements.totals,
	isActiveSearch: state.movements.isActiveSearch,
	search: state.movements.actions.search,
	dateFrom: state.movements.dateFrom,
	dateTo: state.movements.dateTo,
})

const mapDispatchToProps = dispatch => ({
	getMovements: (params, criteria, isSearch) => dispatch(MovementsActions.getMovements(params, criteria, isSearch)),
	reset: (type) => dispatch(MovementsActions.reset(type)),
	setDateFrom: (date) => dispatch(MovementsActions.setDateFrom(date)),
	setDateTo: (date) => dispatch(MovementsActions.setDateTo(date)),
})

export default connect(mapStateToProps, mapDispatchToProps)(
	withQueryParams({
		p: withDefault(NumberParam, 0), // The current page
		r: withDefault(NumberParam, 10), // The current page count
		f: withDefault(StringParam, ""),//  The current date from
		t: withDefault(StringParam, ""),// The current date to
		// u: withDefault(StringParam, ""), // The current userId from user selected
	}, MovementsList)
);
